import { Input, Upload, Radio, Row, Space, Select, Tag } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { useCountryArray } from '../../../../utils/countryArray';

const { Dragger } = Upload;
const { Option } = Select;

export const useOtherInfosFields = ({
  t,
  setDocumentsFileList,
  documentsFileList
}) => {
  const countries = useCountryArray();

  const filterOption = (input, option) =>
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const getRadioGroup = () => (
    <Radio.Group>
      <Radio value>{t('pensioners.form.yes')}</Radio>
      <Radio value={false}>{t('pensioners.form.no')}</Radio>
    </Radio.Group>
  );

  const draggerProps = (name) => ({
    onRemove: () => {
      const updatedFileList = { ...documentsFileList };
      delete updatedFileList[name];
      setDocumentsFileList(updatedFileList);
    },
    beforeUpload: (file) => {
      setDocumentsFileList((prevFileList) => ({
        ...prevFileList,
        [name]: [file]
      }));

      return false;
    },
    fileList: documentsFileList[name]
  });

  const otherInfosFields = [
    {
      name: ['address', 'number'],
      label: 'address.number'
    },
    {
      name: ['address', 'street']
    },
    {
      name: ['address', 'postal_code'],
      input: <Input type="number" />
    },
    {
      name: ['address', 'city']
    },
    {
      name: ['address', 'country'],
      input: (
        <Select showSearch filterOption={filterOption}>
          {(countries || []).map((country) => (
            <Option key={country} value={country}>
              <Tag>{country}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['phone_number', 'number'],
      input: <Input type="number" />
    },
    {
      name: ['email']
    },
    {
      name: ['is_animals'],
      input: getRadioGroup()
    },
    {
      name: ['is_smoker'],
      input: getRadioGroup()
    },
    {
      label: 'pensioner_certificate',
      input: (
        <Dragger {...draggerProps('early_retirement_certificate')}>
          <Space>
            <UploadOutlined />
            {t('users.form.upload_file')}
          </Space>
        </Dragger>
      )
    },
    {
      label: 'insurance_certificate',
      input: (
        <Dragger {...draggerProps('insurance_certificate')}>
          <Space>
            <UploadOutlined />
            {t('users.form.upload_file')}
          </Space>
        </Dragger>
      )
    },
    {
      label: 'proof_of_address',
      input: (
        <Dragger {...draggerProps('residence_proof')}>
          <Space>
            <UploadOutlined />
            {t('users.form.upload_file')}
          </Space>
        </Dragger>
      )
    },
    {
      label: 'other_documents',
      input: (
        <Dragger {...draggerProps('other_documents')}>
          <Row justify="center">
            <InboxOutlined style={{ fontSize: 40 }} />
          </Row>
          <Row justify="center">{t('users.form.upload_other')}</Row>
        </Dragger>
      )
    }
  ];

  return { otherInfosFields };
};
