import { createFileFromJSON } from '../../../utils/filesManagement/createFileFromJSON';

export const addSupervisionAddressData = async ({
  owner,
  setSupervisionAddressData,
  setPetsittingAddressFileList,
  message,
  dispatchAPI
}) => {
  if (owner?.petsitting_address) {
    setSupervisionAddressData({
      petsitting_address: owner.petsitting_address
    });

    if (owner?.petsitting_address?.dwelling_photos?.length) {
      try {
        const dwellingPhotosPromises = (
          owner.petsitting_address.dwelling_photos || []
        ).map((photo) => createFileFromJSON(photo, dispatchAPI, message));

        const results = await Promise.all(dwellingPhotosPromises);
        setPetsittingAddressFileList(results);
      } catch (error) {
        message(message(error));
      }
    }
  }
};
