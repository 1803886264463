/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import webPlugin from 'grapesjs-preset-webpage';
import formPlugin from 'grapesjs-plugin-forms';
import newsletterPlugin from 'grapesjs-preset-newsletter';
import tabs from 'grapesjs-tabs';
import flexbox from 'grapesjs-blocks-flexbox';
import basicPlugin from 'grapesjs-blocks-basic';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseReactComponent from './base-react-component';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { generateOptions } from './utils/generateOptions';
import { registerPlaceholderComponent } from './CustomComponents/RichTextEditor/registerPlaceholderComponent';
import { ArrayModal } from './CustomComponents/ArrayModal';
import { registerArrayButton } from './CustomComponents/Buttons/registerArrayButton';
import { registerA4Button } from './CustomComponents/Buttons/registerA4Button';
import { registerHeaderAndFooter } from './CustomComponents/Blocks/registerHeaderAndFooter';
import { translateEditor } from './utils/translateEditor';
import { manageDimensionSector } from './utils/manageDimensionSector';
import { createBodyPadding } from './utils/createBodyPadding';
import { preventWidthExpand } from './utils/preventWidthExpand';
import { registerForLoopComponent } from './CustomComponents/forLoopComponent.ts';

/**
 * GrapesJSEditor is a React component that initializes and manages the GrapesJS editor for editing or creating templates.
 * This component integrates various plugins for GrapesJS and sets up the initial configurations for the editor.
 * Additionally, it handles the modal toggle functionality and fetches necessary tags from the server.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {Object} props.template - The template object containing the content for the editor.
 * @param {Object} props.editorRef - A ref object to access the GrapesJS editor instance.
 * @param {Object} props.pickedConfiguration - The picked configuration object which contains the collection name.
 * @param {string} props.purpose - The purpose of using the editor which can be either 'edit' or 'create'.
 * @param {Function} props.setPopulateList - A function to set the populate list state.
 *
 * @returns {JSX.Element} - A JSX element comprising the GrapesJS editor and the array modal component.
 *
 * @example
 *
 * <GrapesJSEditor
 *    template={templateData}
 *    editorRef={editorRefInstance}
 *    pickedConfiguration={configurationData}
 *    purpose="create"
 *    setPopulateList={setPopulateListFunction}
 * />
 */
export const GrapesJSEditor = ({
  template,
  editorRef,
  pickedConfiguration,
  purpose,
  setPopulateList
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };

  const fetchTags = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `template-documents/tags/${pickedConfiguration.collection}`
      });
      const selectOptions = generateOptions(data, undefined, t);

      const editor = grapesjs.init({
        container: '#gjs',
        height: '80vh',
        i18n: {
          locale: 'fr',
          localeFallback: 'fr'
        },
        width: '100%',
        plugins: [
          basicPlugin,
          webPlugin,
          formPlugin,
          BaseReactComponent,
          tabs,
          flexbox,
          newsletterPlugin,
          registerForLoopComponent
        ],
        storageManager: false,
        components: purpose === 'edit' ? template.content : null,
        canvas: {
          styles: [
            '.body { padding: 30px; }',
            '.text-block, .div-container, .cell { word-wrap: break-word; white-space: normal; }'
          ]
        },
        deviceManager: {
          devices: [
            {
              id: 'desktop',
              name: 'Desktop',
              width: ''
            },
            {
              id: 'tablet',
              name: 'Tablet',
              width: '768px',
              widthMedia: '992px'
            },
            {
              id: 'mobilePortrait',
              name: 'Mobile portrait',
              width: '595px',
              widthMedia: '575px'
            },
            {
              id: 'A4',
              name: 'A4',
              width: '210mm',
              widthMedia: '992px'
            }
          ]
        },
        pluginsOpts: {
          'grapesjs-preset-webpage': {
            blocks: [
              'column1',
              'column2',
              'column3',
              'column3-7',
              'text',
              'link',
              'image',
              'video',
              'map'
            ]
          }
        }
      });

      // EDITOR ASSIGNMENT
      editorRef.current = editor;

      preventWidthExpand(editor);
      createBodyPadding(editor);
      registerArrayButton(editorRef, handleModal, t);
      registerPlaceholderComponent(editor, selectOptions, setPopulateList, t);
      registerA4Button(editor);
      registerHeaderAndFooter(editor);
      translateEditor(editor);
      manageDimensionSector(editor);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (pickedConfiguration) {
      (async () => {
        await fetchTags();
      })();
    }
  }, [pickedConfiguration]);

  return (
    <>
      <ArrayModal
        open={open}
        setOpen={setOpen}
        handleModal={handleModal}
        editorRef={editorRef}
      />
      <div id="gjs" />
    </>
  );
};

GrapesJSEditor.propTypes = {
  editorRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  template: PropTypes.shape({
    content: PropTypes.string
  }),
  pickedConfiguration: PropTypes.shape({
    collection: PropTypes.string
  }).isRequired,
  purpose: PropTypes.string.isRequired,
  setPopulateList: PropTypes.func
};

GrapesJSEditor.defaultProps = {
  editorRef: null,
  template: undefined,
  setPopulateList: null
};
