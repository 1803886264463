import { useState, useEffect } from 'react';
import {
  Input,
  Checkbox,
  InputNumber,
  Space,
  Button,
  Typography,
  Radio
} from 'antd';
import BeachIcon from '../../../../../assets/images/noto_beach-with-umbrella.svg';
import MountainIcon from '../../../../../assets/images/noto_mountain.svg';
import CityIcon from '../../../../../assets/images/noto_cityscape.svg';
import CountrysideIcon from '../../../../../assets/images/noto_deciduous-tree.svg';
import IsolatedIcon from '../../../../../assets/images/noto_house-with-garden.svg';
import CalmIcon from '../../../../../assets/images/noto_sleeping-face.svg';

const { TextArea } = Input;
const { Text } = Typography;

/**
 * GeneralInformations component that returns a set of form field configurations for general information about the pet sitting address.
 * The fields include various attributes about the pet sitting address like living space, number of available pieces, floor number, etc.
 * The component also maintains state variables to dynamically show or hide fields based on user input.
 *
 * @param {('HOUSE' | string)} typeOfDwelling - The type of dwelling, it is used to conditionally show or hide some fields.
 *
 * @param {function} t - A function to translate strings.
 *
 * @returns {Object} An object containing the following property:
 * - `generalInformationsFields`: An array of objects representing each field in the general informations section. Each object contains properties such as label, name, input, and optionally hidden and valuePropName which are used to configure the form fields in the component where this function is used.
 *
 * @function
 */
const GeneralInformations = ({
  typeOfDwelling,
  t,
  formDataEnvironment,
  setFormDataEnvironment,
  form
}) => {
  const [smallBusinessExist, setSmallBusinessExist] = useState(false);
  const [superMarketExist, setSuperMarketExist] = useState(false);

  useEffect(() => {
    setSmallBusinessExist(
      form.getFieldValue([
        'petsitting_address',
        'small_business_nearby',
        'is_exist'
      ])
    );
    setSuperMarketExist(
      form.getFieldValue([
        'petsitting_address',
        'super_market_nearby',
        'is_exist'
      ])
    );
  }, [form]);

  const initialCheckedValue = (value) => {
    if (formDataEnvironment?.includes(value)) {
      return true;
    }
    return false;
  };

  const environmentOptions = [
    {
      icon: (
        <img
          src={BeachIcon}
          alt={t('petsittingaddress.form.environment.beach')}
        />
      ),
      label: t('petsittingaddress.form.environment.beach'),
      value: 'beach',
      checked: initialCheckedValue('beach')
    },
    {
      icon: (
        <img
          src={MountainIcon}
          alt={t('petsittingaddress.form.environment.mountain')}
        />
      ),
      label: t('petsittingaddress.form.environment.mountain'),
      value: 'mountain',
      checked: initialCheckedValue('mountain')
    },
    {
      icon: (
        <img
          src={CityIcon}
          alt={t('petsittingaddress.form.environment.city')}
        />
      ),
      label: t('petsittingaddress.form.environment.city'),
      value: 'city',
      checked: initialCheckedValue('city')
    },
    {
      icon: (
        <img
          src={CountrysideIcon}
          alt={t('petsittingaddress.form.environment.campaign')}
        />
      ),
      label: t('petsittingaddress.form.environment.campaign'),
      value: 'campaign',
      checked: initialCheckedValue('campaign')
    },
    {
      icon: (
        <img
          src={IsolatedIcon}
          alt={t('petsittingaddress.form.environment.isolated')}
        />
      ),
      label: t('petsittingaddress.form.environment.isolated'),
      value: 'isolated',
      checked: initialCheckedValue('isolated')
    },
    {
      icon: (
        <img
          src={CalmIcon}
          alt={t('petsittingaddress.form.environment.calm')}
        />
      ),
      label: t('petsittingaddress.form.environment.calm'),
      value: 'calm',
      checked: initialCheckedValue('calm')
    }
  ];
  const [currentClass, setCurrentClass] = useState(environmentOptions);

  useEffect(() => {
    setCurrentClass(environmentOptions);
  }, [formDataEnvironment]);

  const handleChangeClassName = (index) => {
    const newClass = [...currentClass];
    newClass[index].checked = !newClass[index].checked;
    setCurrentClass(newClass);

    // if formDataEnvironment doesn't include the value of the current item, add it
    if (!formDataEnvironment?.includes(currentClass?.[index]?.value)) {
      setFormDataEnvironment((prevState) => [
        ...prevState,
        currentClass[index].value
      ]);
    }
    // if formDataEnvironment includes the value of the current item and remove checked className, remove it
    if (
      formDataEnvironment?.includes(currentClass[index].value) &&
      !newClass[index].checked
    ) {
      const newArray = formDataEnvironment.filter(
        (item) => item !== currentClass[index].value
      );
      setFormDataEnvironment(newArray);
    }
  };

  const generalInformationsFields = [
    {
      noLabel: true,
      name: ['petsitting_address', 'type_of_environment'],
      input: (
        <Space className="environment-btn-row">
          {currentClass.map((item, index) => (
            <Space
              direction="vertical"
              key={item.value}
              className="environment-btn-wrapper"
            >
              <Button
                icon={item.icon}
                className={item.checked && 'checked'}
                onClick={() => handleChangeClassName(index)}
              />
              <Text>{item.label}</Text>
            </Space>
          ))}
        </Space>
      )
    },
    {
      label: 'living_space',
      name: ['petsitting_address', 'living_space'],
      input: <InputNumber addonAfter="m2" />
    },
    {
      label: 'available_pieces_number',
      name: ['petsitting_address', 'available_pieces_number']
    },
    {
      hidden: typeOfDwelling === 'HOUSE' || typeOfDwelling === '',
      label: 'floor_number',
      name: ['petsitting_address', 'floor_number']
    },
    {
      hidden: typeOfDwelling === 'HOUSE' || typeOfDwelling === '',
      label: 'elevator',
      name: ['petsitting_address', 'elevator'],
      input: (
        <Radio.Group
          options={[
            { label: t('owners.show.yes'), value: true },
            { label: t('owners.show.no'), value: false }
          ]}
        />
      )
    },
    {
      hidden: typeOfDwelling === 'HOUSE' || typeOfDwelling === '',
      label: 'code_lock',
      name: ['petsitting_address', 'code_lock']
    },
    {
      label: 'is_air_conditioned_house',
      name: ['petsitting_address', 'is_air_conditioned_house'],
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      label: 'small_business_nearby.is_exist',
      name: ['petsitting_address', 'small_business_nearby', 'is_exist'],
      input: (
        <Checkbox
          onChange={() => {
            setSmallBusinessExist(!smallBusinessExist);
          }}
        />
      ),
      valuePropName: 'checked'
    },
    {
      hidden: !smallBusinessExist,
      label: 'small_business_nearby.name_and_distance',
      name: [
        'petsitting_address',
        'small_business_nearby',
        'name_and_distance'
      ],
      rules: [
        () => {
          if (smallBusinessExist) {
            return {
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error(t('owners.form.messages.enter_small_business'))
                  );
                }
                return Promise.resolve();
              }
            };
          }
          return Promise.resolve();
        }
      ]
    },
    {
      label: 'super_market_nearby.is_exist',
      name: ['petsitting_address', 'super_market_nearby', 'is_exist'],
      input: (
        <Checkbox onChange={() => setSuperMarketExist(!superMarketExist)} />
      ),
      valuePropName: 'checked'
    },
    {
      hidden: !superMarketExist,
      label: 'super_market_nearby.name_and_distance',
      name: ['petsitting_address', 'super_market_nearby', 'name_and_distance'],
      rules: [
        () => {
          if (superMarketExist) {
            return {
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error(t('owners.form.messages.enter_super_market'))
                  );
                }
                return Promise.resolve();
              }
            };
          }
          return Promise.resolve();
        }
      ]
    },
    {
      label: 'means_of_transports_nearby',
      name: ['petsitting_address', 'means_of_transports_nearby']
    },
    {
      label: 'station_distance',
      name: ['petsitting_address', 'station_distance']
    },
    {
      label: 'nearby_city',
      name: ['petsitting_address', 'nearby_city']
    },
    {
      label: 'neighbor_distance',
      name: ['petsitting_address', 'neighbor_distance']
    },
    {
      label: 'nearby_site',
      name: ['petsitting_address', 'nearby_site'],
      input: <TextArea />
    }
  ];

  return {
    generalInformationsFields
  };
};

export default GeneralInformations;
