import { Drawer, Form, message as antdMessage } from 'antd';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { FirstStep } from './form/FirstStep';
import { SecondStep } from './form/SecondStep';
import { LastStep } from './form/LastStep';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { usersFormDataFormat } from '../../routes/usersShows/supervisions/utils/usersFormDataFormat';
import { adminFormDataFormat } from './utils/formDataFormat';
import { InfosStep } from './form/InfosStep';

export const ReviewDrawer = ({
  open,
  setOpen,
  t,
  supervision,
  setForceRefresh,
  questionnaireType,
  setQuestionnaireType,
  customInstanceForm,
  showReviewDrawer,
  supervisions,
  setSupervision,
  editReviewDrawer,
  reviewData,
  forceRefresh
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();

  const [current, setCurrent] = useState(user.role === 'admins:ADMIN' ? 0 : 1);
  const [infosStep, setInfosStep] = useState({});
  const [firstStep, setFirstStep] = useState({});
  const [secondStep, setSecondStep] = useState({});
  const [lastStep, setLastStep] = useState({});
  const [ratingDate, setRatingDate] = useState({});

  const handleSubmit = async () => {
    try {
      const action = editReviewDrawer ? 'PATCH' : 'POST';
      const url = editReviewDrawer ? `/ratings/${reviewData._id}` : '/ratings';

      await dispatchAPI(action, {
        url,
        body: ['users'].includes(user.role.split(':')[0])
          ? usersFormDataFormat({
              firstStep,
              secondStep,
              lastStep,
              supervision,
              user,
              userRole: questionnaireType || user.role
            })
          : adminFormDataFormat({
              infosStep,
              firstStep,
              secondStep,
              lastStep,
              supervision,
              user,
              ratingDate,
              userRole: questionnaireType
            })
      });

      antdMessage.success(
        t('owners.users_shows.review_drawer.form.messages.success')
      );

      setCurrent(0);
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  useEffect(async () => {
    if (Object.keys(lastStep).length) await handleSubmit();
    if (customInstanceForm) {
      customInstanceForm.resetFields();
    } else {
      form.resetFields();
    }
    setOpen(false);
  }, [lastStep]);

  const forms = [
    user.role === 'admins:ADMIN' && (
      <InfosStep
        form={customInstanceForm || form}
        setCurrent={setCurrent}
        t={t}
        setOpen={setOpen}
        user={user}
        infosStep={infosStep}
        setInfosStep={setInfosStep}
        supervision={supervision}
        supervisions={supervisions}
        questionnaireType={questionnaireType}
        setQuestionnaireType={setQuestionnaireType}
        setRatingDate={setRatingDate}
        showReviewDrawer={showReviewDrawer}
        setSupervision={setSupervision}
      />
    ),
    <FirstStep
      form={customInstanceForm || form}
      firstStep={firstStep}
      setFirstStep={setFirstStep}
      setCurrent={setCurrent}
      t={t}
      setOpen={setOpen}
      user={user}
      supervision={supervision}
      questionnaireType={questionnaireType}
      showReviewDrawer={showReviewDrawer}
    />,
    <SecondStep
      form={customInstanceForm || form}
      secondStep={secondStep}
      setSecondStep={setSecondStep}
      setCurrent={setCurrent}
      t={t}
      user={user}
      questionnaireType={questionnaireType}
      showReviewDrawer={showReviewDrawer}
    />,
    <LastStep
      form={customInstanceForm || form}
      lastStep={lastStep}
      setLastStep={setLastStep}
      t={t}
      setCurrent={setCurrent}
      user={user}
      showReviewDrawer={showReviewDrawer}
    />
  ];

  return (
    <Drawer
      width={600}
      onClose={() => {
        setOpen(false);
        setCurrent(0);
      }}
      open={open}
      title={t('owners.users_shows.review_drawer.form.title')}
    >
      {forms[current]}
    </Drawer>
  );
};

ReviewDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  supervision: PropTypes.shape({
    _id: PropTypes.string,
    pensioners: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  questionnaireType: PropTypes.string.isRequired,
  setQuestionnaireType: PropTypes.func,
  customInstanceForm: PropTypes.shape({
    resetFields: PropTypes.func
  }),
  showReviewDrawer: PropTypes.bool,
  supervisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSupervision: PropTypes.func.isRequired,
  editReviewDrawer: PropTypes.bool,
  reviewData: PropTypes.shape({
    _id: PropTypes.string
  }),
  forceRefresh: PropTypes.bool
};

ReviewDrawer.defaultProps = {
  customInstanceForm: null,
  showReviewDrawer: false,
  setQuestionnaireType: () => {},
  editReviewDrawer: false,
  reviewData: {},
  forceRefresh: false
};
