import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Select,
  Space
} from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;

/**
 * React component for the registration form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.switchForm - Function to switch between registration forms.
 * @returns {JSX.Element} React component.
 */
const RegisterForm = ({ switchForm }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [roleValue, setRoleValue] = useState();

  const actionToDuplicateKeyError = () => {
    notification.destroy('duplicate_email');
    switchForm('login');
  };

  const registerUser = async (values) => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('REGISTER', values);
      if (typeof data === 'string') {
        const splitMessage = data.split('_');
        message.success(
          t('login.secondary_profile_created', {
            role: t(`users.tags.${splitMessage[0]}`)
          })
        );
      } else {
        message.success(t('login.registrationSuccess'));
      }
      switchForm('login');
    } catch (e) {
      if (e.response) {
        if (e?.response?.data?.description?.message.includes('E11000')) {
          const role = e.response.data.description.message
            .split(' ')[1]
            .split('_')[0];
          const roleTranslated = t(`users.tags.${role}`);
          notification.warning({
            message: t('login.please_login', { role: roleTranslated }),
            key: 'duplicate_email',
            duration: null,
            btn: <Button onClick={actionToDuplicateKeyError}>Connexion</Button>
          });
        } else if (e?.response?.data?.message.includes('E11000')) {
          notification.warning({
            message: t('login.secondary_profile_already_exist'),
            key: 'duplicate_email',
            duration: null,
            btn: <Button onClick={actionToDuplicateKeyError}>Connexion</Button>
          });
        } else if (e.response.data && e.response.data.error) {
          message.error(e.response.data.error.description);
        } else {
          message.error(t('login.wrongDetails'));
        }
      } else if (e.request) {
        message.error(t('login.networkError'));
      } else {
        message.error(t('login.error'));
      }
    }
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    await registerUser({ ...values, email: values.username.toLowerCase() });
  };

  const onChangeRadio = ({ target: { value } }) => {
    setRoleValue(value);
  };

  const roleOptions = [
    {
      value: 'users:OWNER',
      label: t('login.owner')
    },
    {
      value: 'users:PENSIONER',
      label: t('login.pensioner')
    }
  ];

  return (
    <div>
      <Form onFinish={handleSubmit} layout="vertical">
        <div style={{ padding: '16px' }}>
          <Form.Item
            name="role"
            label={<b>{t('login.roles_register')}</b>}
            rules={[
              {
                required: true,
                message: t('errors.form.REQUIRED')
              }
            ]}
          >
            <Radio.Group
              options={roleOptions}
              optionType="button"
              buttonStyle="solid"
              onChange={onChangeRadio}
              value={roleValue}
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="last_name"
            label={<b>{t('login.last_name')}</b>}
            rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="first_name"
            label={<b>{t('login.first_name')}</b>}
            rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="username"
            label={<b>{t('login.username')}</b>}
            rules={[{ required: true }, { type: 'email' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<b>{t('login.phone-number')}</b>}
            rules={[
              { required: true, message: t('login.phone-number-missing') }
            ]}
          >
            <Space.Compact>
              <Form.Item
                noStyle
                name={['phone_number', 'country_code']}
                initialValue="+33"
              >
                <Select style={{ width: 70 }}>
                  <Option value="+33">+33</Option>
                </Select>
              </Form.Item>
              <Form.Item noStyle name={['phone_number', 'number']}>
                <Input />
              </Form.Item>
            </Space.Compact>
          </Form.Item>
        </div>
        <Form.Item
          style={{ textAlign: 'left', marginTop: '16px' }}
          name="CGU"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(t('login.CGUMissing'))
            }
          ]}
        >
          <Checkbox>
            <b>{`${t('login.CGU1')} `}</b>
            <Button
              type="link"
              style={{ padding: 0, height: 'auto' }}
              onClick={() => setModalVisible(!modalVisible)}
            >
              {`${t('login.CGU2')}`}
            </Button>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: '100%' }}
            loading={isLoading}
          >
            {t('login.registration')}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="link"
            style={{ width: '100%' }}
            onClick={() => switchForm('login')}
          >
            {t('login.connection')}
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title={<b>{t('login.terms_and_conditions')}</b>}
        open={modalVisible}
        bodyStyle={{ height: '500px', overflow: 'scroll' }}
        onCancel={() => setModalVisible(!modalVisible)}
        footer={[
          <Button
            key="Ok"
            onClick={() => setModalVisible(!modalVisible)}
            type="primary"
          >
            {t('login.includes')}
          </Button>
        ]}
      >
        <p>
          <b>Informations légales</b>
          <br />
          Les sites www.ani-seniors.fr et www.ani-seniors.com appartiennent et
          sont exploités par la S.A.S Ani Seniors Services 4 rue Maréchal Foch
          66140 Canet en Roussillon RCS PERPIGNAN N° 41083742100024
          www.ani-seniors.fr et www.ani-seniors.com sont des noms de domaine
          appartenant à la marque déposée Ani-Seniors-Services. Ani Seniors
          Services ne communique aucune donnée vous concernant à d’autres
          sociétés ou organismes.
          <br />
          <br />
          <b>Mentions légales</b>
          <br />
          L’utilisation du site Ani-Seniors-Services est régie par les termes et
          conditions générales d’utilisation détaillés ci-dessous. Tout
          utilisateur reconnaît en avoir pris connaissance et les accepter.
          Ani-seniors-Services se réserve la possibilité de modifier à tout
          moment et sans préavis l’accès à www.ani-seniors.fr et
          www.ani-seniors.com, ainsi que les termes des conditions générales.
          <br />
          <br />
          <b>Conditions Générales d’utilisation</b>
          <br />
          Par utilisateur, on entend toute personne physique ou morale qui vient
          à visiter les sites suivants : www.ani-seniors.fr et ani-seniors.com
          <br />
          <br />
          <b>Cookies</b>
          <br />
          Ani-seniors-Services ne collecte pas de données nominatives relatives
          aux utilisateurs du site, donc n’utilise pas volontairement les”
          cookies ” . Votre ordinateur les utilisant, celui-ci accédera plus
          rapidement au site.
          <br />
          <br />
          <b>Données nominatives</b>
          <br />
          Se référer à la rubrique Déclaration CNIL en bas de page.
          <br />
          <br />
          <b>Copyright et propriété intellectuelle</b>
          <br />
          Tous les écrans, graphismes et données présents sur les sites
          Ani-Seniors.fr et Ani-Seniors.com sont la propriété de la société
          Ani-Seniors-Services. L’ensemble des éléments édités sur ce site, sont
          protégés par les dispositions du code de la Propriété Intellectuelle.
          Ils sont la propriété exclusive de la société Ani-seniors-Services. En
          conséquence, aucun élément composant ce site ne peut être copié,
          reproduit, modifié, réédité, chargé, dénaturé, transmis ou distribué
          de quelle que manière que ce soit, de façon partielle ou intégrale,
          sans l’autorisation écrite et préalable de Ani-Seniors-Services. Seule
          la copie à usage privé est autorisée pour votre usage personnel, privé
          et non-commercial, sur votre ordinateur. L’accès au site vous confère
          un droit d’usage privé et non exclusif de ce site.
          <br />
          <br />
          <b>Nature des informations et limitation de responsabilité</b>
          <br />
          Les informations contenues sur le site sont données aux seules fins
          d’information de l’utilisateur et ne peuvent être considérées comme
          assorties d’une quelconque forme de garantie. De ce fait,
          l’utilisation du présent site s’effectue entièrement aux propres
          risques de l’utilisateur. Ani-Seniors-Services ne pourra être tenu
          pour responsables des dommages directs ou indirects, tels que,
          notamment, préjudice matériel, pertes de données ou de programme,
          préjudice financier, résultant de l’utilisation de ce site. En outre,
          Ani-Seniors-Services ne pourra être tenu responsable pour tous
          dommages résultant d’une intrusion frauduleuse d’un tiers, ayant
          entraîné une modification des informations ou éléments mis à la
          disposition sur le site.
          <br />
          <br />
          <b>Disponibilité du Site web</b>
          <br />
          Ani-Seniors-Services s’engage à tout mettre en œuvre pour assurer aux
          utilisateurs une accessibilité du site à tout moment.
          Ani-Seniors-Services pourra à tout moment, modifier ou interrompre,
          temporairement ou de façon permanente, tout ou partie du site pour
          effectuer des opérations de maintenance et/ou effectuer des
          améliorations et/ou des modifications sur le site.
          <br />
          <br />
          <b>Politique de confidentialité</b>
          <br />
          À compter du 25 mai 2018, nous nous alignons sur le nouveau règlement
          général sur la protection des Données (RGPD) de l’Union Européenne.
          Vous trouverez donc ci-dessous notre politique de confidentialité, la
          manière dont Ani Seniors Services protège votre vie Privée et les
          explications sur la manière d’exercer vos droits concernant vos
          données. Avant toute chose il nous paraît primordial de vous rappeler
          qu’Ani Seniors Services ne commercialise et ne diffuse pas les données
          que vous nous communiquez à d’autres organismes. Cette politique de
          confidentialité décrit les données personnelles qui sont collectées ou
          générées (traitées) lorsque vous utilisez les sites internet d’ ANI
          SENIORS SERVICES (“Sites”) et les applications mobiles d’ ANI SENIORS
          SERVICES (“Applis”). Elle explique également la façon dont vos données
          personnelles sont utilisées, partagées et protégées, les choix dont
          vous disposez s’agissant de vos données personnelles et comment nous
          contacter.
          <br />
          <br />
          <b>Qui est responsable du traitement de vos données personnelles ?</b>
          <br />
          Vincent POIZAT, Directeur Général d’Ani Seniors Services, est désigné
          comme responsable du Traitement de vos données personnelles
          <br />
          <br />
          <b>
            Quelles sont les données personnelles que nous collectons et quand
            est-ce que nous les collectons ?
          </b>
          <br />
          <br />
          <b>
            1) Vous êtes propriétaires d’un animal ou d’animaux et souhaitez les
            faire garder:
            <br />
          </b>
          Nous vous demandons de nous fournir certaines données personnelles
          pour vous fournir les services que vous sollicitez. Ces données
          personnelles comprennent : Les informations vous concernant : Nom,
          Prénom, adresse postale, e-mail, numéros de téléphone, profession,
          personne à prévenir en cas d’urgence, mot de passe et identifiant
          unique d’utilisateur, adresse de la garde. Les informations concernant
          vos animaux : Nom, âge, race et numéro de tatouage ou de puce de vos
          animaux, leurs habitudes alimentaires, soins, traitements, promenades,
          les coordonnées de votre vétérinaire et de votre toiletteur. Les
          informations concernant l’environnement de la garde et les équipements
          mis à disposition des retraités : Distances des commerces, distances
          des transports en commun, environnement, type de couchage, présence de
          plantes à arroser, équipements ménagers mis à disposition des
          retraités. Les informations concernant votre habitation (maison ou
          appartement) : Surfaces, présence d’extérieur, stationnement mis à
          disposition, rangement mis à disposition, coordonnées de votre
          assureur et numéro de contrat. Les photos de vos animaux et de votre
          habitation.
          <br />
          <br />
          <b>
            2) Vous êtes retraités et souhaitez vous inscrire pour réaliser des
            gardes au domicile des propriétaires:
            <br />
          </b>{' '}
          Nous vous demandons de nous fournir certaines données personnelles
          pour vous fournir les services que vous sollicitez.Ces données
          personnelles comprennent : Les informations vous concernant : Nom,
          Prénom, adresse postale, adresse mail, numéros de téléphone, date de
          naissance, ancienne profession, les coordonnées des personnes à
          prévenir en cas d’urgence, façon dont vous nous avez connu, langues
          parlées. Les informations sur votre expérience vis à vis des animaux :
          Nom, Race, particularités et âge des animaux que vous avez eu ou que
          vous avez encore. Les informations sur les gardes que vous souhaitez
          réaliser : moyen de transport pour vous y rendre, régions que vous
          souhaitez découvrir, disponibilités. Les documents relatifs à votre
          inscription : Attestation de caisse de retraite (nous n’avons pas à
          connaitre les montants perçus), attestation de domicile, extrait de
          casier judiciaire, attestation d’assurance, photocopie de votre pièce
          d’identité et une photo d’identité récente. Pourquoi et comment
          utilisons-nous vos données personnelles ? Ani Seniors Services ne vend
          pas vos données personnelles à des tiers. Pour les propriétaires
          d’animaux à faire garder, nous utilisons vos données personnelles
          uniquement dans le but de sélectionner et vous mettre en relation avec
          les personnes qui réaliseront la garde de votre animal ou vos animaux.
          Pour les retraités (Seniors), nous communiquons uniquement aux
          propriétaires des gardes que vous sélectionnerez, vos noms, prénoms,
          adresse, numéro de téléphone, âge, nombre de gardes réalisées dans
          notre société, adresse mail, photographie récente et habitude des
          animaux. Ani Seniors Services peut être amené à vous contacter,
          ponctuellement, par mail, SMS ou téléphone afin de vous faire part de
          propositions commerciales ou vous envoyer des communications marketing
          et des nouvelles à propos des services d’Ani Seniors Services. Pour
          traiter vos données personnelles, nous nous fondons sur certains
          fondements légaux qui dépendent de la façon dont vous interagissez
          avec notre Site et Appli. Quand vous utilisez nos services à partir de
          nos Sites et Applis, nous avons besoin de vos données personnelles
          pour exécuter le contrat qui nous lie à vous. Vous pouvez vous
          désinscrire à tout moment après avoir exprimé votre consentement.
          <br />
          <br />
          <b>Partage de vos données personnelles</b>
          <br />
          ANI SENIORS SERVICES NE COMMERCIALISE JAMAIS VOS INFORMATIONS AUPRES
          D’AUTRES SOCIETES NI NE LES COMMUNIQUE. Certaines de vos informations
          peuvent être transmises : Aux retraités susceptibles de venir garder
          votre animal ou vos animaux. Aux propriétaires chez qui vous irez
          réaliser des gardes. A d’autres tiers dans la mesure où cela est
          nécessaire pour : (i) se conformer à une requête gouvernementale, une
          décision de justice ou la loi applicable ; (ii) empêcher des
          utilisations illicites de nos Sites et Applis ou la violation des
          Conditions d’Utilisation de nos Sites et Applis et de nos règles ;
          (iii) nous défendre contre des réclamations de tiers ; et (iv) fournir
          notre assistance dans la prévention des fraudes ou des investigations.
          Nous pouvons également transférer les données personnelles vous
          concernant dans le cas où nous cédons ou transférons tout ou partie de
          nos activités ou actifs (en ce compris dans le cas d’une
          réorganisation, d’une cession, dissolution ou liquidation).
          <br />
          <br />
          <b>Vos partages:</b>
          <br />
          Lorsque vous utilisez certaines fonctionnalités sociales sur nos Sites
          ou Applis, vous pouvez créer un profil public qui peut contenir des
          informations telles que votre pseudonyme, votre photo de profil et
          votre ville. Vous pouvez aussi partager des contenus avec vos amis ou
          avec le public, comprenant des informations sur Ani Seniors Services.
          Protection et gestion de vos données personnelles
          <br />
          <br />
          <b>Cryptage et Sécurité:</b>
          <br />
          Nous utilisons différentes mesures de sécurité techniques et
          organisationnelles, et notamment le cryptage et des outils
          d’authentification, pour assurer la sécurité de vos données
          personnelles.
          <br />
          <br />
          <b>Transferts de vos données personnelles:</b>
          <br />
          Les données personnelles que nous collectons (ou traitons) pour nos
          Sites et Applis sont stockées chez notre hébergeur .
          <br />
          <br />
          <b>Conservation de vos données personnelles :</b>
          <br />
          Nous conservons vos données personnelles le temps nécessaire pour
          répondre aux finalités pour lesquelles elles ont été collectées et
          pour nous conformer à nos obligations légales, résoudre les litiges et
          faire respecter nos accords. La durée de conservation de vos données
          personnelles dépend des finalités pour lesquelles elles sont traitées
          et de la catégorie de données concernée. Nous conservons vos données
          personnelles aussi longtemps que votre compte Ani Seniors Services est
          actif ou aussi longtemps que nécessaire pour vous fournir nos
          services. Nous conservons vos données personnelles après la fermeture
          de votre compte si nous avons une obligation légale de le faire, ou si
          cela est nécessaire pour faire respecter nos accords, résoudre les
          litiges ou des problèmes de sécurité. Nous conservons vos données
          personnelles pour nos intérêts légitimes commerciaux, tels que la
          prévention de la fraude et l’amélioration de la sécurité de nos
          utilisateurs. Une fois la période de conservation expirée, nous
          détruisons ou anonymisons vos données personnelles de manière
          sécurisée.
          <br />
          <br />
          <b>Modifications de notre politique de confidentialité :</b>
          <br />
          La législation et les pratiques évoluent au fil du temps. Si nous
          décidons de mettre à jour notre politique de confidentialité, nous
          publierons les modifications sur nos Sites et Applis. Si nous
          modifions matériellement la façon dont nous traitons vos données
          personnelles nous vous demanderons d’accepter notre nouvelle politique
          de confidentialité avant de pouvoir utiliser nos services. Nous vous
          invitons vivement à lire notre politique de confidentialité et à
          vérifier régulièrement si des modifications y ont été apportées.
          <br />
          <br />
          <b>Conception et réalisation du site :</b>
        </p>
      </Modal>
    </div>
  );
};

RegisterForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};

export default RegisterForm;
