import { useTranslation } from 'react-i18next';
import { Button, Tag } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { templateStatus } from '../../utils/constants/tagColors';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * A custom hook that generates an array of column objects to be used with a data table component.
 * Each column object contains properties like title, key, dataIndex, width, and render to define how data
 * should be displayed in each column of the table.
 *
 * @function
 * @param {boolean} forceRefresh - A boolean to trigger the refresh of the data table.
 * @param {Function} setForceRefresh - A function to update the forceRefresh state.
 * @returns {Array<Object>} An array of column objects for displaying data in a data table.
 *
 * @example
 *
 * const [forceRefresh, setForceRefresh] = useState(false);
 * const columns = useColumns(forceRefresh, setForceRefresh);
 */
export const useColumns = (forceRefresh, setForceRefresh) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const patchDefaultTemplate = async ({ configuration, _id }) => {
    const { use_case } = configuration;
    try {
      await dispatchAPI('PATCH', {
        url: `template-documents/patch-default/${_id}/${use_case}`
      });
      setForceRefresh(!forceRefresh);
    } catch (error) {
      message(error);
    }
  };

  return [
    {
      title: t('template-documents.form.status'),
      key: 'status',
      dataIndex: 'status',
      width: '10%',
      sorter: true,
      render: (status) => (
        <Tag color={templateStatus[status]}>
          {t(`template-documents.form.${status}`)}
        </Tag>
      )
    },
    {
      title: t('template-documents.form.default_template'),
      key: 'default_template',
      width: '30%',
      sorter: true,
      render: (record) => (
        <Button onClick={() => patchDefaultTemplate(record)}>
          {record.default_template === true ? (
            <CheckOutlined />
          ) : (
            <CloseOutlined />
          )}
        </Button>
      )
    },
    {
      title: t('template-documents.form.title'),
      key: 'title',
      dataIndex: 'title',
      width: '30%',
      sorter: true
    },
    {
      title: t('template-documents.form.author'),
      key: 'author',
      dataIndex: 'author',
      width: '30%',
      sorter: true,
      render: (author) => `${author?.first_name} ${author?.last_name}`
    }
  ];
};
