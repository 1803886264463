import React, { useEffect } from 'react';
import { Button, Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { useMainInfoFields } from '../fields/mainInfoFields';

/**
 * Form component for collecting main information.
 *
 * @component
 * @param {object} props - The props that define this component.
 * @param {Function} props.onFinish - Function to handle form submission.
 * @param {object} props.initialValues - Initial values for the form fields.
 * @param {Function} props.t - Function for translation.
 * @param {object} props.enums - Enumeration values used in the form.
 * @param {boolean} props.isFieldsLoading - Indicates if form fields are loading.
 * @param {Function} props.dispatchAPI - Function to dispatch API requests.
 * @param {object} props.owner - Information about the owner.
 * @param {Function} props.setOwnerId - Function to set owner information.
 * @param {object[]} props.owners - List of owner objects.
 * @param {object[]} props.animals - List of animal objects.
 * @param {Function} props.setAnimals - Function to set animal information.
 * @param {object} props.form - Ant Design form object.
 * @param {Function} props.setStartDate - Function to set start date.
 * @param {Function} props.setEndDate - Function to set end date.
 * @returns {JSX.Element}
 */

export const MainInformationsForm = ({
  onFinish,
  initialValues,
  t,
  enums,
  isFieldsLoading,
  dispatchAPI,
  owner,
  setOwnerId,
  owners,
  animalsRef,
  form,
  startDate,
  endDate,
  userRole
}) => {
  const generateFields = useGenerateFormItem();
  const navigate = useNavigate();
  const { informationsFields } = useMainInfoFields({
    enums,
    t,
    isFieldsLoading,
    dispatchAPI,
    setOwnerId,
    owners,
    startDate,
    endDate,
    animalsRef,
    owner,
    userRole
  });

  const setDynamicFields = async () => {
    if (Object.keys(owner).length) {
      const { data } = await dispatchAPI('GET', {
        url: `/veterinarians?owner=${owner._id}`
      });

      form.resetFields(['main_informations', 'animals']);
      form.setFieldsValue({
        main_informations: {
          owner_name: owner._id,
          owner: owner._id,
          veterinarian: data.length ? data[0] : null
        }
      });
    }
  };

  useEffect(() => {
    setDynamicFields();
  }, [owner]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <>
      {informationsFields.map((field) => generateFields('supervisions', field))}
      <Flex justify="flex-end" gap="small">
        <Button type="link" danger onClick={() => navigate(-1)}>
          {t('buttons.cancel')}
        </Button>
        <Button type="add" onClick={onFinish}>
          {t('buttons.next')}
        </Button>
      </Flex>
    </>
  );
};

MainInformationsForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  enums: PropTypes.shape({}).isRequired,
  isFieldsLoading: PropTypes.bool.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  owner: PropTypes.shape({
    animals: PropTypes.arrayOf(PropTypes.shape({})),
    veterinarian: PropTypes.shape({}),
    _id: PropTypes.string
  }).isRequired,
  setOwnerId: PropTypes.func.isRequired,
  owners: PropTypes.arrayOf(PropTypes.shape({})),
  animalsRef: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired
  }).isRequired,
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  userRole: PropTypes.string
};
MainInformationsForm.defaultProps = {
  initialValues: {},
  owners: [],
  userRole: 'admins:ADMIN',
  startDate: {},
  endDate: {}
};
