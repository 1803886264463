import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col, Carousel, Image, Button } from 'antd';
import { generalPetsittingAddressInfo } from '../../../../../components/PetsittingAddress/listContent/petsittingAddressInfo';
import { DescriptionList } from '../../../../../components';
import PetsittingAddressInfoModal from '../modals/PetsittingAddressModal';
import { useAuthContext } from '../../../../../contexts/AuthContext';

/**
 * `PetsittingAddressInfoCards` is a component that displays information about a pet-sitting address,
 * including a carousel of photos and a description list with additional details.
 * It also manages a modal which can be opened to view more details.
 *
 * @component
 * @param {Object} props - The properties passed down from the parent component.
 * @param {Function} props.t - A function to handle the translation of strings.
 * @param {boolean} props.isLoading - A boolean flag to indicate whether data is currently being loaded.
 * @param {Object} props.owner - An object containing owner details, including information about the pet-sitting address.
 *
 * @returns {React.Element} JSX representation of the component.
 */
const PetsittingaddressInfoCards = ({ t, isLoading, owner }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { token } = useAuthContext();

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col xs={24} xxl={24}>
          <Card
            title={t('owners.show.petsitting_address_info')}
            className="form-card"
            extra={
              <Button onClick={() => setIsModalOpen(true)} size="small">
                {t('owners.buttons.show_more')}
              </Button>
            }
          >
            <Row gutter={[24, 24]}>
              <Col xs={24} md={8}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <Carousel autoplay>
                    {owner?.petsitting_address?.dwelling_photos.map((image) => (
                      <Image
                        key={image._id}
                        src={`${process.env.REACT_APP_API_URL}/owners/get-url/${image._id}/${token}`}
                      />
                    ))}
                  </Carousel>
                </Skeleton>
              </Col>
              <Col xs={24} md={16}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <DescriptionList
                    data={generalPetsittingAddressInfo(
                      owner?.petsitting_address || {},
                      t
                    )}
                    translate
                  />
                </Skeleton>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <PetsittingAddressInfoModal
        address={owner?.petsitting_address}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        t={t}
      />
    </>
  );
};

export default PetsittingaddressInfoCards;

PetsittingaddressInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  owner: PropTypes.shape({
    petsitting_address: PropTypes.shape({
      dwelling_photos: PropTypes.arrayOf(PropTypes.shape({}))
    })
  }).isRequired
};
