/**
 * Registers a custom button in the GrapesJS editor panel to handle array functionalities.
 *
 * @param {object} editorRef - A reference to the GrapesJS editor instance.
 * @param {Function} handleModal - A callback function to handle modal operations.
 * @param {Function} t - The translation function to translate the label of the button.
 * @returns {object} - The button object created in the panel.
 */
export const registerArrayButton = (editorRef, handleModal, t) => {
  const panelManager = editorRef.current.Panels;
  return panelManager.addButton('options', {
    id: 'custom-button',
    className: 'custom-button',
    label: t('template-documents.form.array-button'),
    command: handleModal
  });
};
