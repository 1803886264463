export const fileExtensionManage = (fileExtension) => {
  const fileExtensionList = [
    'png',
    'PNG',
    'jpg',
    'JPG',
    'jpeg',
    'JPEG',
    'heic',
    'HEIC'
  ];
  if (fileExtensionList.includes(fileExtension)) {
    return true;
  }
  return false;
};
