import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A React component that defines and returns an array of field configurations
 * for capturing outside information in a form. It utilizes the `useTranslation`
 * hook from `react-i18next` to provide translations for the label of each
 * checkbox.
 *
 * @returns {Object} An object containing the following properties:
 * - `outsideInformationsFields`: An array of objects, each representing a field
 *   configuration for a checkbox input capturing various outside area features.
 *   The configurations include properties such as `noLabel`, `label`, `name`,
 *   `input`, and `valuePropName`.
 *
 * @function
 * @example
 *
 * import OutsideInformations from './OutsideInformations';
 *
 * const { outsideInformationsFields } = OutsideInformations();
 *
 * // Now, outsideInformationsFields can be used to generate a form or part of a form
 */
const OutsideInformations = () => {
  const { t } = useTranslation();

  const outsideInformationsFields = [
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'garden'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.garden'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'closed'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.closed'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'type_of_storage_for_pensioner.outside.surface',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'surface'
      ]
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'gardener_during_supervision'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.gardener_during_supervision'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'automatic_spraying'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.automatic_spraying'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'external_plant'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.external_plant'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'type_of_storage_for_pensioner.outside.external_time_to_give',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'external_time_to_give'
      ]
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'interior_plant'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.interior_plant'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'type_of_storage_for_pensioner.outside.interior_time_to_give',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'interior_time_to_give'
      ]
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'swimming_pool'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.swimming_pool'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'type_of_storage_for_pensioner.outside.to_maintain',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'to_maintain'
      ]
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'jacuzzi'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.jacuzzi'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'type_of_storage_for_pensioner.outside.detail',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'detail'
      ]
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'garden_furniture'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.garden_furniture'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'barbecue'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.barbecue'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'balcony'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.balcony'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'outside',
        'patio'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.patio'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    }
  ];

  return {
    outsideInformationsFields
  };
};

export default OutsideInformations;
