import { Tag, Divider, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  RollbackOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useRef } from 'react';
import { OwnerStatus } from '../../utils/constants/tagColors';
import { useListResourceArchiving } from '../../utils/listResourceArchiving';
import { postalCodeFilter } from './utils/ownersFilters';

/**
 * Custom hook that returns columns and headers for the owners table.
 * @hook
 * @param {Function} setForceRefresh - Function to force refresh the table.
 * @returns {Object} - Object containing columns and headers.
 */
export const useColumns = (setForceRefresh) => {
  const { t } = useTranslation();
  const { deleteResource, restoreResource } = useListResourceArchiving(
    setForceRefresh,
    'owners/archiving'
  );

  const searchInput = useRef(null);

  const columns = [
    {
      title: t('owners.form.reference'),
      key: 'reference',
      dataIndex: 'reference',
      sorter: true
    },
    {
      title: t('owners.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('owners.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('owners.form.address.city'),
      key: 'address',
      dataIndex: 'address',
      render: (address) => address?.city,
      sorter: true
    },
    {
      title: t('owners.form.address.postal_code'),
      key: 'address.postal_code',
      dataIndex: 'address',
      ...postalCodeFilter(searchInput, t),
      render: (address) => address?.postal_code,
      sorter: true
    },
    {
      title: t('owners.form.department'),
      key: 'address',
      dataIndex: 'address',
      render: (address) => address?.state,
      sorter: (address) => address?.state
    },
    {
      title: t('owners.form.phone_number_full'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) => phone_number?.number,
      sorter: true
    },
    {
      title: t('owners.form.cell_phone_number_full'),
      key: 'cell_phone_number',
      dataIndex: 'cell_phone_number',
      render: (cell_phone_number) => cell_phone_number?.number,
      sorter: true
    },
    {
      title: t('owners.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('owners.form.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) =>
        status ? (
          <Tag color={OwnerStatus[status]}>{t(`owners.tags.${status}`)}</Tag>
        ) : (
          'N/R'
        ),
      sorter: true
    },
    {
      key: 'actions',
      align: 'rigth',
      fixed: 'rigth',
      width: 100,
      render: (record) => (
        <>
          <Tooltip
            title={t('datatable.column.action.show.hover')}
            placement="left"
          >
            <Link to={{ pathname: `/owners/show/${record._id}` }}>
              <EyeOutlined style={{ fontSize: 18 }} />
            </Link>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={t('datatable.column.action.edit.hover')}
            placement="left"
          >
            <Link to={{ pathname: `/owners/edit/${record._id}` }}>
              <EditOutlined style={{ fontSize: 18 }} />
            </Link>
          </Tooltip>
          <>
            <Divider type="vertical" />
            {!record.is_archived ? (
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() => deleteResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <Tooltip
                  title={t('datatable.column.action.archive.hover')}
                  placement="left"
                >
                  <ContainerOutlined
                    style={{ color: '#b51010', fontSize: 18 }}
                    type="delete"
                  />
                </Tooltip>
              </Popconfirm>
            ) : (
              <Popconfirm
                title={t('datatable.column.action.unarchived.title')}
                okText={t('datatable.column.action.unarchived.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.unarchived.cancel')}
                onConfirm={() => restoreResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <Tooltip
                  title={t('datatable.column.action.unarchived.hover')}
                  placement="left"
                >
                  <RollbackOutlined
                    style={{ color: '#b51010', fontSize: 18 }}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </>
        </>
      )
    }
  ];

  const headers = [
    {
      label: 'reference',
      key: 'reference'
    },
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'address.city',
      key: 'address.city'
    },
    {
      label: 'address.postal_code',
      key: 'address.postal_code'
    },
    {
      label: 'address.state',
      key: 'address.state'
    },
    {
      label: 'phone_number.number',
      key: 'phone_number.number'
    },
    {
      label: 'cell_phone_number.number',
      key: 'cell_phone_number.number'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'status',
      key: 'owner_status'
    }
  ];

  return { columns, headers };
};
