import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Steps, message as Message, Form } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../../components';
import { MainInformationsForm } from './forms/MainInformations';
import { SupervisionAddressForm } from './forms/SupervisionAddress';
import { SummaryForm } from './forms/Summary';
import { useConfig } from './utils/CreateUpdateConfig';
import { CreateUpdateContainerSupervision } from './utils/CreateUpdateContainerSupervision';
import { getOwners } from './utils/getOwners';
import { getEnums } from './utils/getEnums';
import { getSupervisionPrices } from './utils/getSupervisionPrices';
import { onFinishInfoForm } from './utils/onFinishInfoForm';
import { onFinishSupervisionAddressForm } from './utils/onFinishSupervisionAddressForm';
import { onFinishSummaryForm } from './utils/onFinishSummaryForm';
import { submitForm } from './utils/submitForm';
import { addDuration } from './utils/addDuration';
import { changeForm } from './utils/changeForm';
import { addSupervisionAddressData } from './utils/addSupervisionAddressData';
import { handlePrevious } from './utils/handlePrevious';
import { formatNewPetsittingAddressFiles } from '../../utils/formatNewPetsittingAddressFiles';
import { getOwner } from './utils/getOwner';
import { useUnlockEditProfile } from '../../utils/useUnlockEditProfile';
import { useHandleProfileLock } from '../../utils/useHandleProfileLock';

/**
 * Component for creating or updating a supervision record.
 *
 * @component
 * @param {Object} props - The props that define this component.
 * @param {string} props.purpose - The purpose of the form (create or update).
 * @returns {JSX.Element}
 */

export const CreateUpdateSupervision = ({ purpose }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const location = useLocation();
  const { unlockEditProfile } = useUnlockEditProfile(id, 'supervisions');
  const { handleProfileLock } = useHandleProfileLock(id, 'supervisions');

  const [current, setCurrent] = useState(0);
  const [informationsData, setInformationData] = useState({});
  const [supervisionAddressData, setSupervisionAddressData] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [supervisionPrices, setSupervisionPrices] = useState([]);
  const [ownerId, setOwnerId] = useState(null);
  const [supervisionPrice, setSupervisionPrice] = useState({});
  const [owner, setOwner] = useState({});
  const [owners, setOwners] = useState([]);
  const animalsRef = useRef([]);
  const startDateRef = useRef({});
  const endDateRef = useRef({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [duration, setDuration] = useState([]);
  const [differenceDays, setDifferenceDays] = useState(0);
  const [selectedAnouncePhotos, setSelectedAnouncePhotos] = useState([]);
  const [animalsFileList, setAnimalsFileList] = useState([]);
  const [reduction, setReduction] = useState(0);
  const [petsittingAddressFileList, setPetsittingAddressFileList] = useState(
    []
  );
  const [formDataEnvironment, setFormDataEnvironment] = useState([]);
  const [country, setCountry] = useState('');

  const searchParams = new URLSearchParams(location.search);
  const OwnerId = searchParams.get('id');

  useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await handleProfileLock();
      })();

      window.addEventListener('beforeunload', async () => {
        await unlockEditProfile();
      });
    }

    return async () => {
      if (purpose === 'edit') {
        // clean up the event handler when the component unmounts
        window.removeEventListener('beforeunload', async () => {
          await unlockEditProfile();
        });
        // call the unlock func when the component unmounts
        await unlockEditProfile();
      }
    };
  }, []);

  useEffect(() => {
    if (purpose === 'create' && OwnerId) {
      getOwner({ dispatchAPI, setOwner, message, OwnerId });
    }
  }, [purpose]);

  useEffect(async () => {
    if (ownerId) {
      await getOwner({ dispatchAPI, setOwner, message, OwnerId: ownerId });
    }
  }, [ownerId]);

  useEffect(async () => {
    if (purpose === 'create') {
      await addSupervisionAddressData({
        owner,
        setSupervisionAddressData,
        setPetsittingAddressFileList,
        message,
        dispatchAPI
      });
    }
  }, [owner]);

  useEffect(() => {
    if (animalsRef.current.length) {
      animalsRef.current.forEach((animal) => {
        animal.animal_photo?.forEach((photo) => {
          setAnimalsFileList((previousResult) => [...previousResult, photo]);
        });
      });
    }
  }, [animalsRef.current]);

  const getSelectOptions = useCallback(async () => {
    await getEnums({ dispatchAPI, setEnums, message });
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const config = useConfig({
    setPetsittingAddressFileList,
    dispatchAPI,
    message,
    setSelectedAnouncePhotos,
    setSupervisionAddressData,
    startDateRef,
    endDateRef,
    animals: animalsRef,
    setReduction,
    setOwner,
    setFormDataEnvironment,
    setCountry,
    setAnimalsFileList
  });

  useEffect(() => {
    if (formSubmitted && Object.keys(summaryData).length) {
      (async () => {
        await submitForm({
          informationsData,
          supervisionAddressData,
          summaryData,
          petsittingAddressFileList,
          purpose,
          dispatchAPI,
          id,
          Message,
          t,
          navigate,
          message,
          selectedAnouncePhotos,
          supervisionPrice
        });
      })();
    }
  }, [formSubmitted, summaryData]);

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getSupervisionPrices({
        dispatchAPI,
        setSupervisionPrices,
        message
      });
      await getOwners({ dispatchAPI, setOwners, message });
      setIsFieldsLoading(false);
    })();
  }, []);

  useEffect(() => {
    addDuration({
      startDate: startDateRef.current,
      endDate: endDateRef.current,
      setDifferenceDays,
      supervisionPrices,
      form,
      setDuration
    });
  }, [startDateRef.current, endDateRef.current]);

  useEffect(async () => {
    await formatNewPetsittingAddressFiles({
      petsittingAddressFileList,
      dispatchAPI,
      message,
      setPetsittingAddressFileList
    });
  }, [petsittingAddressFileList]);

  const forms = [
    <MainInformationsForm
      onFinish={() =>
        onFinishInfoForm({
          form,
          setInformationData,
          informationsData,
          setCurrent,
          Message,
          t
        })
      }
      enums={enums}
      initialValues={informationsData}
      t={t}
      isFieldsLoading={isFieldsLoading}
      dispatchAPI={dispatchAPI}
      owner={owner}
      setOwner={setOwner}
      setOwnerId={setOwnerId}
      owners={owners}
      animalsRef={animalsRef}
      form={form}
      startDate={startDateRef}
      endDate={endDateRef}
      userRole="admins:ADMIN"
      purpose={purpose}
    />,
    <SupervisionAddressForm
      onFinish={() =>
        onFinishSupervisionAddressForm({
          form,
          setSupervisionAddressData,
          setCurrent,
          Message,
          t
        })
      }
      handlePrevious={() => handlePrevious({ setCurrent, current })}
      enums={enums}
      initialValues={supervisionAddressData}
      isFieldsLoading={isFieldsLoading}
      t={t}
      owner={owner}
      form={form}
      fileList={petsittingAddressFileList}
      setFileList={setPetsittingAddressFileList}
      formDataEnvironment={formDataEnvironment}
      setFormDataEnvironment={setFormDataEnvironment}
      country={country}
      setCountry={setCountry}
    />,
    <SummaryForm
      onFinish={() =>
        onFinishSummaryForm({
          form,
          setSummaryData,
          setCurrent,
          setFormSubmitted,
          Message,
          t
        })
      }
      handlePrevious={() => handlePrevious({ setCurrent, current })}
      enums={enums}
      initialValues={summaryData}
      isFieldsLoading={isFieldsLoading}
      t={t}
      supervisionPrices={supervisionPrices}
      form={form}
      duration={duration}
      setDuration={setDuration}
      differenceDays={differenceDays}
      animalsFileList={animalsFileList}
      petsittingAddressFileList={petsittingAddressFileList}
      selectedAnouncePhotos={selectedAnouncePhotos}
      setSelectedAnouncePhotos={setSelectedAnouncePhotos}
      reduction={reduction}
      setReduction={setReduction}
      supervisionPrice={supervisionPrice}
      setSupervisionPrice={setSupervisionPrice}
    />
  ];
  const steps = [
    {
      title: t('supervisions.form.main_informations.title'),
      icon: <CheckCircleOutlined />
    },
    {
      title: t('supervisions.form.supervision_address'),
      icon: <CheckCircleOutlined />
    },
    {
      title: t('supervisions.form.summary.title'),
      icon: <CheckCircleOutlined />
    }
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title
  }));

  return (
    <ContentCustom>
      <>
        <Steps
          current={current}
          items={items}
          onChange={(e) =>
            changeForm({
              nextStep: e,
              form,
              setCurrent,
              Message,
              t,
              current,
              setInformationData,
              setSupervisionAddressData,
              setSummaryData
            })
          }
          style={{ marginBottom: 24 }}
        />
        <CreateUpdateContainerSupervision
          loadingFields={isFieldsLoading}
          purpose={purpose}
          customFormInstance={form}
          baseUrl="supervisions/form"
          resource="supervisions"
          config={config}
          formExtra={forms[current]}
        />
      </>
    </ContentCustom>
  );
};

CreateUpdateSupervision.propTypes = {
  purpose: PropTypes.string.isRequired
};
