export const generateFilterAndRecordID = ({
  dispatchAPI,
  documentType,
  id,
  option,
  supervision,
  setFilterID,
  setRecordID
}) => {
  const ownerDocumentType = [
    { type: 'OWNER_CONTRACT', record_id: option._id },
    { type: 'OWNER-INVOICE', invoice_type: 'BALANCE' },
    { type: 'OWNER-INVOICE', invoice_type: 'ADVANCE_PAYMENT' }
  ];

  const filterID = [];
  const recordID = [];

  switch (documentType) {
    case 'OWNER_CONTRACT':
      ownerDocumentType.map(async (doc) => {
        if (doc.type === 'OWNER-INVOICE') {
          const { data } = await dispatchAPI('GET', {
            url: `customerinvoices?supervision=${supervision._id}&type=${doc.invoice_type}`
          });

          if (data.length) {
            recordID.push(data[0]._id);
          }
        } else {
          filterID.push(option._id);
          recordID.push(id);
        }
      });
      break;
    default:
      filterID.push(option._id);
      recordID.push(id);
      break;
  }

  setFilterID(filterID);
  setRecordID(recordID);
};
