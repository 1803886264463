import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A React component function that generates an array of field configurations
 * for parking and installation information in a form. It takes into account the
 * type of dwelling to selectively hide or show certain fields. It utilizes the
 * `useTranslation` hook from `react-i18next` to provide translations for the label
 * of each checkbox.
 *
 * @param {string} typeOfDwelling - The type of dwelling to base the visibility
 * of certain fields on. Can be 'HOUSE' or other values to determine the hidden
 * property of residence field.
 *
 * @returns {Object} An object containing the following properties:
 * - `parkingAndInstallationInformationsFields`: An array of objects, each
 * representing a field configuration for a checkbox input capturing various parking
 * and installation details. The configurations include properties such as `hidden`,
 * `noLabel`, `name`, `input`, and `valuePropName`.
 *
 * @function
 * @example
 *
 * import ParkingAndInstallationInformations from './ParkingAndInstallationInformations';
 *
 * const { parkingAndInstallationInformationsFields } = ParkingAndInstallationInformations('HOUSE');
 *
 * // Now, parkingAndInstallationInformationsFields can be used to generate a form or part of a form
 */
const ParkingAndInstallationInformations = (typeOfDwelling) => {
  const { t } = useTranslation();

  const parkingAndInstallationInformationsFields = [
    {
      hidden: typeOfDwelling === 'HOUSE' || typeOfDwelling === '',
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'parking_and_installation',
        'parking',
        'residence'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.parking.residence'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'parking_and_installation',
        'parking',
        'garden'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.parking.garden'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'parking_and_installation',
        'parking',
        'street'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.parking.street'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'parking_and_installation',
        'parking',
        'charged'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.parking.charged'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'parking_and_installation',
        'parking',
        'garage'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.parking.garage'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'parking_and_installation',
        'parking',
        'no_parking'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.parking.no_parking'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'parking_and_installation',
        'installation',
        'alarm'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.installation.alarm'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'parking_and_installation',
        'installation',
        'telesurveillance'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.installation.telesurveillance'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    }
  ];

  return {
    parkingAndInstallationInformationsFields
  };
};

export default ParkingAndInstallationInformations;
