import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col, Avatar } from 'antd';
import { ownerPersonalInfo } from '../listContent/ownerPersonalInfo';
import { DescriptionList } from '../../../../../components';

const OwnerPersonalInfoCards = ({ t, isLoading, owner, token }) => (
  <Card title={t('owners.show.owners_personal_info')} className="form-card">
    <Row gutter={[0, 24]}>
      <Col xs={24} md={8}>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          {owner?.photo && (
            <Avatar
              src={`${process.env.REACT_APP_API_URL}/owners/get-url/${owner.photo}/${token}`}
              size={160}
            />
          )}
        </Skeleton>
      </Col>
      <Col xs={24} md={16}>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList data={ownerPersonalInfo(owner || {}, t)} translate />
        </Skeleton>
      </Col>
    </Row>
  </Card>
);

export default OwnerPersonalInfoCards;

OwnerPersonalInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  owner: PropTypes.shape({
    photo: PropTypes.string
  }).isRequired,
  token: PropTypes.string.isRequired
};
