import { useTranslation } from 'react-i18next';
import { Row, Card, Col, Collapse, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { DescriptionList } from '../../../../../components';
import { useVeterinarianInfos } from '../listContent/veterinarianInfos';
import { useGroomerInfos } from '../listContent/groomerInfos';
import { generatePDF } from '../../../../../components/TemplateDocumentButton/TemplateDocumentButton';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

const { Panel } = Collapse;

export const VeterinarianGroomerCard = ({ veterinarian, groomer }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const { veterinarianInfos } = useVeterinarianInfos(veterinarian || {});
  const { groomerInfos } = useGroomerInfos(groomer || {});

  return (
    <Collapse accordion>
      <Panel header={t('owners.show.veterinarian_groomer.title')} key="1">
        <Row gutter={[12, 12]}>
          <Col xs={12} xxl={12}>
            <Card
              bordered={false}
              title={t('owners.show.veterinarian_groomer.veterinarian')}
              className="form-card"
              extra={[
                <Button
                  onClick={() =>
                    generatePDF({
                      dispatchAPI,
                      id: veterinarian._id,
                      collection: 'Veterinarian',
                      use_case: 'VETERINARIAN_SYNTHESIS',
                      message
                    })
                  }
                >
                  <DownloadOutlined />
                </Button>
              ]}
            >
              <DescriptionList data={veterinarianInfos} translate />
            </Card>
          </Col>
          <Col xs={12} xxl={12}>
            <Card
              bordered={false}
              title={t('owners.show.veterinarian_groomer.groomer')}
              className="form-card"
              extra={[
                <Button
                  onClick={() =>
                    generatePDF({
                      dispatchAPI,
                      id: groomer._id,
                      collection: 'Groomer',
                      use_case: 'GROOMER_SYNTHESIS',
                      message
                    })
                  }
                >
                  <DownloadOutlined />
                </Button>
              ]}
            >
              <DescriptionList data={groomerInfos} translate />
            </Card>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

VeterinarianGroomerCard.propTypes = {
  veterinarian: PropTypes.shape({
    _id: PropTypes.string
  }),
  groomer: PropTypes.shape({
    _id: PropTypes.string
  })
};

VeterinarianGroomerCard.defaultProps = {
  veterinarian: {},
  groomer: {}
};
