import { Button, Popconfirm, Dropdown, notification } from 'antd';
import {
  RedoOutlined,
  UploadOutlined,
  EditOutlined,
  WarningOutlined,
  DeleteOutlined,
  CloseOutlined,
  PlusOutlined,
  DownOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { routes } from '../../utils/constants/adminRoutes';
import { updateSupervisionStatus } from './ShowSupervision/utils/updateSupervisionStatus';
import { deleteSupervision } from './ShowSupervision/utils/deleteSupervision';
import { ContractMenu } from './utils/ContractMenu';
import { SatisfactionMenu } from './utils/SatisfactionMenu';
import { InformationMenu } from './utils/InformationMenu';
import { generateDocumentsOld } from '../mail-sender/utils/generateDocumentsOld';
import { editingLocked } from '../../utils/editingLocked';

/**
 * Génère les boutons d'action en fonction du statut de la supervision.
 * @param {string} status - Le statut de la supervision.
 * @param {Object} t - L'objet de traduction de react-i18next.
 * @param {string} id - L'identifiant de la supervision.
 * @param {Function} setAnounceModalVisible - La fonction pour définir la visibilité de la modal d'annonce.
 * @param {Function} dispatchAPI - La fonction de dispatch de l'API.
 * @param {Function} setRefreshData - La fonction pour rafraîchir les données.
 * @param {boolean} refreshData - L'état de rafraîchissement des données.
 * @param {Function} message - La fonction pour afficher un message d'Ant Design.
 * @param {Function} setCancelSupervisionModalVisible - La fonction pour définir la visibilité de la modal d'annulation de supervision.
 * @param {Function} setInterruptSupervisionModalVisible - La fonction pour définir la visibilité de la modal d'interruption de supervision.
 * @param {Object} supervision - L'objet de la supervision.
 * @returns {JSX.Element} Les boutons d'action.
 */

export const actionButtons = ({
  status,
  t,
  id,
  setAnounceModalVisible,
  dispatchAPI,
  setRefreshData,
  refreshData,
  message,
  setCancelSupervisionModalVisible,
  setInterruptSupervisionModalVisible,
  supervision,
  setRecipients,
  setIsEmailModalOpen,
  setTemplateType,
  setDocumentType,
  setFilterID,
  setRecordID,
  customerInvoices,
  editSupervisionLock
}) => {
  switch (status) {
    case 'REQUEST':
      return (
        <>
          <Button
            type="primary"
            onClick={() =>
              updateSupervisionStatus({
                nextStatus: 'WAITING',
                dispatchAPI,
                id,
                setRefreshData,
                refreshData,
                message
              })
            }
          >
            <RedoOutlined />
            {`${t('supervisions.show.buttons.confirm_supervision')} `}
          </Button>
          <Button
            type="primary"
            onClick={() =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                'SUPERVISION_SUMMARY',
                id,
                undefined,
                t
              )
            }
          >
            <UploadOutlined />
            {`${t('supervisions.show.buttons.generate_synthesis')} `}
          </Button>
          <Link
            to={{
              pathname: !editingLocked(editSupervisionLock, id)
                ? `${routes.SUPERVISIONS}/edit/${id}`
                : ''
            }}
          >
            <Button
              type="primary"
              onClick={() =>
                editingLocked(editSupervisionLock, id) &&
                notification.warning({
                  message: t('pensioners.profile_lock'),
                  duration: false
                })
              }
            >
              {`${t('buttons.edit')} `}
              <EditOutlined />
            </Button>
          </Link>
          <Popconfirm
            title={t('datatable.column.action.archive.title')}
            okText={t('datatable.column.action.archive.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.archive.cancel')}
            onConfirm={() =>
              deleteSupervision({
                dispatchAPI,
                id,
                setRefreshData,
                refreshData,
                message
              })
            }
            icon={<WarningOutlined />}
          >
            <Button type="link" danger>
              {`${t('buttons.archive')} `}
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </>
      );
    case 'WAITING':
      return (
        <>
          <Button type="primary" onClick={() => setAnounceModalVisible(true)}>
            <RedoOutlined />
            {`${t('supervisions.show.buttons.create_anounce')} `}
          </Button>
          <Button
            type="primary"
            onClick={() =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                'SUPERVISION_SUMMARY',
                id,
                undefined,
                t
              )
            }
          >
            <UploadOutlined />
            {`${t('supervisions.show.buttons.generate_synthesis')} `}
          </Button>
          <Link
            to={{
              pathname: !editingLocked(editSupervisionLock, id)
                ? `${routes.SUPERVISIONS}/edit/${id}`
                : ''
            }}
          >
            <Button
              type="primary"
              onClick={() =>
                editingLocked(editSupervisionLock, id) &&
                notification.warning({
                  message: t('pensioners.profile_lock'),
                  duration: false
                })
              }
            >
              {`${t('buttons.edit')} `}
              <EditOutlined />
            </Button>
          </Link>
          <Popconfirm
            title={t('datatable.column.action.archive.title')}
            okText={t('datatable.column.action.archive.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.archive.cancel')}
            onConfirm={() =>
              deleteSupervision({
                dispatchAPI,
                id,
                setRefreshData,
                refreshData,
                message
              })
            }
            icon={<WarningOutlined />}
          >
            <Button type="link" danger>
              {`${t('buttons.archive')} `}
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </>
      );
    case 'PUBLISHED':
      return (
        <>
          <Button
            type="primary"
            onClick={() =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                'SUPERVISION_SUMMARY',
                id,
                undefined,
                t
              )
            }
          >
            <UploadOutlined />
            {`${t('supervisions.show.buttons.generate_synthesis')} `}
          </Button>
          <Button
            type="primary"
            style={{ backgroundColor: 'red' }}
            onClick={() => setCancelSupervisionModalVisible(true)}
          >
            <CloseOutlined />
            {`${t('supervisions.show.buttons.cancel_supervision')} `}
          </Button>
        </>
      );
    case 'WAITING_BOOKING':
      return (
        <>
          <Dropdown
            overlay={
              <InformationMenu
                supervision={supervision}
                setRecipients={setRecipients}
                setIsEmailModalOpen={setIsEmailModalOpen}
                setTemplateType={setTemplateType}
                setDocumentType={setDocumentType}
                setFilterID={setFilterID}
                setRecordID={setRecordID}
              />
            }
          >
            <Button type="primary">
              {`${t('supervisions.show.buttons.send_informations')} `}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Button
            type="primary"
            onClick={() =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                'SUPERVISION_SUMMARY',
                id,
                undefined,
                t
              )
            }
          >
            <UploadOutlined />
            {`${t('supervisions.show.buttons.generate_synthesis')} `}
          </Button>
          <Button
            type="primary"
            style={{ backgroundColor: 'red' }}
            onClick={() => setCancelSupervisionModalVisible(true)}
          >
            <CloseOutlined />
            {`${t('supervisions.show.buttons.cancel_supervision')} `}
          </Button>
        </>
      );
    case 'BOOKED':
      return (
        <>
          <Link
            to={{
              pathname: '/invoices/customers/create',
              search: `?ownerId=${supervision.owner}&supervisionId=${id}&ownerUserId=${supervision.main_informations.owner.user._id}`
            }}
          >
            <Button type="primary">
              <PlusOutlined />
              {`${t('supervisions.show.buttons.create_invoice')} `}
            </Button>
          </Link>
          <Dropdown
            overlay={
              <ContractMenu
                supervision={supervision}
                setRecipients={setRecipients}
                setIsEmailModalOpen={setIsEmailModalOpen}
                setTemplateType={setTemplateType}
                setDocumentType={setDocumentType}
                setFilterID={setFilterID}
                setRecordID={setRecordID}
              />
            }
          >
            <Button type="primary">
              {`${t('supervisions.show.buttons.send_contracts')} `}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Dropdown
            overlay={
              <InformationMenu
                supervision={supervision}
                setRecipients={setRecipients}
                setIsEmailModalOpen={setIsEmailModalOpen}
                setTemplateType={setTemplateType}
                setDocumentType={setDocumentType}
                setFilterID={setFilterID}
                setRecordID={setRecordID}
              />
            }
          >
            <Button type="primary">
              {`${t('supervisions.show.buttons.send_informations')} `}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Button
            type="primary"
            onClick={() =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                'SUPERVISION_SUMMARY',
                id,
                undefined,
                t
              )
            }
          >
            <UploadOutlined />
            {`${t('supervisions.show.buttons.generate_synthesis')} `}
          </Button>
          <Button
            type="primary"
            style={{ backgroundColor: 'red' }}
            onClick={() => setCancelSupervisionModalVisible(true)}
          >
            <CloseOutlined />
            {`${t('supervisions.show.buttons.cancel_supervision')} `}
          </Button>
        </>
      );
    case 'IN_PROGRESS':
      return (
        <>
          <Button
            type="primary"
            style={{ backgroundColor: 'red' }}
            onClick={() => {
              const balanceInvoice = customerInvoices.find(
                (invoice) => invoice.type === 'BALANCE'
              );
              setRecipients([
                {
                  ...supervision.main_informations.owner,
                  user_collection: 'owners',
                  customer_type: 'OWNER'
                }
              ]);
              setTemplateType('UNPAID_INVOICE_REMINDER');
              setIsEmailModalOpen(true);
              setDocumentType('OWNER-INVOICE');
              setRecordID(balanceInvoice?._id);
            }}
          >
            {`${t('supervisions.show.buttons.call_again_invoice')} `}
          </Button>
          <Dropdown
            overlay={
              <ContractMenu
                supervision={supervision}
                setRecipients={setRecipients}
                setIsEmailModalOpen={setIsEmailModalOpen}
                setTemplateType={setTemplateType}
                setDocumentType={setDocumentType}
                setFilterID={setFilterID}
                setRecordID={setRecordID}
              />
            }
          >
            <Button type="primary">
              {`${t('supervisions.show.buttons.send_contracts')} `}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Dropdown
            overlay={
              <InformationMenu
                supervision={supervision}
                setRecipients={setRecipients}
                setIsEmailModalOpen={setIsEmailModalOpen}
                setTemplateType={setTemplateType}
                setDocumentType={setDocumentType}
                setFilterID={setFilterID}
                setRecordID={setRecordID}
              />
            }
          >
            <Button type="primary">
              {`${t('supervisions.show.buttons.send_informations')} `}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Button
            type="primary"
            onClick={() =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                'SUPERVISION_SUMMARY',
                id,
                undefined,
                t
              )
            }
          >
            <UploadOutlined />
            {`${t('supervisions.show.buttons.generate_synthesis')} `}
          </Button>
          <Button
            type="primary"
            style={{ backgroundColor: 'red' }}
            onClick={() => setInterruptSupervisionModalVisible(true)}
          >
            <CloseOutlined />
            {`${t('supervisions.show.buttons.interrupt_supervision')} `}
          </Button>
        </>
      );
    case 'FINISHED':
      return (
        <>
          <Button
            type="primary"
            style={{ backgroundColor: 'red' }}
            onClick={() => {
              const balanceInvoice = customerInvoices.find(
                (invoice) => invoice.type === 'BALANCE'
              );
              setRecipients([
                {
                  ...supervision.main_informations.owner,
                  user_collection: 'owners',
                  customer_type: 'OWNER'
                }
              ]);
              setTemplateType('UNPAID_INVOICE_REMINDER');
              setIsEmailModalOpen(true);
              setDocumentType('OWNER-INVOICE');
              setRecordID(balanceInvoice?._id);
            }}
          >
            {`${t('supervisions.show.buttons.call_again_invoice')} `}
          </Button>
          <Dropdown
            overlay={
              <ContractMenu
                supervision={supervision}
                setRecipients={setRecipients}
                setIsEmailModalOpen={setIsEmailModalOpen}
                setTemplateType={setTemplateType}
                setDocumentType={setDocumentType}
                setFilterID={setFilterID}
                setRecordID={setRecordID}
              />
            }
          >
            <Button type="primary">
              {`${t('supervisions.show.buttons.send_contracts')} `}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Link
            to={{
              pathname: `${routes.SUPERVISIONS}/create`,
              search: `?id=${supervision.owner}`
            }}
          >
            <Button type="primary">
              <PlusOutlined />
              {`${t('supervisions.show.buttons.duplicate_supervision')} `}
            </Button>
          </Link>
          <Dropdown
            overlay={
              <SatisfactionMenu
                supervision={supervision}
                setRecipients={setRecipients}
                setIsEmailModalOpen={setIsEmailModalOpen}
                setTemplateType={setTemplateType}
                setDocumentType={setDocumentType}
                setFilterID={setFilterID}
                setRecordID={setRecordID}
              />
            }
          >
            <Button type="primary">
              {`${t('supervisions.show.buttons.satisfaction')} `}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Button
            type="primary"
            onClick={() =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                'SUPERVISION_SUMMARY',
                id,
                undefined,
                t
              )
            }
          >
            <UploadOutlined />
            {`${t('supervisions.show.buttons.generate_synthesis')} `}
          </Button>
          <Popconfirm
            title={t('datatable.column.action.archive.title')}
            okText={t('datatable.column.action.archive.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.archive.cancel')}
            onConfirm={() =>
              deleteSupervision({
                dispatchAPI,
                id,
                setRefreshData,
                refreshData,
                message
              })
            }
            icon={<WarningOutlined />}
          >
            <Button type="link" danger>
              {`${t('buttons.archive')} `}
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </>
      );
    case 'INTERRUPT':
      return (
        <>
          <Button type="primary">
            <PlusOutlined />
            {`${t('supervisions.show.buttons.duplicate_supervision')} `}
          </Button>
          <Button
            type="primary"
            onClick={() =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                'SUPERVISION_SUMMARY',
                id,
                undefined,
                t
              )
            }
          >
            <UploadOutlined />
            {`${t('supervisions.show.buttons.generate_synthesis')} `}
          </Button>
          <Popconfirm
            title={t('datatable.column.action.archive.title')}
            okText={t('datatable.column.action.archive.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.archive.cancel')}
            onConfirm={() =>
              deleteSupervision({
                dispatchAPI,
                id,
                setRefreshData,
                refreshData,
                message
              })
            }
            icon={<WarningOutlined />}
          >
            <Button type="link" danger>
              {`${t('buttons.archive')} `}
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </>
      );
    case 'CANCEL':
      return (
        <>
          <Link
            to={{
              pathname: `${routes.SUPERVISIONS}/create`,
              search: `?id=${supervision.owner}`
            }}
          >
            <Button type="primary">
              <PlusOutlined />
              {`${t('supervisions.show.buttons.duplicate_supervision')} `}
            </Button>
          </Link>
          <Button
            type="primary"
            onClick={() =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                'SUPERVISION_SUMMARY',
                id,
                undefined,
                t
              )
            }
          >
            <UploadOutlined />
            {`${t('supervisions.show.buttons.generate_synthesis')} `}
          </Button>
          <Popconfirm
            title={t('datatable.column.action.archive.title')}
            okText={t('datatable.column.action.archive.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.archive.cancel')}
            onConfirm={() =>
              deleteSupervision({
                dispatchAPI,
                id,
                setRefreshData,
                refreshData,
                message
              })
            }
            icon={<WarningOutlined />}
          >
            <Button type="link" danger>
              {`${t('buttons.archive')} `}
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </>
      );
    default:
      return null;
  }
};
