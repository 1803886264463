import { editStyleManagerOptions } from './editStyleManagerOptions';

/**
 * Adds padding to the body of the editor's canvas and calls a function to modify the style manager options once the editor is loaded.
 *
 * @param {Object} editor - The editor object which provides methods to interact with the editor.
 *
 * @returns {void}
 */
export const createBodyPadding = (editor) => {
  editor.on('load', () => {
    const wrapper = editor.Canvas.getDocument().body;
    wrapper.style.padding = '20px';
    editStyleManagerOptions(editor);
  });
};
