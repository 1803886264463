import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Generates an array of objects containing formatted address and dwelling info for a pet sitting location.
 *
 * @function
 * @param {Object} data - The data object containing information about the pet sitting address and dwelling characteristics.
 * @param {Object} data.address - The address object with details about the pet sitting location's address.
 * @param {string} data.address.number - The house number.
 * @param {string} data.address.street - The street name.
 * @param {string} data.address.city - The city name.
 * @param {string} data.address.postal_code - The postal code.
 * @param {string} data.address.state - The state or department name.
 * @param {string} data.address.country - The country name.
 * @param {string} data.type_of_dwelling - The type of dwelling.
 * @param {number} data.living_space - The amount of living space (in an appropriate unit).
 * @param {number} data.available_pieces_number - The number of available pieces or rooms.
 * @param {Function} t - The translation function to be used for translating living space label.
 *
 * @returns {Array<Object>} - Returns an array of objects with `label`, `span`, and `content` properties, ready to be used in a form.
 *
 */
export const generalPetsittingAddressInfo = (data) => {
  const { t } = useTranslation();

  const { address, type_of_dwelling, living_space, available_pieces_number } =
    data || {};
  const { number, street, city, postal_code, state, country } = address || {};

  return [
    {
      label: 'petsittingaddress.form.address.street',
      span: 1,
      content: `${number || ''} ${street || ''}` || '-'
    },
    {
      label: 'petsittingaddress.form.address.postal_code',
      span: 1,
      content: postal_code || '-'
    },
    {
      label: 'petsittingaddress.form.address.city',
      span: 1,
      content: city || '-'
    },
    {
      label: 'petsittingaddress.form.department',
      span: 1,
      content: state || '-'
    },
    {
      label: 'petsittingaddress.form.address.country',
      span: 1,
      content: country || '-'
    },
    {
      label: 'petsittingaddress.form.type_of_dwelling',
      span: 1,
      content:
        (type_of_dwelling && t(`petsittingaddress.tags.${type_of_dwelling}`)) ||
        '-'
    },
    {
      label: 'petsittingaddress.form.living_space',
      span: 1,
      content:
        (living_space &&
          t('petsittingaddress.form.living_space_label', {
            number: living_space
          })) ||
        '-'
    },
    {
      label: 'petsittingaddress.form.available_pieces_number',
      span: 1,
      content: (available_pieces_number && available_pieces_number) || '-'
    }
  ];
};

/**
 * Generates an array of objects containing detailed address and related info for a pet sitting location.
 *
 * @function
 * @param {Object} data - The data object containing comprehensive information about the pet sitting address and surrounding characteristics.
 * @param {Object} data.address - The address object containing details about the pet sitting location's address.
 * @param {string} data.address.number - The house number.
 * @param {string} data.address.street - The street name.
 * @param {string} data.address.city - The city name.
 * @param {string} data.address.postal_code - The postal code.
 * @param {string} data.address.state - The state or department name.
 * @param {string} data.address.country - The country name.
 * @param {string} data.type_of_dwelling - The type of dwelling.
 * @param {Object} data.insurance_company - Information regarding the insurance company associated with the pet sitting location.
 * @param {string} data.insurance_company.consulting_room_name - The name of the consulting room of the insurance company.
 * @param {string} data.insurance_company.address - The address of the insurance company.
 * @param {Object} data.insurance_company.phone_number - The phone number details of the insurance company.
 * @param {string} data.insurance_company.phone_number.number - The full phone number of the insurance company.
 * @param {string} data.police_number - The police number associated with the location.
 * @param {Object} data.criterion - Additional criterion data (not used in the current function scope, but included for future extensions).
 * @param {number} data.living_space - The living space details (not used in the current function scope, but included for future extensions).
 * @param {number} data.available_pieces_number - Details of available pieces or rooms (not used in the current function scope, but included for future extensions).
 * @param {boolean} data.is_air_conditioned_house - Information if the house has air conditioning (not used in the current function scope, but included for future extensions).
 * @param {boolean} data.small_business_nearby - Information if there are small businesses nearby (not used in the current function scope, but included for future extensions).
 * @param {boolean} data.super_market_nearby - Information if there is a supermarket nearby (not used in the current function scope, but included for future extensions).
 * @param {Array<string>} data.means_of_transports_nearby - Details of nearby means of transports (not used in the current function scope, but included for future extensions).
 * @param {string} data.station_distance - Details on the distance to the nearest station (not used in the current function scope, but included for future extensions).
 * @param {string} data.nearby_city - Details on the nearby city (not used in the current function scope, but included for future extensions).
 * @param {string} data.neighbor_distance - Details on the distance to the nearest neighbor (not used in the current function scope, but included for future extensions).
 * @param {Array<string>} data.nearby_site - Details on nearby sites (not used in the current function scope, but included for future extensions).
 * @param {boolean} data.code_lock - Information on if there is a code lock present (not used in the current function scope, but included for future extensions).
 * @param {number} data.floor_number - Details on the floor number of the dwelling (not used in the current function scope, but included for future extensions).
 * @param {boolean} data.elevator - Information if the dwelling has an elevator (not used in the current function scope, but included for future extensions).
 * @param {string} data.type_of_storage_for_pensioner - Details on the type of storage available for pensioners (not used in the current function scope, but included for future extensions).
 * @param {string} title - The title for the pet sitting address details (not used in the current function scope, but included for future extensions).
 * @param {Function} t - The translation function to be used for translating living space label (not used in the current function scope, but included for future extensions).
 *
 * @returns {Array<Object>} - Returns an array of objects with `label`, `span`, and `content` properties, ready to be used in a form.
 *
 * @example
 */
export const detailPetsittingAddressInfo = (data, title, t) => {
  const { user } = useAuthContext();

  const {
    address,
    type_of_dwelling,
    insurance_company,
    police_number,
    type_of_environment,
    living_space,
    available_pieces_number,
    is_air_conditioned_house,
    small_business_nearby,
    super_market_nearby,
    means_of_transports_nearby,
    station_distance,
    nearby_city,
    neighbor_distance,
    nearby_site,
    code_lock,
    floor_number,
    elevator,
    type_of_storage_for_pensioner
  } = data;

  const details = [
    {
      label: 'petsittingaddress.form.type_of_dwelling',
      span: 1,
      content:
        (type_of_dwelling && t(`petsittingaddress.tags.${type_of_dwelling}`)) ||
        '-'
    },
    {
      label: 'petsittingaddress.form.address.street',
      span: 2,
      content:
        (address && `${address?.number || ''} ${address?.street || ''}`) || '-'
    },
    {
      label: 'petsittingaddress.form.address.city',
      span: 3,
      content: (address && address.city) || '-'
    },
    {
      label: 'petsittingaddress.form.address.postal_code',
      span: 1,
      content: (address && address.postal_code) || '-'
    },
    {
      label: 'petsittingaddress.form.department',
      span: 2,
      content: (address && address.state) || '-'
    },
    {
      label: 'petsittingaddress.form.address.country',
      span: 3,
      content: (address && address.country) || '-'
    },
    ...(user?.role === 'admins:ADMIN'
      ? [
          {
            label:
              'petsittingaddress.form.insurance_company.consulting_room_name',
            span: 1,
            content:
              (insurance_company && insurance_company.consulting_room_name) ||
              '-'
          },
          {
            label: 'petsittingaddress.form.insurance_company.address',
            span: 2,
            content: (insurance_company && insurance_company.address) || '-'
          },
          {
            label: 'petsittingaddress.form.insurance_company.full_phone_number',
            span: 1,
            content:
              (insurance_company &&
                insurance_company?.phone_number &&
                insurance_company.phone_number.number) ||
              '-'
          }
        ]
      : []),
    {
      label: 'petsittingaddress.form.police_number',
      span: 2,
      content: (police_number && police_number) || '-'
    }
  ];

  const generalInformation = [
    {
      label: 'petsittingaddress.form.criterion',
      span: 1,
      content:
        type_of_environment?.length &&
        type_of_environment
          .map((env) => t(`petsittingaddress.form.environment.${env}`))
          .join(', ')
    },
    {
      label: 'petsittingaddress.form.living_space',
      span: 2,
      content:
        (living_space &&
          t('petsittingaddress.form.living_space_label', {
            number: living_space
          })) ||
        '-'
    },
    {
      label: 'petsittingaddress.form.available_pieces_number',
      span: 1,
      content: (available_pieces_number && available_pieces_number) || '-'
    },
    {
      hidden: ['HOUSE'].includes(type_of_dwelling),
      label: 'petsittingaddress.form.code_lock',
      span: 1,
      content: (code_lock && code_lock) || '-'
    },
    {
      hidden: ['HOUSE'].includes(type_of_dwelling),
      label: 'petsittingaddress.form.floor_number',
      span: 2,
      content: (floor_number && floor_number) || '-'
    },
    {
      hidden: ['HOUSE'].includes(type_of_dwelling),
      label: 'petsittingaddress.form.elevator',
      span: 3,
      content: (elevator ? t('owners.show.yes') : t('owners.show.no')) || '-'
    },
    {
      label: 'petsittingaddress.form.is_air_conditioned_house',
      span: 2,
      content: is_air_conditioned_house
        ? t('owners.show.yes')
        : t('owners.show.no')
    },
    {
      label: 'petsittingaddress.form.small_business_nearby.label',
      span: 1,
      content:
        (small_business_nearby && small_business_nearby.name_and_distance) ||
        '-'
    },
    {
      label: 'petsittingaddress.form.super_market_nearby.label',
      span: 2,
      content:
        (super_market_nearby && super_market_nearby.name_and_distance) || '-'
    },
    {
      label: 'petsittingaddress.form.means_of_transports_nearby',
      span: 1,
      content: (means_of_transports_nearby && means_of_transports_nearby) || '-'
    },
    {
      label: 'petsittingaddress.form.station_distance',
      span: 2,
      content: station_distance || '-'
    },
    {
      label: 'petsittingaddress.form.nearby_city',
      span: 1,
      content: (nearby_city && nearby_city) || '-'
    },
    {
      label: 'petsittingaddress.form.neighbor_distance',
      span: 2,
      content: neighbor_distance || '-'
    },
    {
      label: 'petsittingaddress.form.nearby_site',
      span: 1,
      content: (nearby_site && nearby_site) || '-'
    }
  ];

  const roomAndLaundry = [
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.cleaner_during_supervision',
      span: 1,
      content: type_of_storage_for_pensioner?.room_and_laundry
        ?.cleaner_during_supervision
        ? t('owners.show.yes')
        : t('owners.show.no')
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.room_in_ground_floor',
      span: 2,
      content: type_of_storage_for_pensioner?.room_and_laundry
        ?.room_in_ground_floor
        ? t('owners.show.yes')
        : t('owners.show.no')
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.air_conditioned_room',
      span: 1,
      content: type_of_storage_for_pensioner?.room_and_laundry
        ?.air_conditioned_room
        ? t('owners.show.yes')
        : t('owners.show.no')
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.rooms_number_for_pensioner',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.room_and_laundry
          ?.rooms_number_for_pensioner &&
          type_of_storage_for_pensioner?.room_and_laundry
            .rooms_number_for_pensioner) ||
        '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.bed',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.room_and_laundry?.bed &&
          type_of_storage_for_pensioner?.room_and_laundry.bed) ||
        '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.tidying_type',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.room_and_laundry?.tidying_type &&
          type_of_storage_for_pensioner?.room_and_laundry.tidying_type) ||
        '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.sheet',
      span: 1,
      content: type_of_storage_for_pensioner?.room_and_laundry?.sheet
        ? t('owners.show.yes')
        : t('owners.show.no')
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.household_linen',
      span: 2,
      content: type_of_storage_for_pensioner?.room_and_laundry?.household_linen
        ? t('owners.show.yes')
        : t('owners.show.no')
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.washer',
      span: 1,
      content: type_of_storage_for_pensioner?.room_and_laundry?.washer
        ? t('owners.show.yes')
        : t('owners.show.no')
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.dryer',
      span: 2,
      content: type_of_storage_for_pensioner?.room_and_laundry?.dryer
        ? t('owners.show.yes')
        : t('owners.show.no')
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.iron',
      span: 1,
      content: type_of_storage_for_pensioner?.room_and_laundry?.iron
        ? t('owners.show.yes')
        : t('owners.show.no')
    }
  ];

  const bathroomInformation = [
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.bathroom.shower',
      span: 1,
      content: type_of_storage_for_pensioner?.bathroom?.shower
        ? t('owners.show.yes')
        : t('owners.show.no')
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.bathroom.bathroom_towel',
      span: 2,
      content: type_of_storage_for_pensioner?.bathroom?.bathroom_towel
        ? t('owners.show.yes')
        : t('owners.show.no')
    }
  ];

  const kitchenInformation = [
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.hotplate',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.kitchen?.hotplate
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.oven',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.kitchen?.oven
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.microwave',
      span: 3,
      content:
        (type_of_storage_for_pensioner?.kitchen?.microwave
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.electric_coffee_maker',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.kitchen?.electric_coffee_maker
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.toaster',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.kitchen?.toaster
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.dishwasher',
      span: 3,
      content:
        (type_of_storage_for_pensioner?.kitchen?.dishwasher
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.freezer',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.kitchen?.freezer
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    }
  ];

  const outsideInformation = [
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.garden',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.outside?.garden
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.closed',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.outside?.closed
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.surface',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.outside?.surface &&
          t(
            'petsittingaddress.form.type_of_storage_for_pensioner.outside.surface_label',
            {
              number: type_of_storage_for_pensioner?.outside.surface
            }
          )) ||
        '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.gardener_during_supervision',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.outside?.gardener_during_supervision
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.automatic_spraying',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.outside?.automatic_spraying
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.external_plant',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.outside?.external_plant
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.external_time_to_give',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.outside?.external_time_to_give &&
          type_of_storage_for_pensioner?.outside.external_time_to_give) ||
        '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.interior_plant',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.outside?.interior_plant
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.interior_time_to_give',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.outside?.interior_time_to_give &&
          type_of_storage_for_pensioner?.outside.interior_time_to_give) ||
        '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.swimming_pool',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.outside?.swimming_pool
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.jacuzzi',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.outside?.jacuzzi
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.garden_furniture',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.outside?.garden_furniture
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.barbecue',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.outside?.barbecue
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.balcony',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.outside?.balcony
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.patio',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.outside?.patio
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    }
  ];

  const formatParkingData = () => {
    if (type_of_storage_for_pensioner?.parking_and_installation?.parking) {
      const selectedOptions = Object.keys(
        type_of_storage_for_pensioner?.parking_and_installation?.parking
      ).filter(
        (key) =>
          type_of_storage_for_pensioner?.parking_and_installation?.parking[key]
      );

      const translatedOptions = selectedOptions.map((key) =>
        t(
          `petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.parking.${key}`
        )
      );

      return translatedOptions.join(', ');
    }
    return '-';
  };

  const parkingAndInstallation = [
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.parking.label',
      span: 1,
      content: formatParkingData()
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.installation.alarm',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.parking_and_installation?.installation
          ?.alarm
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.installation.telesurveillance',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.parking_and_installation?.installation
          ?.telesurveillance
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    }
  ];

  const equipmentInformation = [
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.equipment.television',
      span: 1,
      content:
        (type_of_storage_for_pensioner?.equipment?.television
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.equipment.cable',
      span: 2,
      content:
        (type_of_storage_for_pensioner?.equipment?.cable
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    },
    {
      label:
        'petsittingaddress.form.type_of_storage_for_pensioner.equipment.internet',
      span: 3,
      content:
        (type_of_storage_for_pensioner?.equipment?.internet
          ? t('owners.show.yes')
          : t('owners.show.no')) || '-'
    }
  ];

  const addressData = {
    details,
    general_informations: generalInformation,
    room_and_laundry: roomAndLaundry,
    bathroom: bathroomInformation,
    kitchen: kitchenInformation,
    outside: outsideInformation,
    parking_and_installation: parkingAndInstallation,
    equipment: equipmentInformation
  };

  return addressData[title];
};
