/**
 * Modifies the style manager settings in the editor to manage the dimension sector properties of header and footer components.
 * It removes the dimension properties (like width, height, max-width, min-height) from the style manager when a header or footer
 * component is selected and adds them back when a different component is selected.
 *
 * @param {object} editor - The editor instance where the style manager resides.
 *
 * @listens component:selected - Listens to the component:selected event on the editor to identify when a component has been selected.
 *
 * @example
 * manageDimensionSector(editorInstance); // Modifies the dimension sector properties based on the selected component in the editorInstance
 *
 * @returns {void}
 */
export const manageDimensionSector = (editor) => {
  let dimensionsPropertiesRemoved = false;
  const styleManager = editor.StyleManager;

  const dimensionSector = styleManager.getSector('dimension');
  const dimensionSectorProperties = dimensionSector.getProperties();
  const propertiesNames = ['width', 'height', 'max-width', 'min-height'];

  const dimensionSectorPropertiesFiltered = dimensionSectorProperties.filter(
    (property) => propertiesNames.includes(property.id)
  );

  editor.on('component:selected', (component) => {
    if (
      component.get('tagName') === 'header' ||
      component.get('tagName') === 'footer'
    ) {
      for (let i = 0; i < propertiesNames.length; i += 1) {
        styleManager.removeProperty('dimension', propertiesNames[i]);
      }
      dimensionsPropertiesRemoved = true;
    } else if (dimensionsPropertiesRemoved) {
      for (let i = 0; i < dimensionSectorPropertiesFiltered.length; i += 1) {
        styleManager.addProperty(
          'dimension',
          dimensionSectorPropertiesFiltered[i]
        );
      }
      dimensionsPropertiesRemoved = false;
    }
  });
};
