import { pickTagColor } from '../../utils/pickTagColor';
import { processString } from '../../utils/processString';

/**
 * Registers a custom component in the GrapesJS editor which includes a placeholder selector populated
 * with dynamically generated options and a color legend. Also manages the update of a list of placeholders.
 *
 * @function
 * @param {object} editor - The GrapesJS editor instance.
 * @param {string} selectOptions - HTML string representing options to be included in the select element.
 * @param {function} setPopulateList - Function to update the populate list state.
 * @param {function} t - Function to translate the predefined strings.
 *
 * @returns {void}
 */
export const registerPlaceholderComponent = (
  editor,
  selectOptions,
  setPopulateList,
  t
) => {
  const updatePopulateList = (populateItem) => {
    const formattedString = processString(populateItem);
    if (formattedString) {
      setPopulateList((prevPopulateList) => {
        const isPartialMatch = prevPopulateList.some((item) =>
          item.includes(formattedString)
        );
        if (isPartialMatch) {
          return prevPopulateList;
        }

        const removedObsoletePopulateItems = (array, string) =>
          array.filter((item) => !string.includes(item));

        const updatedPrevPopulateList = removedObsoletePopulateItems(
          prevPopulateList,
          formattedString
        );

        return [...updatedPrevPopulateList, formattedString];
      });
    }
    return false;
  };

  const colorLegend = () => {
    const types = ['String', 'Number', 'Date', 'Boolean', 'Others'];
    let legend = `<option disabled style="font-weight: 700;color:"black";">${t(
      'template-documents.form.color-legend'
    )}</option>`;

    for (let i = 0; i < types.length; i += 1) {
      const optionColor = pickTagColor(types[i]);
      legend += `<option disabled style="color: ${optionColor}">${t(
        `template-documents.types.${types[i]}`
      )}
      </option>`;
    }
    legend += `<option disabled style="font-weight: 700; color:"black"">${t(
      'template-documents.form.placeholders'
    )}</option>`;
    return legend;
  };

  editor.RichTextEditor.add('custom-vars', {
    icon: `<select class="placeholder-select">${colorLegend()}${selectOptions}</select>`,
    event: 'change',
    result: (rte, action) => {
      const placeholderItem = action.btn.firstChild.value;
      updatePopulateList(placeholderItem);
      const span = document.createElement('span');
      span.textContent = placeholderItem;
      span.style.display = 'inline-block';
      span.style.overflowWrap = 'anywhere';
      return rte.insertHTML(span.outerHTML);
    },
    update: (rte, action) => {
      let updateAction = action.btn.firstChild.value;
      updateAction = '';
      return updateAction;
    }
  });
};
