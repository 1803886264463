import { Row, Typography, Flex } from 'antd';
import PropTypes from 'prop-types';
import { ReviewsCards } from './ReviewsCards';

/**
 * Renders a list of reviews given by a user.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.t - The translation function.
 * @param {Array} props.reviews - The array of reviews.
 * @param {Object} props.user - The user object.
 * @param {Function} props.setShowReviewDrawer - The function to show the review drawer.
 * @param {Function} props.setOpen - The function to set the open state.
 * @param {string} props.title - The title of the reviews.
 * @param {Function} props.setEditReviewDrawer - The function to set the edit review drawer.
 * @returns {JSX.Element} The rendered ListReviews component.
 */
export const ListReviews = ({
  t,
  reviews,
  user,
  setShowReviewDrawer,
  setOpen,
  title,
  setEditReviewDrawer
}) => (
  <Flex vertical>
    <Typography.Title level={5} className="underlined">
      {t('owners.users_shows.reviews.given_reviews')}
    </Typography.Title>
    <Row gutter={[16, 16]}>
      {(reviews || []).map(
        (review) =>
          review.author.id.user === user._id && (
            <ReviewsCards
              t={t}
              title={title}
              review={review}
              setShowReviewDrawer={setShowReviewDrawer}
              setOpen={setOpen}
              setEditReviewDrawer={setEditReviewDrawer}
            />
          )
      )}
    </Row>
  </Flex>
);
ListReviews.propTypes = {
  t: PropTypes.func.isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      author: PropTypes.string,
      content: PropTypes.string
    })
  ).isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string,
    role: PropTypes.string
  }).isRequired,
  setShowReviewDrawer: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setEditReviewDrawer: PropTypes.func.isRequired
};
