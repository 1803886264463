import React from 'react';
import { Menu, Typography, Col, Row } from 'antd';
import { MailOutlined, DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { generateContractDocuments } from './generateContractDocuments';
import { generateFilterAndRecordID } from './generateFilterAndRecordID';

const { Text } = Typography;

export const ContractMenu = ({
  supervision,
  setRecipients,
  setIsEmailModalOpen,
  setTemplateType,
  setDocumentType,
  setFilterID,
  setRecordID
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const options = [];

  options.push({
    ...supervision.main_informations.owner,
    type: 'owner',
    customer_type: 'OWNER',
    collection: 'Supervision',
    user_collection: 'owners'
  });
  supervision.pensioners.forEach((pensioner, index) => {
    options.push({
      ...pensioner,
      type: 'pensioner',
      collection: 'Supervision',
      use_case: 'PENSIONER_SUPERVISION_CONTRACT_SEND',
      customer_type: 'PENSIONER',
      user_collection: 'pensioners',
      number: index + 1
    });
  });
  return (
    <Menu>
      {options.map((option) => {
        const documentsType =
          option.type === 'owner'
            ? [
                `${option.customer_type}_CONTRACT`,
                'OWNER-INVOICE',
                'OWNER-INVOICE'
              ]
            : ['PENSIONER_CONTRACT'];
        const documentType =
          option.type === 'owner' ? 'OWNER_CONTRACT' : 'PENSIONER_CONTRACT';
        return (
          <Menu.Item
            key={option._id || option.type}
            onClick={() => {
              setRecipients([option]);
              setIsEmailModalOpen(true);
              setTemplateType(`CONTRACT_${option.customer_type}`);
              setDocumentType(documentsType);
              generateFilterAndRecordID({
                dispatchAPI,
                documentType,
                id,
                option,
                supervision,
                setFilterID,
                setRecordID,
                setDocumentType
              });
            }}
          >
            <Row gutter={10}>
              <Col>
                <MailOutlined />
              </Col>
              <Col>
                <Text>
                  {t(`supervisions.show.buttons.${option.type}`, {
                    number: option.number
                  })}
                </Text>
              </Col>
            </Row>
          </Menu.Item>
        );
      })}
      {options.map((option) => {
        const documentType =
          option.type === 'owner' ? 'OWNER_CONTRACT' : 'PENSIONER_CONTRACT';
        return (
          <Menu.Item
            key={option._id ? `dl${option._id}` : `dl${option.type}`}
            onClick={() =>
              generateContractDocuments({
                dispatchAPI,
                message,
                documentType,
                id,
                option,
                t,
                supervision
              })
            }
          >
            <Row gutter={10}>
              <Col>
                <DownloadOutlined />
              </Col>
              <Col>
                <Text>
                  {t(`supervisions.show.buttons.${option.type}`, {
                    number: option.number
                  })}
                </Text>
              </Col>
            </Row>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

ContractMenu.propTypes = {
  supervision: PropTypes.shape({
    main_informations: PropTypes.shape({
      owner: PropTypes.shape({
        _id: PropTypes.string
      })
    }),
    pensioners: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string
      })
    )
  }),
  setRecipients: PropTypes.func,
  setIsEmailModalOpen: PropTypes.func,
  setTemplateType: PropTypes.func,
  setDocumentType: PropTypes.func,
  setFilterID: PropTypes.func,
  setRecordID: PropTypes.func
};

ContractMenu.defaultProps = {
  supervision: {},
  setRecipients: () => {},
  setIsEmailModalOpen: () => {},
  setTemplateType: () => {},
  setDocumentType: () => {},
  setFilterID: () => {},
  setRecordID: () => {}
};
