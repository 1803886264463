import { useTranslation } from 'react-i18next';
import { CheckSquareOutlined, CloseOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import BeachIcon from '../../../assets/images/noto_beach-with-umbrella.svg';
import MountainIcon from '../../../assets/images/noto_mountain.svg';
import CityIcon from '../../../assets/images/noto_cityscape.svg';
import CountrysideIcon from '../../../assets/images/noto_deciduous-tree.svg';
import IsolatedIcon from '../../../assets/images/noto_house-with-garden.svg';
import CalmIcon from '../../../assets/images/noto_sleeping-face.svg';

const { Text } = Typography;

export const useListContent = (data) => {
  const { t } = useTranslation();
  const {
    type_of_dwelling,
    supervision_phone,
    insurance_company,
    police_number,
    type_of_environment,
    living_space,
    available_pieces_number,
    is_air_conditioned_house,
    small_business_nearby: { name_and_distance: nameAndDistanceSmall } = {},
    super_market_nearby: { name_and_distance: nameAndDistanceSuper } = {},
    means_of_transports_nearby,
    station_distance,
    nearby_city,
    neighbor_distance,
    nearby_site,
    type_of_storage_for_pensioner
  } = data || {};
  const {
    room_and_laundry,
    bathroom: { independant_bathroom, shower, bathroom_towel } = {},
    kitchen,
    outside,
    parking_and_installation: {
      parking,
      installation: { alarm, telesurveillance } = {}
    } = {},
    equipment
  } = type_of_storage_for_pensioner || {};

  const { television, cable, internet } = equipment || {};

  const {
    hotplate,
    oven,
    microwave,
    electric_coffee_maker,
    toaster,
    dishwasher,
    freezer
  } = kitchen || {};

  const {
    cleaner_during_supervision,
    room_in_ground_floor,
    air_conditioned_room,
    rooms_number_for_pensioner,
    bed,
    tidying_type,
    sheet,
    household_linen,
    washer,
    dryer,
    iron
  } = room_and_laundry || {};

  const {
    garden,
    closed,
    surface,
    gardener_during_supervision,
    automatic_spraying,
    external_plant,
    swimming_pool,
    jacuzzi,
    garden_furniture,
    barbecue,
    balcony,
    patio
  } = outside || {};

  const { number: supervision_phone_number } = supervision_phone || {};

  const {
    consulting_room_name,
    address: insurance_address,
    phone_number: { number: insurance_phone_number } = {}
  } = insurance_company || {};

  const displayChecked = (value) =>
    value ? <CheckSquareOutlined /> : <CloseOutlined />;

  const mainInfos = [
    {
      title: t('petsittingaddress.form.type_of_dwelling'),
      content: type_of_dwelling
        ? t(`petsittingaddress.tags.${type_of_dwelling}`)
        : '-'
    },
    {
      title: t('petsittingaddress.form.supervision_phone.number'),
      content: supervision_phone_number || '-'
    },
    {
      title: t('petsittingaddress.form.insurance_company.consulting_room_name'),
      content: consulting_room_name || '-'
    },
    {
      title: t('petsittingaddress.form.insurance_company.address'),
      content: insurance_address || '-'
    },
    {
      title: t('petsittingaddress.form.insurance_company.full_phone_number'),
      content: insurance_phone_number || '-'
    },
    {
      title: t('petsittingaddress.form.police_number'),
      content: police_number || '-'
    }
  ];

  const icons = {
    beach: (
      <Space.Compact direction="vertical">
        <img
          src={BeachIcon}
          alt={t('petsittingaddress.form.environment.beach')}
        />
        <Text>{t('petsittingaddress.form.environment.beach')}</Text>
      </Space.Compact>
    ),
    mountain: (
      <Space.Compact direction="vertical">
        <img
          src={MountainIcon}
          alt={t('petsittingaddress.form.environment.mountain')}
        />
        <Text>{t('petsittingaddress.form.environment.mountain')}</Text>
      </Space.Compact>
    ),
    city: (
      <Space.Compact direction="vertical">
        <img
          src={CityIcon}
          alt={t('petsittingaddress.form.environment.city')}
        />
        <Text>{t('petsittingaddress.form.environment.city')}</Text>
      </Space.Compact>
    ),
    campaign: (
      <Space.Compact direction="vertical">
        <img
          src={CountrysideIcon}
          alt={t('petsittingaddress.form.environment.campaign')}
        />
        <Text>{t('petsittingaddress.form.environment.campaign')}</Text>
      </Space.Compact>
    ),
    isolated: (
      <Space.Compact direction="vertical">
        <img
          src={IsolatedIcon}
          alt={t('petsittingaddress.form.environment.isolated')}
        />
        <Text>{t('petsittingaddress.form.environment.isolated')}</Text>
      </Space.Compact>
    ),
    calm: (
      <Space.Compact direction="vertical">
        <img
          src={CalmIcon}
          alt={t('petsittingaddress.form.environment.calm')}
        />
        <Text>{t('petsittingaddress.form.environment.calm')}</Text>
      </Space.Compact>
    )
  };

  const generalInfos = [
    {
      title: t('petsittingaddress.form.situation'),
      content: type_of_environment?.map((type) => icons?.[type])
    },
    {
      title: t('petsittingaddress.form.living_space'),
      content: living_space || '-'
    },
    {
      title: t('petsittingaddress.form.available_pieces_number'),
      content: available_pieces_number || '-'
    },
    {
      title: t('petsittingaddress.form.is_air_conditioned_house'),
      content: displayChecked(is_air_conditioned_house)
    },
    {
      title: t('petsittingaddress.form.small_business_nearby.label'),
      content: nameAndDistanceSmall || '-'
    },
    {
      title: t('petsittingaddress.form.super_market_nearby.label'),
      content: nameAndDistanceSuper || '-'
    },
    {
      title: t('petsittingaddress.form.means_of_transports_nearby'),
      content: means_of_transports_nearby || '-'
    },
    {
      title: t('petsittingaddress.form.station_distance'),
      content: station_distance || '-'
    },
    {
      title: t('petsittingaddress.form.nearby_city'),
      content: nearby_city || '-'
    },
    {
      title: t('petsittingaddress.form.neighbor_distance'),
      content: neighbor_distance ? `${neighbor_distance}` : '-'
    },
    {
      title: t('petsittingaddress.form.nearby_site'),
      content: nearby_site || '-'
    }
  ];

  const roomAndLaundryInfos = [
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.cleaner_during_supervision'
      ),
      content: displayChecked(cleaner_during_supervision)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.room_in_ground_floor'
      ),
      content: displayChecked(room_in_ground_floor)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.air_conditioned_room'
      ),
      content: displayChecked(air_conditioned_room)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.rooms_number_for_pensioner'
      ),
      content: rooms_number_for_pensioner || '-'
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.bed'
      ),
      content: bed || '-'
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.tidying_type'
      ),
      content: tidying_type || '-'
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.sheet'
      ),
      content: displayChecked(sheet)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.household_linen'
      ),
      content: displayChecked(household_linen)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.washer'
      ),
      content: displayChecked(washer)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.dryer'
      ),
      content: displayChecked(dryer)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.iron'
      ),
      content: displayChecked(iron)
    }
  ];

  const bathroomInfos = [
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.bathroom.independant_bathroom'
      ),
      content: displayChecked(independant_bathroom)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.bathroom.shower'
      ),
      content: displayChecked(shower)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.bathroom.bathroom_towel'
      ),
      content: displayChecked(bathroom_towel)
    }
  ];

  const kitchenInfos = [
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.hotplate'
      ),
      content: displayChecked(hotplate)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.oven'
      ),
      content: displayChecked(oven)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.microwave'
      ),
      content: displayChecked(microwave)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.electric_coffee_maker'
      ),
      content: displayChecked(electric_coffee_maker)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.toaster'
      ),
      content: displayChecked(toaster)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.dishwasher'
      ),
      content: displayChecked(dishwasher)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.freezer'
      ),
      content: displayChecked(freezer)
    }
  ];

  const outsideInfos = [
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.garden'
      ),
      content: displayChecked(garden)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.closed'
      ),
      content: displayChecked(closed)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.surface'
      ),
      content: surface || '-'
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.gardener_during_supervision'
      ),
      content: displayChecked(gardener_during_supervision)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.automatic_spraying'
      ),
      content: displayChecked(automatic_spraying)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.external_plant'
      ),
      content: displayChecked(external_plant)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.swimming_pool'
      ),
      content: displayChecked(swimming_pool)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.jacuzzi'
      ),
      content: displayChecked(jacuzzi)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.garden_furniture'
      ),
      content: displayChecked(garden_furniture)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.barbecue'
      ),
      content: displayChecked(barbecue)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.balcony'
      ),
      content: displayChecked(balcony)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.outside.patio'
      ),
      content: displayChecked(patio)
    }
  ];

  const parkingAndInstallationInfos = [
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.parking.title'
      ),
      content:
        Object.entries(parking || {})
          .filter(([, value]) => value === true)
          .map(([key]) =>
            t(
              `petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.parking.${key}`
            )
          )
          .join(', ') || '-'
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.installation.alarm'
      ),
      content: alarm || '-'
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.parking_and_installation.installation.telesurveillance'
      ),
      content: telesurveillance || '-'
    }
  ];

  const equipmentInfos = [
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.equipment.television'
      ),
      content: displayChecked(television)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.equipment.cable'
      ),
      content: displayChecked(cable)
    },
    {
      title: t(
        'petsittingaddress.form.type_of_storage_for_pensioner.equipment.internet'
      ),
      content: displayChecked(internet)
    }
  ];

  const additionalInfos = [
    generalInfos,
    roomAndLaundryInfos,
    bathroomInfos,
    kitchenInfos,
    outsideInfos,
    parkingAndInstallationInfos,
    equipmentInfos
  ];

  return { mainInfos, additionalInfos };
};
