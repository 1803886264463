import PropTypes from 'prop-types';
import { useState } from 'react';
import { Datatable } from '../../components';
import { useColumns } from './columns';

/**
 * `TemplateDocsDatatable` is a component that renders a datatable for displaying
 * template documents. It leverages the `Datatable` component and sets up specific
 * columns and queries based on the `useCaseParams` prop.
 *
 * @component
 * @param {Object} props - The properties that define the component.
 * @param {string} props.useCaseParams - A string parameter used to construct a query to fetch data for the datatable.
 * @param {function} props.navigate - A function for navigation.
 * @param {boolean} props.archiveFilter - A boolean flag to enable or disable archive filtering.
 *
 * @returns {React.Element} A `Datatable` component populated with columns and data based on the `useCaseParams`.
 *
 * @example
 *
 * return (
 *   <TemplateDocsDatatable useCaseParams="someUseCaseParam" navigate={navigateFunction} archiveFilter={true} />
 * );
 */
export const TemplateDocsDatatable = ({
  useCaseParams,
  navigate,
  archiveFilter
}) => {
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = useColumns(forceRefresh, setForceRefresh);

  return (
    <Datatable
      archiveFilter={archiveFilter}
      navigate={navigate}
      columns={columns}
      resourceName="template-documents"
      forceRefresh={forceRefresh}
      extraQuery={`configuration.use_case=${useCaseParams}&populate=author`}
    />
  );
};

TemplateDocsDatatable.propTypes = {
  useCaseParams: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  archiveFilter: PropTypes.bool.isRequired
};
