import { Button, Form, Flex, Input, Typography, Upload } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { useFields } from '../../owners/form/petsittingAddressForm/fields/fields';
import GeneralInformations from '../../owners/form/petsittingAddressForm/fields/generalInformationsFields';
import RoomAndLaundryInformations from '../../owners/form/petsittingAddressForm/fields/roomAndLaundryFields';
import BathroomInformations from '../../owners/form/petsittingAddressForm/fields/bathroomFields';
import KitchenInformations from '../../owners/form/petsittingAddressForm/fields/kitchenFields';
import OutsideInformations from '../../owners/form/petsittingAddressForm/fields/outsideFields';
import ParkingAndInstallationInformations from '../../owners/form/petsittingAddressForm/fields/parkingAndInstallationFields';
import EquipmentInformations from '../../owners/form/petsittingAddressForm/fields/equipmentFields';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { draggerProps } from '../../owners/form/utils/petsittingAddressDraggerProps';

const { Title } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;

/**
 * Form component for collecting supervision address information.
 *
 * @component
 * @param {object} props - The props that define this component.
 * @param {Function} props.onFinish - Function to handle form submission.
 * @param {Function} props.handlePrevious - Function to handle previous step.
 * @param {object} props.initialValues - Initial values for the form.
 * @param {Array} props.fileList - List of file objects.
 * @param {Function} props.setFileList - Function to set file list.
 * @param {Function} props.t - Function for translation.
 * @param {object} props.form - Ant Design form object.
 * @returns {JSX.Element}
 */

export const SupervisionAddressForm = ({
  t,
  onFinish,
  initialValues,
  handlePrevious,
  fileList,
  setFileList,
  form,
  formDataEnvironment,
  setFormDataEnvironment,
  country,
  setCountry
}) => {
  const generateFields = useGenerateFormItem();

  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [typeOfDwelling, setTypeOfDwelling] = useState('');
  const { formFields } = useFields({
    setTypeOfDwelling,
    form,
    country,
    setCountry
  });
  const { generalInformationsFields } = GeneralInformations({
    typeOfDwelling,
    t,
    formDataEnvironment,
    setFormDataEnvironment,
    form
  });
  const { roomAndLaundryInformationsFields } =
    RoomAndLaundryInformations(typeOfDwelling);
  const { bathroomInformationsFields } = BathroomInformations();
  const { kitchenInformationsFields } = KitchenInformations();
  const { outsideInformationsFields } = OutsideInformations();
  const { parkingAndInstallationInformationsFields } =
    ParkingAndInstallationInformations(typeOfDwelling);
  const { equipmentInformationsFields } = EquipmentInformations();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <>
      <Input.Group
        name={['petsitting_address']}
        className="supervision-address-form"
      >
        <Form.Item noStyle name={['petsitting_address']}>
          {formFields.map((fields) =>
            fields.map((field) => generateFields('petsittingaddress', field))
          )}
        </Form.Item>
        <Title level={4} className="underlined">
          {t('owners.form.general_infos')}
        </Title>
        {generalInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.room_and_laundry')}
        </Title>
        {roomAndLaundryInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.bathroom')}
        </Title>
        {bathroomInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.kitchen')}
        </Title>
        {kitchenInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.outside')}
        </Title>
        {outsideInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.parking')}
        </Title>
        {parkingAndInstallationInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Title level={4} className="underlined">
          {t('owners.form.equipment')}
        </Title>
        {equipmentInformationsFields.map((field) =>
          generateFields('petsittingaddress', field)
        )}
        <Form.Item
          label={t('owners.form.complementary-infos')}
          name={['petsittingaddress', 'complementary_infos']}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={t('owners.form.dwelling-photos')}
          name={['petsittingaddress', 'dwelling_photos']}
        >
          <Dragger
            {...draggerProps(t, dispatchAPI, message, fileList, setFileList)}
          >
            <p className="ant-upload-drag-icon">
              <CameraOutlined style={{ color: 'var(--textColor)' }} />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
        </Form.Item>
      </Input.Group>
      <Flex justify="flex-end" gap="small">
        <Button type="link" danger onClick={handlePrevious}>
          {t('buttons.previous')}
        </Button>
        <Button type="add" onClick={onFinish}>
          {t('buttons.next')}
        </Button>
      </Flex>
    </>
  );
};

SupervisionAddressForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  handlePrevious: PropTypes.func.isRequired,
  enums: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  isFieldsLoading: PropTypes.bool.isRequired,
  owner: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  setFileList: PropTypes.func,
  formDataEnvironment: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFormDataEnvironment: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  setCountry: PropTypes.func.isRequired
};
SupervisionAddressForm.defaultProps = {
  initialValues: {},
  fileList: [],
  setFileList: () => {}
};
