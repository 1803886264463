import { useTranslation } from 'react-i18next';
import { Button, Form, Divider } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ListResource } from '../../../../../components/ListResource/ListResource';
import { ReviewDrawer } from '../../../../../components/Review/ReviewDrawer';
import { setRatingShowInfo } from '../../utils/setRatingShowInfo';

export const ListRatings = ({ supervision }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [showReviewDrawer, setShowReviewDrawer] = useState(false);
  const [editReviewDrawer, setEditReviewDrawer] = useState(false);
  const [reviewData, setReviewData] = useState({});
  const [questionnaireType, setQuestionnaireType] = useState('');

  useEffect(() => {
    if (forceRefresh) setForceRefresh(false);
  }, [forceRefresh]);

  useEffect(() => {
    if (Object.keys(reviewData).length) {
      setRatingShowInfo({
        reviewData,
        form,
        setQuestionnaireType
      });
    }
  }, [reviewData]);

  const columns = [
    {
      title: t('ratings.form.left_by'),
      key: 'author',
      render: (author) =>
        `${author?.id?.last_name || ''} ${author?.id?.first_name || ''}` || '-',
      dataIndex: 'author'
    },
    {
      title: t('ratings.form.received_by'),
      key: 'receiver',
      dataIndex: 'receiver',
      render: (receiver) => {
        if (receiver && receiver.id.length) {
          return receiver.id
            .map(
              (user) =>
                `${
                  user?.civility ? t(`pensioners.tags.${user.civility}`) : ''
                } ${user?.last_name || ''} ${user?.first_name || ''}`
            )
            .join(', ');
        }
        return '-';
      }
    },
    {
      title: t('ratings.form.supervision_city'),
      key: 'supervision',
      dataIndex: 'supervision',
      render: (superv) =>
        superv?.address?.petsitting_address?.address.city || '-'
    },
    {
      title: t('ratings.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (date) => dayjs(date).format('DD/MM/YYYY') || '-'
    },
    {
      title: t('ratings.form.rating'),
      key: 'rating',
      dataIndex: 'rating',
      render: (rating) => rating || '-'
    },
    {
      key: 'action',
      render: (text, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setEditReviewDrawer(false);
              setShowReviewDrawer(true);
              setReviewData(record);
              setOpen(true);
            }}
          >
            <EyeOutlined />
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            onClick={() => {
              setShowReviewDrawer(false);
              setEditReviewDrawer(true);
              setReviewData(record);
              setOpen(true);
            }}
          >
            <EditOutlined />
          </Button>
        </>
      )
    }
  ];

  const setExtraQuery = () => {
    let extraQuery = '';
    if (supervision._id) {
      extraQuery = `supervision=${supervision._id}&`;
    }
    return extraQuery;
  };

  useEffect(() => {
    setExtraQuery();
  }, [supervision]);

  return (
    <ListResource
      resourceName="ratings"
      resourceModelName="Ratings"
      forceRefresh={forceRefresh}
      columns={columns}
      showAction={false}
      withPageHeader={false}
      withUploadButton={false}
      withSearchBar={false}
      withCreateButton={false}
      withCustomCreateButton
      customActionColumn
      customCreateButtonAction={() => setOpen(true)}
      extraQuery={setExtraQuery()}
      onDoubleClickAction={{
        action: (record) => {
          setEditReviewDrawer(false);
          setShowReviewDrawer(true);
          setReviewData(record);
          setOpen(true);
        }
      }}
    >
      <ReviewDrawer
        t={t}
        open={open}
        setOpen={setOpen}
        supervision={supervision}
        showReviewDrawer={showReviewDrawer}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        questionnaireType={questionnaireType}
        setQuestionnaireType={setQuestionnaireType}
        supervisions={[supervision]}
        customInstanceForm={form}
        editReviewDrawer={editReviewDrawer}
        reviewData={reviewData}
      />
    </ListResource>
  );
};

ListRatings.propTypes = {
  owner: PropTypes.shape({
    _id: PropTypes.string,
    user: PropTypes.string
  }).isRequired,
  supervision: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};
