import { useTranslation } from 'react-i18next';
import { Button, Collapse, Skeleton, Typography, Flex, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useSupervisionCollapseInfos } from './useSupervisionCollapseInfos';
import { ImagesLayout } from './ImagesLayout';
import { dateFormat } from './utils/dateFormat';
import { PensionersProfiles } from './pensionersProfiles/PensionersProfiles';
import { ReviewDrawer } from '../../../components/Review/ReviewDrawer';
import { ListDocuments } from './documents/ListDocuments';
import { getSupervision } from './utils/getSupervision';
import { ListReviews } from './reviews/ListReviews';
import { OwnerProfile } from './ownerProfile/OwnerProfile';
import { getReviews } from './utils/getReviews';
import { buttonActions } from './utils/buttonActions';
import { useSocketContext } from '../../../contexts/SocketContext';

const { Text } = Typography;

export const ShowMySupervision = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [form] = Form.useForm();
  const { editSupervisionLock, onProfileLock } = useSocketContext();

  const [supervision, setSupervision] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [anounce, setAnounce] = useState({});
  const [userAlreadySendReview, setUserAlreadySendReview] = useState(false);
  const [showReviewDrawer, setShowReviewDrawer] = useState(false);
  const [editReviewDrawer, setEditReviewDrawer] = useState(false);

  const isOwner = user?.role === 'users:OWNER';
  const userIsReviewAuthor = reviews.some(
    (review) => review.author.id.user === user?._id && review.status === 'SENT'
  );

  useEffect(async () => {
    if (forceRefresh)
      await getReviews({
        dispatchAPI,
        supervision,
        setReviews,
        user,
        setUserAlreadySendReview,
        form,
        message
      });
    setForceRefresh(false);
  }, [forceRefresh]);

  useEffect(async () => {
    if (supervision?._id)
      await getReviews({
        dispatchAPI,
        supervision,
        setReviews,
        user,
        setUserAlreadySendReview,
        form,
        message
      });
  }, [supervision]);

  const url = supervision?.summary?.anounce_photos || [];
  const title = t(`supervisions.users_shows.supervision_date`, {
    reference: supervision?.reference,
    ...dateFormat(
      supervision?.main_informations?.start_date,
      supervision?.main_informations?.end_date
    )
  });

  const displayDocumentsCondition = ['BOOKED', 'IN_PROGRESS', 'FINISHED'];
  const displayPensionersProfilesCondition = [
    ...displayDocumentsCondition,
    'WAITING_BOOKING',
    'FINISHED'
  ];

  useEffect(() => {
    (async () => {
      await getSupervision({
        dispatchAPI,
        id,
        setSupervision,
        message,
        setIsLoading,
        setAnounce
      });
    })();
  }, [refreshData]);

  useEffect(() => {
    (async () => {
      await onProfileLock();
    })();
  }, []);

  const actionByStatus = buttonActions({
    navigate,
    supervision,
    dispatchAPI,
    message,
    setSupervision,
    setIsLoading,
    id,
    setOpen,
    t,
    editSupervisionLock
  })[supervision?.status];

  return (
    <>
      {isLoading ? (
        <Skeleton.Button
          loading={isLoading}
          active
          block
          style={{ margin: '20px 0 10px' }}
        />
      ) : (
        <PageHeaderCustom
          withoutBreadCrumb
          title={t('owners.users_shows.title.supervision_details', supervision)}
          extra={
            (!userIsReviewAuthor &&
              isOwner &&
              actionByStatus &&
              !userAlreadySendReview) ||
            (!userIsReviewAuthor &&
              !isOwner &&
              supervision.status === 'FINISHED' &&
              !userAlreadySendReview) ? (
              <Button type="primary" onClick={actionByStatus}>
                {t(`owners.users_shows.buttons.${supervision?.status}`)}
              </Button>
            ) : null
          }
        />
      )}
      <ContentCustom>
        <Skeleton
          loading={isLoading}
          active
          avatar={{ shape: 'square', size: 400 }}
        >
          <Flex
            justify="space-between"
            align="center"
            wrap="wrap"
            style={{ marginBottom: 16 }}
          >
            <Typography.Title level={3}>{title}</Typography.Title>
            <Typography.Text>
              {t(`supervisions.tags.${supervision?.status}`)}
            </Typography.Text>
          </Flex>
          {url.length ? <ImagesLayout url={url} /> : null}
          <Collapse
            ghost
            items={[
              {
                key: '1',
                label: (
                  <Text level={4} strong>
                    {t('supervisions.show.anounce.supervision_information')}
                  </Text>
                ),
                children: (
                  <DescriptionList
                    layout="horizontal"
                    data={useSupervisionCollapseInfos(
                      supervision,
                      t,
                      user,
                      anounce
                    )}
                  />
                )
              }
            ]}
            style={{ marginBottom: 32 }}
          />
          <Flex vertical gap="large">
            {displayPensionersProfilesCondition.includes(supervision?.status) &&
              isOwner && <PensionersProfiles supervision={supervision} />}
            {!isOwner && <OwnerProfile supervision={supervision} />}
            {displayDocumentsCondition.includes(supervision?.status) && (
              <ListDocuments
                supervision={supervision}
                t={t}
                refreshData={refreshData}
                setRefreshData={setRefreshData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}
            {reviews.length > 0 &&
              supervision?.status === 'FINISHED' &&
              userIsReviewAuthor && (
                <ListReviews
                  reviews={reviews}
                  user={user}
                  t={t}
                  setShowReviewDrawer={setShowReviewDrawer}
                  setOpen={setOpen}
                  title={title}
                  setEditReviewDrawer={setEditReviewDrawer}
                />
              )}
          </Flex>
        </Skeleton>
        <ReviewDrawer
          t={t}
          open={open}
          setOpen={setOpen}
          supervision={supervision}
          review={reviews}
          setReview={setReviews}
          setForceRefresh={setForceRefresh}
          showReviewDrawer={showReviewDrawer}
          customInstanceForm={form}
          editReviewDrawer={editReviewDrawer}
          reviewData={reviews.find(
            (review) =>
              review.author.id.user === user?._id && review.status === 'SENT'
          )}
        />
      </ContentCustom>
    </>
  );
};
