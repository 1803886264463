export const getSupervisions = async ({
  dispatchAPI,
  id,
  setSupervisions,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/supervisions?status=FINISHED&main_informations.owner=${id}&populate=pensioners,main_informations.owner`
    });
    setSupervisions(data);
  } catch (e) {
    message(e);
  }
};
