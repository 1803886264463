import { Card, Image, Spin, message as antdMessage } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Component for displaying a gallery of announcement photos.
 *
 * @component
 * @param {Object} props - The props that define this component.
 * @param {Array} props.photos - Array of photo objects.
 * @param {Array} props.selectedAnouncePhotos - Array of selected photo objects.
 * @param {Function} props.setSelectedAnouncePhotos - Function to set selected photos.
 * @returns {JSX.Element}
 */

export const AnouncePhotoGallery = ({
  photos,
  selectedAnouncePhotos,
  setSelectedAnouncePhotos
}) => {
  const { t } = useTranslation();
  const { token } = useAuthContext();

  const handleCardClick = (id, file) => {
    if (selectedAnouncePhotos.length === 10)
      return antdMessage.warning(t('supervision.messages.anounce_photo_limit'));
    setSelectedAnouncePhotos((prevSelected) => {
      const photoIndex = prevSelected.findIndex(
        (photo) => photo.metadata.originalName === file.metadata.originalName
      );
      if (photoIndex !== -1) {
        return [
          ...prevSelected.slice(0, photoIndex),
          ...prevSelected.slice(photoIndex + 1)
        ];
      }
      return [...prevSelected, file];
    });

    return null;
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {(photos || []).map((photo) => (
        <Card
          key={photo._id}
          hoverable
          style={{
            width: 240,
            margin: '10px',
            border: selectedAnouncePhotos.some(
              (selected) =>
                selected.metadata.originalName === photo.metadata.originalName
            )
              ? '5px solid orange'
              : 'none'
          }}
          onClick={() => handleCardClick(photo._id, photo)}
        >
          <Image
            alt={photo.metadata.originalName}
            src={`${process.env.REACT_APP_API_URL}/anounces/get-url/${photo?._id}/${token}`}
            style={{ width: '100%' }}
            placeholder={<Spin loading size="large" />}
            preview={false}
          />
        </Card>
      ))}
    </div>
  );
};

AnouncePhotoGallery.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({})),
  selectedAnouncePhotos: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  setSelectedAnouncePhotos: PropTypes.func.isRequired
};

AnouncePhotoGallery.defaultProps = {
  selectedAnouncePhotos: [],
  photos: []
};
