export const useDetailsCards = (supervision = {}) => {
  const { address: { petsitting_address } = {}, comment } = supervision;

  return [
    {
      label: 'Adresse de la garde',
      content:
        `${petsitting_address?.number || ''} ${
          petsitting_address?.additional || ''
        } ${petsitting_address?.street || ''} ${
          petsitting_address?.postal_code || ''
        } ${petsitting_address?.city || ''} ${
          petsitting_address?.country || ''
        }` || '-',
      comment: comment || '-',
      withDivider: true
    }
  ];
};
