import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import AnimalPanel from './animalForm/AnimalPanel';
import GroomerFields from './groomerForm/GroomerFields';
import PetsittingAddressFields from './petsittingAddressForm/PetsittingAddressFields';
import VeterinarianFields from './veterinarianForm/VeterinarianFields';

/**
 * `FormExtra` is a component that renders a collapsible form section, including fields for
 * petsitting address, animals, veterinarians, and groomers.
 *
 * It takes several props:
 * - `petsittingAddressFileList`: An array to hold the files for the petsitting address section.
 * - `setPetsittingAddressFileList`: A function to update `petsittingAddressFileList`.
 * - `form`: An object representing the form state.
 * - `animalsFileList`: An object representing the files for the animals section.
 * - `setAnimalsFileList`: A function to update `animalsFileList`.
 *
 * @component
 * @param {Object[]} petsittingAddressFileList - The list of files related to the petsitting address.
 * @param {Function} setPetsittingAddressFileList - Function to update the list of files related to the petsitting address.
 * @param {Object} form - The form object that holds the state of the form.
 * @param {Object[]} animalsFileList - The list of files related to animals.
 * @param {Function} setAnimalsFileList - Function to update the list of files related to animals.
 *
 * @example
 * @returns {React.Element} - formExtra element of the CreateUpdateOwner.
 */
export const FormExtra = ({
  petsittingAddressFileList,
  setPetsittingAddressFileList,
  form,
  animalsFileList,
  setAnimalsFileList,
  purpose,
  formDataEnvironment,
  setFormDataEnvironment,
  country,
  setCountry
}) => {
  const { t } = useTranslation();
  const items = [
    {
      key: '1',
      label: t('petsittingaddress.header_panel'),
      children: (
        <PetsittingAddressFields
          fileList={petsittingAddressFileList}
          setFileList={setPetsittingAddressFileList}
          form={form}
          purpose={purpose}
          formDataEnvironment={formDataEnvironment}
          setFormDataEnvironment={setFormDataEnvironment}
          country={country}
          setCountry={setCountry}
        />
      )
    },
    {
      key: '2',
      label: (
        <AnimalPanel
          fileList={animalsFileList}
          setFileList={setAnimalsFileList}
          form={form}
        />
      ),
      collapsible: 'disabled',
      showArrow: false
    },
    {
      key: '3',
      label: t('veterinarians.header_panel'),
      children: <VeterinarianFields />
    },
    {
      key: '4',
      label: t('groomers.header_panel'),
      children: <GroomerFields />
    }
  ];
  return <Collapse items={items} style={{ width: '100%' }} bordered={false} />;
};

FormExtra.propTypes = {
  petsittingAddressFileList: PropTypes.arrayOf().isRequired,
  setPetsittingAddressFileList: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired,
  animalsFileList: PropTypes.shape({}).isRequired,
  setAnimalsFileList: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  formDataEnvironment: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFormDataEnvironment: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  setCountry: PropTypes.func.isRequired
};

FormExtra.defaultProps = {
  purpose: ''
};
