import React, { useEffect, useState } from 'react';
import { Input, Form, Typography, Upload } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useFields } from './fields/fields';
import GeneralInformations from './fields/generalInformationsFields';
import RoomAndLaundryInformations from './fields/roomAndLaundryFields';
import BathroomInformations from './fields/bathroomFields';
import KitchenInformations from './fields/kitchenFields';
import OutsideInformations from './fields/outsideFields';
import ParkingAndInstallationInformations from './fields/parkingAndInstallationFields';
import EquipmentInformations from './fields/equipmentFields';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { draggerProps } from '../utils/petsittingAddressDraggerProps';

const { Title } = Typography;
const { Dragger } = Upload;
const { TextArea } = Input;

/**
 * PetsittingAddressFields is a component that generates form fields for a petsitting address form.
 * It manages the state of the dwelling type and utilizes various hooks for generating form fields and translations.
 *
 * @component
 * @param {Object} props The properties passed down to this component.
 * @param {function} props.setFileList A function to set the list of files.
 * @param {Object} props.fileList An object representing the current list of files.
 * @param {Object} props.form Instance of the form.
 *
 * @returns {React.Element} - A React component containing grouped inputs and form items for the petsitting address form.
 */
const PetsittingAddressFields = ({
  setFileList,
  fileList,
  form,
  purpose,
  setFormDataEnvironment,
  formDataEnvironment,
  country,
  setCountry
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFields = useGenerateFormItem();

  const [typeOfDwelling, setTypeOfDwelling] = useState('');
  const { formFields } = useFields({
    setTypeOfDwelling,
    form,
    country,
    setCountry
  });
  const { generalInformationsFields } = GeneralInformations({
    typeOfDwelling,
    t,
    formDataEnvironment,
    setFormDataEnvironment,
    form
  });
  const { roomAndLaundryInformationsFields } =
    RoomAndLaundryInformations(typeOfDwelling);
  const { bathroomInformationsFields } = BathroomInformations();
  const { kitchenInformationsFields } = KitchenInformations();
  const { outsideInformationsFields } = OutsideInformations();
  const { parkingAndInstallationInformationsFields } =
    ParkingAndInstallationInformations(typeOfDwelling);
  const { equipmentInformationsFields } = EquipmentInformations();

  useEffect(() => {
    if (purpose === 'create') {
      const values = form.getFieldsValue();
      if (values.address) {
        form.setFieldsValue({
          petsitting_address: { address: { ...values.address } }
        });
      }
    }
    setTypeOfDwelling(
      form.getFieldValue(['petsitting_address', 'type_of_dwelling'])
    );
  }, [form]);

  return (
    <Input.Group name={['petsitting_address']}>
      <Form.Item noStyle name={['petsitting_address']}>
        {formFields.map((fields) =>
          fields.map((field) => generateFields('petsittingaddress', field))
        )}
      </Form.Item>
      <Title level={4}>{t('owners.form.general_infos')}</Title>
      {generalInformationsFields.map((field) =>
        generateFields('petsittingaddress', field)
      )}
      <Title level={4}>{t('owners.form.room_and_laundry')}</Title>
      {roomAndLaundryInformationsFields.map((field) =>
        generateFields('petsittingaddress', field)
      )}
      <Title level={4}>{t('owners.form.bathroom')}</Title>
      {bathroomInformationsFields.map((field) =>
        generateFields('petsittingaddress', field)
      )}
      <Title level={4}>{t('owners.form.kitchen')}</Title>
      {kitchenInformationsFields.map((field) =>
        generateFields('petsittingaddress', field)
      )}
      <Title level={4}>{t('owners.form.outside')}</Title>
      {outsideInformationsFields.map((field) =>
        generateFields('petsittingaddress', field)
      )}
      <Title level={4}>{t('owners.form.parking')}</Title>
      {parkingAndInstallationInformationsFields.map((field) =>
        generateFields('petsittingaddress', field)
      )}
      <Title level={4}>{t('owners.form.equipment')}</Title>
      {equipmentInformationsFields.map((field) =>
        generateFields('petsittingaddress', field)
      )}
      <Form.Item
        label={t('owners.form.complementary-infos')}
        name={['petsittingaddress', 'complementary_infos']}
      >
        <TextArea />
      </Form.Item>
      <Form.Item label={t('owners.form.dwelling-photos')}>
        <Dragger
          {...draggerProps(t, dispatchAPI, message, fileList, setFileList)}
        >
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      </Form.Item>
    </Input.Group>
  );
};

export default PetsittingAddressFields;

PetsittingAddressFields.propTypes = {
  setFileList: PropTypes.func.isRequired,
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldsValue: PropTypes.func
  }),
  purpose: PropTypes.string,
  setFormDataEnvironment: PropTypes.func.isRequired,
  formDataEnvironment: PropTypes.arrayOf(PropTypes.string).isRequired,
  country: PropTypes.string.isRequired,
  setCountry: PropTypes.func.isRequired
};

PetsittingAddressFields.defaultProps = {
  fileList: null,
  form: null,
  purpose: ''
};
