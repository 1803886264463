import { createFileFromJSON } from '../../../../utils/filesManagement/createFileFromJSON';

export const useConfig = (
  petsittingAddressFileList,
  setPetsittingAddressFileList,
  formDataEnvironment,
  setFormDataEnvironment,
  dispatchAPI,
  message,
  setCountry
) => ({
  onCreateResource: {
    setBody: (data) => {
      const formData = new FormData();

      if (petsittingAddressFileList.length) {
        petsittingAddressFileList.forEach((file) => {
          formData.append('dwelling_photos', file.file, file.file.name);
        });
      }

      formData.append(
        'values',
        JSON.stringify({ ...data, type_of_environment: formDataEnvironment })
      );

      return formData;
    }
  },
  onUpdateResource: {
    setBody: (data) => {
      const formData = new FormData();

      if (petsittingAddressFileList.length) {
        petsittingAddressFileList.forEach((file) => {
          formData.append('dwelling_photos', file.file, file.file.name);
        });
      }

      formData.append(
        'values',
        JSON.stringify({
          ...data,
          petsitting_address: {
            ...data.petsitting_address,
            type_of_environment: formDataEnvironment
          }
        })
      );

      return formData;
    }
  },
  onGetResource: {
    setFields: async (data) => {
      if (data?.dwelling_photos && data?.dwelling_photos?.length) {
        try {
          const dwellingPhotosPromises = (data.dwelling_photos || []).map(
            (photo) => createFileFromJSON(photo, dispatchAPI, message)
          );

          const results = await Promise.all(dwellingPhotosPromises);
          setPetsittingAddressFileList(results ?? []);
        } catch (error) {
          message(message(error));
        }
      }

      if (data?.type_of_environment) {
        setFormDataEnvironment(data.type_of_environment);
      }

      setCountry(data?.address?.country);

      return { petsitting_address: data };
    }
  }
});
