import { Badge, Col, Divider, List, Row, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatNotificationDate } from '../../utils/formatNotificationDate';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

export const NotificationsDetails = ({
  content,
  pathname,
  notifID,
  createdAt,
  deleteNotification,
  consulted
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();

  const patchNotifications = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `notifications/${notifID}`
      });
    } catch (e) {
      message(e);
    }
  };

  const consultedNotif = consulted?.filter((id) => id === user._id);

  return (
    <>
      <Row justify="space-between" align="middle" gutter={10}>
        <Col span={22}>
          <Link to={{ pathname }} onClick={patchNotifications}>
            <Row>
              <List.Item>
                {!consultedNotif?.length ? (
                  <Badge color="red" text={content} />
                ) : (
                  content
                )}
              </List.Item>
            </Row>
            <Row>
              <Typography.Text>
                {formatNotificationDate(createdAt)}
              </Typography.Text>
            </Row>
          </Link>
        </Col>
        <Col span={2}>
          <CloseOutlined onClick={() => deleteNotification(notifID)} />
        </Col>
      </Row>
      <Divider />
    </>
  );
};

NotificationsDetails.propTypes = {
  content: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  notifID: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  consulted: PropTypes.arrayOf(PropTypes.string).isRequired
};
