/**
 * Determines the color code corresponding to a given data type. This function facilitates visualization by mapping different data types
 * to distinct color codes which can be utilized in UI development, particularly in developing tag systems or data visualization modules.
 *
 * @param {string} type - The data type for which the color code needs to be determined. Expected values: 'String', 'Number', 'Date', 'Boolean'. Any other value will return the default color code.
 * @returns {string} The color code corresponding to the given data type. Returns a default color code if the type doesn't match any predefined categories.
 *
 * @example
 * pickTagColor('String'); // Returns: '#199D90'
 * pickTagColor('Boolean'); // Returns: '#F18F01'
 */
export const pickTagColor = (type) => {
  let color;
  switch (type) {
    case 'String':
      color = '#199D90';
      break;
    case 'Number':
      color = '#DB5461';
      break;
    case 'Date':
      color = '#6153CC';
      break;
    case 'Boolean':
      color = '#F18F01';
      break;
    default:
      color = '#000501';
      break;
  }
  return color;
};
