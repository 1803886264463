import { Card, Col, Row, Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../../components';
import { veterinarianInfo } from '../listContent/veterinarianInfo';
import { generatePDF } from '../../../../../components/TemplateDocumentButton/TemplateDocumentButton';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

/**
 * Component for displaying veterinarian information in a card.
 *
 * @param {Object} props - The component's props.
 * @param {boolean} props.isLoading - Indicates if the data is currently loading.
 * @param {Object} props.veterinarian - The veterinarian object to display.
 * @returns {JSX.Element} - The rendered VeterinarianCard component.
 */
export const VeterinarianCard = ({ veterinarian, isLoading }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <Card
        title={t('supervisions.show.veterinarian.title')}
        extra={
          <Button
            onClick={() =>
              generatePDF({
                dispatchAPI,
                id: veterinarian._id,
                collection: 'Veterinarian',
                use_case: 'VETERINARIAN_SYNTHESIS',
                message
              })
            }
          >
            <DownloadOutlined />
          </Button>
        }
      >
        <Row>
          <Col span={24}>
            <DescriptionList
              data={veterinarianInfo(veterinarian, t)}
              translate
            />
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

VeterinarianCard.propTypes = {
  veterinarian: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
};
