import React from 'react';
import { Menu, Typography, Col, Row } from 'antd';
import { MailOutlined, DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { generateDocumentsOld } from '../../mail-sender/utils/generateDocumentsOld';
import { generateFilterAndRecordID } from './generateFilterAndRecordID';

const { Text } = Typography;

export const InformationMenu = ({
  supervision,
  setRecipients,
  setIsEmailModalOpen,
  setTemplateType,
  setDocumentType,
  setFilterID,
  setRecordID
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const options = [];

  options.push({
    ...supervision.main_informations.owner,
    type: 'owner',
    user_collection: 'owners',
    customer_type: 'OWNER',
    document_type: 'PENSIONER_INFORMATION_DOCUMENT'
  });
  supervision.pensioners.forEach((pensioner, index) => {
    options.push({
      ...pensioner,
      type: 'pensioner',
      user_collection: 'pensioners',
      customer_type: 'PENSIONER',
      document_type: 'OWNER_INFORMATION_DOCUMENT',
      number: index + 1
    });
  });
  return (
    <Menu>
      {options.map((option) => (
        <Menu.Item
          key={option._id || option.type}
          onClick={() => {
            setRecipients([option]);
            setIsEmailModalOpen(true);
            setTemplateType(`SEND_INFORMATION_${option.customer_type}`);
            setDocumentType(option.document_type);
            generateFilterAndRecordID({
              dispatchAPI,
              documentType: option.document_type,
              id,
              option,
              supervision,
              setFilterID,
              setRecordID,
              setDocumentType
            });
          }}
        >
          <Row gutter={10}>
            <Col>
              <MailOutlined />
            </Col>
            <Col>
              <Text>
                {t(`supervisions.show.buttons.${option.type}`, {
                  number: option.number
                })}
              </Text>
            </Col>
          </Row>
        </Menu.Item>
      ))}
      {options.map((option) => {
        const documentType =
          option.type === 'owner'
            ? 'PENSIONER_INFORMATION_DOCUMENT'
            : 'OWNER_INFORMATION_DOCUMENT';
        return (
          <Menu.Item
            key={option._id ? `dl${option._id}` : `dl${option.type}`}
            onClick={() =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                documentType,
                id,
                option._id,
                t
              )
            }
          >
            <Row gutter={10}>
              <Col>
                <DownloadOutlined />
              </Col>
              <Col>
                <Text>
                  {t(`supervisions.show.buttons.${option.type}`, {
                    number: option.number
                  })}
                </Text>
              </Col>
            </Row>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

InformationMenu.propTypes = {
  supervision: PropTypes.shape({
    main_informations: PropTypes.shape({
      owner: PropTypes.shape({
        _id: PropTypes.string
      })
    }),
    pensioners: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string
      })
    )
  }),
  setRecipients: PropTypes.func,
  setIsEmailModalOpen: PropTypes.func,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }),
  setTemplateType: PropTypes.func,
  setDocumentType: PropTypes.func,
  setFilterID: PropTypes.func,
  setRecordID: PropTypes.func
};

InformationMenu.defaultProps = {
  supervision: {},
  setRecipients: () => {},
  setIsEmailModalOpen: () => {},
  form: {},
  setTemplateType: () => {},
  setDocumentType: () => {},
  setFilterID: () => {},
  setRecordID: () => {}
};
