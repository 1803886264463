import alignLeft from '../../../../../assets/images/icons/align-left.png';
import alignJustify from '../../../../../assets/images/icons/align-justify.png';
import alignRight from '../../../../../assets/images/icons/align-right.png';
import alignCenter from '../../../../../assets/images/icons/align-center.png';
import close from '../../../../../assets/images/icons/close.png';
import italic from '../../../../../assets/images/icons/italic.png';
import overline from '../../../../../assets/images/icons/overline.png';
import underline from '../../../../../assets/images/icons/underline.png';
import strikethrough from '../../../../../assets/images/icons/strikethrough.png';

/**
 * Updates the options of typography properties in the style manager. This includes modifying the properties for font style, font size, color, text decoration, and text alignment with new values and icons.
 *
 * @param {Object} styleManager - The style manager instance where the typography sector properties are being updated.
 *
 * @returns {void}
 */
const editTypographyOptions = (styleManager) => {
  const typographySector = styleManager.getSector('typography');

  const fontStyleProperty = typographySector.getProperty('font-style');
  fontStyleProperty.set('options', [
    {
      id: 'normal',
      name: `<img src="${close}" width="15" height="15" alt="alignLeft" />`
    },
    {
      id: 'italic',
      name: `<img src="${italic}" width="15" height="15" alt="alignLeft" />`
    }
  ]);

  const fontSizeProperty = typographySector.getProperty('font-size');
  fontSizeProperty.set('placeholder', 'Moyenne');

  const colorProperty = typographySector.getProperty('color');
  colorProperty.set('placeholder', 'Blanc');

  const textDecorationProperty =
    typographySector.getProperty('text-decoration');
  textDecorationProperty.set('options', [
    {
      id: 'none',
      name: `<img src="${close}" width="10" height="10" alt="alignLeft" />`
    },
    {
      id: 'underline',
      name: `<img src="${underline}" width="10" height="10" alt="alignLeft" />`
    },
    {
      id: 'overline',
      name: `<img src="${overline}" width="10" height="10" alt="alignLeft" />`
    },
    {
      id: 'line-through',
      name: `<img src="${strikethrough}" width="10" height="10" alt="alignLeft" />`
    }
  ]);

  const textAlignProperty = typographySector.getProperty('text-align');
  textAlignProperty.set('options', [
    {
      id: 'justify',
      name: `<img src="${alignJustify}" width="10" height="10" alt="alignLeft" />`
    },
    {
      id: 'left',
      name: `<img src="${alignLeft}" width="10" height="10" alt="alignLeft" />`
    },
    {
      id: 'center',
      name: `<img src="${alignCenter}" width="10" height="10" alt="alignLeft" />`
    },
    {
      id: 'right',
      name: `<img src="${alignRight}" width="10" height="10" alt="alignLeft" />`
    }
  ]);
};

/**
 * Updates the options of decoration properties in the style manager. This includes modifying the border collapse option and updating names and placeholders of border properties.
 *
 * @param {Object} styleManager - The style manager instance where the decoration sector properties are being updated.
 *
 * @returns {void}
 */
const editDecorationOptions = (styleManager) => {
  const decorationSector = styleManager.getSector('decorations');

  const borderCollapseProperty =
    decorationSector.getProperty('border-collapse');

  borderCollapseProperty.set('options', [
    {
      value: 'separate',
      name: `Oui`
    },
    {
      value: 'collapse',
      name: `Non`
    }
  ]);

  const borderProperties = decorationSector
    .getProperty('border')
    .getProperties();

  for (let i = 0; i < borderProperties.length; i += 1) {
    switch (borderProperties[i].attributes.name) {
      case 'Width':
        borderProperties[i].set('name', 'Largeur');
        break;
      case 'Color':
        borderProperties[i].set('name', 'Couleur');
        borderProperties[i].set('placeholder', 'Blanc');
        break;
      case 'Style':
        borderProperties[i].set('name', 'Style');
        break;
      default:
        break;
    }
  }
};

/**
 * Updates various options in the style manager by invoking `editTypographyOptions` and `editDecorationOptions` functions to update typography and decoration sector properties respectively. Finally, re-renders the style manager to apply the changes.
 *
 * @param {Object} editor - The editor instance holding the style manager where the changes will be applied.
 *
 * @example
 *
 * editStyleManagerOptions(editorInstance);
 * // The style manager of the provided editor instance will be updated with new options and re-rendered.
 *
 * @returns {void}
 */
export const editStyleManagerOptions = (editor) => {
  const styleManager = editor.StyleManager;

  editTypographyOptions(styleManager);
  editDecorationOptions(styleManager);

  styleManager.render();
};
