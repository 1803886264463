/**
 * Processes a dot-delimited string and filters out segments that contain 'phone_number' or 'address'.
 * If the input string has more than 2 segments, it returns a dot-delimited string consisting of the middle segments (excluding the first and the last) which do not contain 'phone_number' or 'address'.
 * If the input string has 2 or fewer segments, it returns null.
 *
 * @param {string} populateItem - The dot-delimited string to process.
 * @returns {string|null} A dot-delimited string of filtered segments or null if the input string has 2 or fewer segments.
 *
 * @example
 * processString('segment1.segment2.phone_number.segment3'); // returns 'segment2'
 * processString('segment1.segment2'); // returns null
 */
export const processString = (populateItem) => {
  const splittedStrings = populateItem.split('.');
  const filteredStrings = [];

  const isDateOrAddress = (item) =>
    item.includes('phone_number') || item.includes('address');

  if (splittedStrings.length > 2) {
    for (let i = 0; i < splittedStrings.length; i += 1) {
      if (
        i !== 0 &&
        i !== splittedStrings.length - 1 &&
        !isDateOrAddress(splittedStrings[i])
      ) {
        filteredStrings.push(splittedStrings[i]);
      }
    }
  } else {
    return null;
  }
  return filteredStrings.join('.');
};
