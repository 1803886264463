import pluralize from 'pluralize';
import { createTranslationKey } from './createTranslationKey';
import { pickTagColor } from './pickTagColor';

/**
 * Generates a string of HTML options and optgroups based on the data input, with dynamic styling and translated labels.
 * It recursively calls itself to handle nested content (subcontent) within tags.
 *
 * @param {Array} data - The data array, where each element represents a tag with key, type, and subcontent properties.
 * @param {string} parentKeyPath - A string representing the current nested path within the data tree structure.
 * @param {function} t - The translation function used to generate localized labels for the options and optgroups.
 *
 * @returns {string} - A string of HTML containing the formatted <option> and <optgroup> elements.
 *
 * @example
 *
 * const data = [
 *   { key: 'key1', type: 'String', subcontent: [] },
 *   { key: 'key2', type: 'Number', subcontent: [{ key: 'subkey1', type: 'String', subcontent: [] }] }
 * ];
 * const parentKeyPath = '';
 * const t = (key) => key; // A dummy translation function for the example.
 *
 * generateOptions(data, parentKeyPath, t);
 * // Output: a string of HTML with options and optgroups, with styles and translated labels.
 */
export const generateOptions = (data, parentKeyPath, t) =>
  data
    .map((tag) => {
      const currentKey = parentKeyPath
        ? `${parentKeyPath}.${tag.key}`
        : tag.key;

      const tagColor = pickTagColor(tag.type);

      if (!tag.subcontent.length) {
        return `<option value="{{${currentKey}}}" style="color: ${tagColor}">${createTranslationKey(
          'tag',
          currentKey,
          t,
          pluralize
        )}</option>`;
      }
      const subOptions = generateOptions(tag.subcontent, currentKey, t);
      return `<optgroup label="${createTranslationKey(
        'header',
        currentKey,
        t,
        pluralize
      )}" style="color:black">${subOptions}</optgroup>`;
    })
    .join('');
