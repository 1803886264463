/**
 * Registers a button in the GrapesJS editor to set the canvas device to A4 size.
 *
 * @function
 * @param {object} editor - The GrapesJS editor instance.
 *
 * @returns {void}
 */
export const registerA4Button = (editor) => {
  editor.Commands.add('set-device-A4', {
    run: () => {
      const deviceManager = editor.Devices;
      deviceManager.select('A4');
    }
  });

  // Add the custom panel to the GrapesJS editor
  const customDevicesPanel = editor.Panels.getPanel('devices-c');
  if (customDevicesPanel) {
    customDevicesPanel.get('buttons').add([
      {
        id: 'A4',
        label: 'A4',
        className: 'custom-device-button',
        command: 'set-device-A4',
        attributes: {
          'data-device': 'A4'
        }
      }
    ]);
  }
};
