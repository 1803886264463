/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Row, Col, Tag, Typography, Card } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns/createUpdateColumns';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { mailSenderStatus } from '../../utils/constants/tagColors';
import { setShowParameters } from './utils/setShowParameters';
import { getEnums } from './utils/getEnums';
import { getMailSender } from './utils/getMailSender';
import { ShowExtraFilters } from './extraFilters/ShowExtraFilters';

/**
 * Component for displaying details of a mail sender.
 * This component shows the details of a mail sender based on its type and status.
 * It also includes extra filters for customizing the display.
 *
 * @component
 * @returns {JSX.Element} React element representing the component.
 */

const { Text } = Typography;

export const MailSenderShow = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  const { type, id, status, sendingType } = useParams();
  const [mailSender, setMailSender] = useState({});
  const { pensionerColumns, ownerColumns, veterinarianAndGroomerColumns } =
    useColumns({ enums });

  useEffect(() => {
    (async () => {
      if (type !== 'GROOMER_VETERINARIAN_ADVERT') {
        await getEnums({
          dispatchAPI,
          type,
          pensionerColumns,
          ownerColumns,
          veterinarianAndGroomerColumns,
          setEnums,
          message,
          purpose: 'show'
        });
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getMailSender({ dispatchAPI, id, setMailSender });
    })();
  }, []);

  return (
    <ListResource
      resourceName={`mail-sender/show/${type}/${id}`}
      columns={
        setShowParameters({
          type,
          pensionerColumns,
          ownerColumns,
          veterinarianAndGroomerColumns
        }).columns
      }
      resourceModelName="Mailsender"
      populate={
        setShowParameters({
          type,
          pensionerColumns,
          ownerColumns,
          veterinarianAndGroomerColumns
        }).populate
      }
      forceRefresh={refreshData}
      customActionColumn
      customPathName={`mail-sender-${sendingType.toLowerCase()}/show/${id}`}
      children={
        sendingType === 'EMAIL' ? (
          <Card>
            <Row gutter={16}>
              {t('mail-sender.form.previsional_sending_date_label')}
              <Text>
                {dayjs(mailSender?.pervisional_sending_date).format(
                  'DD/MM/YYYY'
                )}
              </Text>
            </Row>
          </Card>
        ) : null
      }
      customPageHeaderTitle={
        <Row gutter={16}>
          <Col>
            <Text>
              {t('mail-sender.page-header-title', {
                type: t(`mail-sender.tags.${type}`)
              })}
            </Text>
          </Col>
          <Col>
            <Tag color={mailSenderStatus.status}>
              {t(`mail-sender.tags.${status}`)}
            </Tag>
          </Col>
        </Row>
      }
      withCreateButton={false}
      extraFilters={
        <ShowExtraFilters
          dispatchAPI={dispatchAPI}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          message={message}
          mailSender={mailSender}
        />
      }
    />
  );
};
