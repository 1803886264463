import { generatePDF } from '../../../components/TemplateDocumentButton/TemplateDocumentButton';
import { generateDocumentsOld } from './generateDocumentsOld';

export const generateDocuments = async (record, dispatchAPI, message, t) => {
  switch (record.document_type) {
    case 'OTHER_PENSIONER':
      await record.pensioners.map(async (pensionerId) => {
        await generatePDF({
          dispatchAPI,
          id: pensionerId,
          collection: 'Pensioner',
          use_case: 'OTHER_PENSIONER',
          message
        });
      });
      break;
    case 'OWNER_WISH':
      await record.owners.map(async (ownerId) => {
        await generatePDF({
          dispatchAPI,
          id: ownerId,
          collection: 'Owner',
          use_case: 'OWNER_WISH',
          message
        });
      });
      break;
    case 'PENSIONER_WISH':
      await record.pensioners.map(async (pensionerId) => {
        await generatePDF({
          dispatchAPI,
          id: pensionerId,
          collection: 'Pensioner',
          use_case: 'PENSIONER_WISH',
          message
        });
      });
      break;
    case 'OWNER_EXPLANATORY_DOCUMENT':
      await record.owners.map(async (ownerId) => {
        await generateDocumentsOld(
          dispatchAPI,
          message,
          record.document_type,
          null,
          ownerId,
          t
        );
      });
      break;
    case 'PENSIONER_EXPLANATORY_DOCUMENT':
      await record.pensioners.map(async (pensionerId) => {
        await generateDocumentsOld(
          dispatchAPI,
          message,
          record.document_type,
          null,
          pensionerId,
          t
        );
      });
      break;
    case 'GROOMER_VETERINARIAN_ADVERT':
      if (record.veterinarians.length) {
        await record.veterinarians.map(async (veterinarianId) => {
          await generateDocumentsOld(
            dispatchAPI,
            message,
            record.document_type,
            veterinarianId,
            null,
            t
          );
        });
      }

      if (record.groomers.length) {
        await record.groomers.map(async (groomerId) => {
          await generateDocumentsOld(
            dispatchAPI,
            message,
            record.document_type,
            groomerId,
            null,
            t
          );
        });
      }
      break;
    case 'OWNER_EXPLANATORY_DOCUMENT_WITHOUT_NAME':
      await generateDocumentsOld(
        dispatchAPI,
        message,
        record.document_type,
        null,
        null,
        t
      );
      break;
    case 'PENSIONER_EXPLANATORY_DOCUMENT_WITHOUT_NAME':
      await generateDocumentsOld(
        dispatchAPI,
        message,
        record.document_type,
        null,
        null,
        t
      );
      break;
    case 'ANOUNCEMENT_WITHOUT_NAME':
      await generateDocumentsOld(
        dispatchAPI,
        message,
        record.document_type,
        null,
        null,
        t
      );
      break;
    default:
      await record.pensioners.map(async (pensionerId) => {
        await generateDocumentsOld(
          dispatchAPI,
          message,
          record.document_type,
          pensionerId,
          null,
          t
        );
      });
  }

  return null;
};
