import { Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * ArrayModal is a React component that provides a modal form for creating a table with a dynamic number of rows and columns.
 * It uses Ant Design components for the UI and reacts-i18next for internationalization.
 *
 * @component
 * @param {object} props - Props passed to the component
 * @param {object} props.editorRef - A ref object pointing to the GrapesJS editor instance
 * @param {boolean} props.open - A boolean indicating if the modal is open
 * @param {function} props.setOpen - A function to set the open state of the modal
 * @param {function} props.handleModal - A function to handle the modal cancel action
 *
 * @returns {JSX.Element} The ArrayModal component rendered as JSX
 * @example
 * <ArrayModal editorRef={editorRef} open={isOpen} setOpen={setOpenState} handleModal={handleModalFunction}/>
 */
export const ArrayModal = ({ editorRef, open, setOpen, handleModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const { cols, rows } = form.getFieldsValue();

    // Add the array to the GrapesJS canvas (example code)
    if (editorRef.current) {
      const editor = editorRef.current;
      const cmp = editor.Components;
      const wrapperChildren = cmp.getWrapper().components();

      // Create the table component
      const tableComponent = cmp.addComponent({
        tagName: 'table',
        components: [],
        style: {
          width: '80%',
          'text-align': 'center',
          margin: '0 auto',
          border: '1px solid black',
          'border-collapse': 'collapse'
        }
      });

      // Add rows and cells to the table component
      for (let i = 0; i < rows; i += 1) {
        const rowComponent = cmp.addComponent({
          tagName: 'tr',
          components: [],
          style: {
            border: '1px solid black',
            padding: '0',
            margin: '0'
          }
        });

        for (let j = 0; j < cols; j += 1) {
          const cellComponent = cmp.addComponent({
            tagName: 'td',
            content: `Cell ${i * cols + j + 1}`,
            attributes: {
              contenteditable: 'true'
            },
            style: {
              border: '1px solid black',
              padding: '0',
              margin: '0'
            }
          });

          rowComponent.components().add(cellComponent);
        }

        tableComponent.components().add(rowComponent);
      }

      // Add the table component to the canvas
      wrapperChildren.add(tableComponent);
    }
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onCancel={handleModal}
      onOk={handleFormSubmit}
      title={t('template-documents.form.array-generation')}
    >
      <Form form={form} initialValues={{ cols: 1, rows: 1 }}>
        <Form.Item name="cols" label={t('template-documents.form.cols')}>
          <Input type="number" />
        </Form.Item>
        <Form.Item name="rows" label={t('template-documents.form.rows')}>
          <Input type="number" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ArrayModal.propTypes = {
  editorRef: PropTypes.shape({
    current: PropTypes.shape({
      Components: PropTypes.shape({
        addComponent: PropTypes.func,
        getWrapper: PropTypes.func
      })
    })
  }),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleModal: PropTypes.func
};

ArrayModal.defaultProps = {
  editorRef: null,
  open: undefined,
  setOpen: null,
  handleModal: null
};
