/* eslint-disable no-underscore-dangle */
import ReactDOM from 'react-dom';
import React from 'react';

/**
 * This function defines a new component type 'react-component' for the GrapesJS editor.
 * This component type allows for React components to be used inside the GrapesJS editor.
 * The view of the component type handles the rendering of the React components, including the mounting of the components into the DOM and the rendering of the children of the components.
 *
 * @param {Object} editor - The GrapesJS editor instance.
 *
 * @example
 * import yourCustomReactComponentFunction from './path-to-your-react-component';
 *
 * editor.DomComponents.addType('react-component', yourCustomReactComponentFunction(editor));
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (editor) => {
  const domc = editor.Components;
  const defType = domc.getType('default');
  const defModel = defType.model;
  const wrpChld = 'data-chld';

  // Main React component
  domc.addType('react-component', {
    model: {
      toHTML(opts = {}) {
        return defModel.prototype.toHTML.call(this, {
          ...opts,
          tag: this.get('type')
        });
      }
    },
    view: {
      tagName: 'div',

      init() {
        const { model } = this;
        this.listenTo(model, 'change:attributes', this.render);
        this.listenTo(model.components(), 'add remove reset', this.__upRender);
      },

      getChildrenContainer() {
        const { childrenContainer } = this;
        if (childrenContainer) return childrenContainer;

        this.childrenContainer = document.createElement('childc');

        return this.childrenContainer;
      },

      createReactChildWrap() {
        return React.createElement('span', { [wrpChld]: true });
      },

      createReactEl(cmp, props) {
        return React.createElement(cmp, props, this.createReactChildWrap());
      },

      mountReact(cmp, el) {
        ReactDOM.render(cmp, el);
      },

      render() {
        const { model, el } = this;
        this.updateAttributes();
        this.renderChildren();
        const reactEl = this.createReactEl(model.get('component'), {
          ...model.get('attributes')
        });
        this.mountReact(reactEl, el);
        const chld = el.querySelector(`span[${wrpChld}]`);

        // If the container is found, the react component is able to render children
        if (chld) {
          const chldCont = this.getChildrenContainer();
          while (chldCont.firstChild) {
            chld.appendChild(chldCont.firstChild);
          }
        }

        return this;
      },

      __upRender() {
        clearTimeout(this._upr);
        this._upr = setTimeout(() => this.render());
      }
    }
  });
};
