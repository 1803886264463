export const setRatingShowInfo = ({
  reviewData,
  setQuestionnaireType,
  form
}) => {
  const receiverIds = reviewData.receiver.id.map((user) => user._id);
  if (reviewData.author.role === 'users:PENSIONER') {
    setQuestionnaireType('users:PENSIONER');
  } else {
    setQuestionnaireType('users:OWNER');
  }
  form.setFieldsValue({
    ...reviewData,
    questionnaire_type:
      reviewData.author.role === 'users:PENSIONER'
        ? 'users:PENSIONER'
        : 'users:OWNER',
    author: { id: reviewData.author.id._id },
    receiver: { id: receiverIds }
  });
};
