import { Editor } from 'grapesjs';

/**
 * Registers a custom 'for-loop' component and a corresponding block in the GrapesJS editor. The component represents a for-loop structure with a selectable collection trait, and includes CSS styling rules.
 *
 * @param {Editor} editor - The GrapesJS editor instance where the custom component and block are to be registered.
 * @returns {void}
 */
export const registerForLoopComponent = (editor: Editor) => {
  editor.Blocks.add('for-loop', {
    label: 'Boucle',
    content: `<div class="for-loop">
                <div class="loop-heading">Boucle FOR sur la collection <span class="collection-trait">{{ collection }}</span></div>
                <div class="loop-content">
                  <!-- Add your loop content here -->
                </div>
                <div class="loop-ending">Fin de la boucle</div>
              </div>`
  });

  editor.Components.addType('for-loop', {
    isComponent: (el) => el.classList && el.classList.contains('for-loop'),
    model: {
      defaults: {
        tagName: 'div',
        classes: ['for-loop'],
        traits: [
          {
            name: 'collection',
            type: 'select',
            label: 'Select Collection',
            full: true,
            options: [
              { id: 'collection-1', name: 'Collection 1' },
              { id: 'collection-2', name: 'Collection 2' }
            ],
            changeProp: true,
            setValue: ({ value, trait, editor, component }) => {
              console.log('setValue', value, trait, editor);
              const collectionTrait = editor
                .getSelected()
                .find('.loop-heading .collection-trait');
              if (collectionTrait) {
                collectionTrait.set('content', value);
              }

              //component.set('collection', value);

              component
                .find('.loop-heading .collection-trait')[0]
                .set('content', value);

              trait.setValue(value);

              return trait;
            }
          }
        ]
      },
      init() {}
    }
  });

  editor.Css.addRules(`
    .for-loop {
      border: 2px dashed #ccc;
      padding: 10px;
      margin-bottom: 20px;
    }
    
    .loop-heading {
      font-weight: bold;
      color: #f00;
      margin-bottom: 10px;
    }
    
    .loop-content {
      border: 1px solid #ccc;
      padding: 10px;
      margin-bottom: 10px;
    }
    
    .loop-ending {
      font-style: italic;
      color: #f00;
      margin-top: 10px;
    }
  `);
};
