import { useState, useEffect } from 'react';
import { Row, Form, Col, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCreditNoteFields } from '../../../../customers/fields/creditNoteFields';
import { useGenerateFormItem } from '../../../../../../utils/generateFormItem';
import { addCreditNoteTotalTTC } from '../../../../customers/utils/addCreditNoteTotalTTC';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/errorMessage';
import { CreditNoteForm } from './CreditNoteForm';

/**
 * CreditNoteCards component for displaying and managing credit note cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {function} props.setIsCreditNoteCardVisible - Function to set the visibility of the credit note card.
 * @param {boolean} props.isCreditNoteCardVisible - Flag indicating whether the credit note card is visible.
 * @param {function} props.t - Translation function for internationalization.
 * @param {Object} props.invoice - The invoice object.
 * @returns {JSX.Element} CreditNoteCards component.
 */
export const CreditNoteCards = ({
  setIsCreditNoteCardVisible,
  isCreditNoteCardVisible,
  t,
  invoice
}) => {
  const [wordingTotalHT, setWordingTotalHT] = useState(0);
  const [wordingTotalTVA, setWordingTotalTVA] = useState(0);
  const [totalHT, setTotalHT] = useState(0);
  const [totalTTC, setTotalTTC] = useState(0);
  const [wordingsIds, setWordingsIds] = useState([]);
  const [updatedWordingType, setUpdatedWordingType] = useState('');
  const [wordingTotalTTC, setWordingTotalTTC] = useState(0);
  const [wordings, setWordings] = useState([
    { id: Date.now().toString(), value: '' }
  ]);
  const [wordingTitle, setWordingTitle] = useState([
    { id: Date.now().toString(), value: '' }
  ]);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { creditNoteFields } = useCreditNoteFields({
    t,
    wordings,
    setWordings,
    setWordingTotalHT,
    setWordingTotalTVA,
    invoice,
    setWordingTitle,
    wordingTitle,
    setWordingsIds,
    wordingsIds,
    setUpdatedWordingType,
    setWordingTotalTTC
  });
  const generateFields = useGenerateFormItem();

  useEffect(() => {
    addCreditNoteTotalTTC({
      wordings,
      wordingTotalHT,
      wordingTotalTVA,
      setTotalHT,
      setTotalTTC,
      form,
      updatedWordingType,
      wordingTotalTTC
    });
  }, [wordingTotalHT, wordingTotalTVA, wordingTotalTTC]);

  useEffect(() => {
    form.setFieldValue('type', 'CREDIT_NOTE');
  }, []);

  return (
    <Row
      id="credit-note-row"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Col>
        <Alert
          message={t('customerinvoices.show.alert.title.credit_note')}
          description={t('customerinvoices.show.alert.description.credit_note')}
          type="warning"
          showIcon
        />
      </Col>
      <Col>
        <CreditNoteForm
          totalHT={totalHT}
          totalTTC={totalTTC}
          wordingsIds={wordingsIds}
          form={form}
          navigate={navigate}
          message={message}
          dispatchAPI={dispatchAPI}
          creditNoteFields={creditNoteFields}
          generateFields={generateFields}
          setIsCreditNoteCardVisible={setIsCreditNoteCardVisible}
          isCreditNoteCardVisible={isCreditNoteCardVisible}
          t={t}
          invoice={invoice}
        />
      </Col>
    </Row>
  );
};

CreditNoteCards.propTypes = {
  setIsCreditNoteCardVisible: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  invoice: PropTypes.shape({}).isRequired,
  isCreditNoteCardVisible: PropTypes.bool.isRequired
};
