import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useFields } from './fields/fields';
import { useErrorMessage } from '../../../utils/errorMessage';
import { createFileFromJSON } from '../../../utils/filesManagement/createFileFromJSON';
import { useOtherInfosFields } from './fields/otherInfosFields';
import { useExtraInfosFields } from './fields/extraInfosFields';
import { useCreateUpdateConfig } from './utils/useCreateUpdateConfig';
import { useSecondaryProfileFields } from './fields/secondaryProfileFields';
import { useSocketContext } from '../../../contexts/SocketContext';
import { EditingLocked } from '../../../components/EditingLocked/EditingLocked';
import { editingLocked } from '../../../utils/editingLocked';

/**
 * CreateUpdateProfile component for creating or updating user profiles.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {string} props.purpose - The purpose of the profile operation, either 'create' or 'update'.
 * @returns {JSX.Element} - React component.
 */
export const CreateUpdateProfile = ({ purpose }) => {
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { profileLock } = useSocketContext();
  const navigate = useNavigate();
  const { id } = useParams();

  // get fieldsActiveKey from url
  const location = useLocation();
  // const { pathname } = location;
  const searchParams = new URLSearchParams(location.search);
  const fieldsActiveKey = searchParams.get('fieldsActiveKey');

  const [documentsFileList, setDocumentsFileList] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [secondaryProfilePhoto, setSecondaryProfilePhoto] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [removedFiles, setRemovedFiles] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/pensioners/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  // Importing and using custom hooks for fields
  const { mainFields } = useFields({
    setDocumentsFileList,
    documentsFileList,
    photo,
    setPhoto,
    secondaryProfilePhoto,
    setSecondaryProfilePhoto,
    loading,
    setLoading,
    imageUrl,
    setImageUrl,
    setRemovedFiles
  });
  const { secondaryProfileFields } = useSecondaryProfileFields({
    setDocumentsFileList,
    documentsFileList,
    secondaryProfilePhoto,
    setSecondaryProfilePhoto,
    setRemovedFiles
  });

  const { otherInfosFields } = useOtherInfosFields({
    t,
    setDocumentsFileList,
    documentsFileList
  });
  const { extraInfosFields } = useExtraInfosFields({
    t,
    enums,
    isFieldsLoading
  });

  // Mapping fields to an object
  const fieldsActive = {
    mainFields,
    secondaryProfileFields,
    otherInfosFields,
    extraInfosFields
  };

  // Importing and using custom hook for configuration
  const { config } = useCreateUpdateConfig({
    createFileFromJSON,
    dispatchAPI,
    message,
    setPhoto,
    photo,
    setSecondaryProfilePhoto,
    secondaryProfilePhoto,
    documentsFileList,
    setDocumentsFileList,
    setUser,
    removedFiles
  });

  // Setting base URL based on user role
  const baseUrl = `${user.role.split(':')[1].toLowerCase()}s${
    user.role === 'users:PENSIONER' ? '/form' : `/form`
  }`;

  return (
    <>
      {editingLocked(profileLock, id) && (
        <EditingLocked
          t={t}
          resources={{
            button: 'my_profile',
            text: 'profile'
          }}
          navigate={navigate}
        />
      )}
      <CreateUpdateContainer
        purpose={purpose}
        baseUrl={baseUrl}
        populate="photo"
        fields={fieldsActive[fieldsActiveKey]}
        config={config}
        resource="users"
        disabled={editingLocked(profileLock, id)}
      />
    </>
  );
};

CreateUpdateProfile.propTypes = {
  purpose: PropTypes.string.isRequired
};
