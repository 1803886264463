import { useCallback, useEffect, useState } from 'react';
import {
  Input,
  Select,
  Tag,
  DatePicker,
  Button,
  Form,
  Upload,
  Row,
  Col,
  InputNumber,
  Flex
} from 'antd';
import {
  PlusOutlined,
  CameraOutlined,
  CloseOutlined
} from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { CustomerType } from '../../utils/constants/tagColors';

const { Option } = Select;
const { Dragger } = Upload;

/**
 * Hook personnalisé pour générer les champs d'un formulaire de facture.
 * @param {Object} options - Les options pour le hook.
 * @param {Array} options.filesList - La liste des fichiers téléchargés.
 * @param {function} options.setFilesList - La fonction pour mettre à jour la liste des fichiers.
 * @param {Array} options.customers - La liste des clients (uniquement pour le type de facture "customer").
 * @param {function} options.setWordings - La fonction pour mettre à jour les libellés.
 * @param {Array} options.wordings - Les libellés.
 * @param {function} options.setCustomer - La fonction pour définir le client sélectionné.
 * @param {Array} options.selectedSupervisions - Les supervisions sélectionnées (uniquement pour le type de facture "customer").
 * @param {function} options.setSupervision - La fonction pour mettre à jour les supervisions.
 * @param {function} options.setWordingTotalHT - La fonction pour mettre à jour le total HT d'un libellé (uniquement pour le type de facture "customer").
 * @param {function} options.setWordingTotalTVA - La fonction pour mettre à jour le total TVA d'un libellé.
 * @param {function} options.setWordingTotalReduction - La fonction pour mettre à jour le total de réduction d'un libellé (uniquement pour le type de facture "customer").
 * @param {function} options.setWordingUnitPrice - La fonction pour mettre à jour le prix unitaire d'un libellé (uniquement pour le type de facture "intern").
 * @param {function} options.setWordingQuantity - La fonction pour mettre à jour la quantité d'un libellé (uniquement pour le type de facture "intern").
 * @param {string} options.invoiceType - Le type de facture ("customer" ou "intern").
 * @param {function} options.setPaymentMethod - La fonction pour mettre à jour la méthode de paiement.
 * @param {string} options.paymentMethod - La méthode de paiement sélectionnée.
 * @returns {Object} Les champs de formulaire et leur état de chargement.
 */

export const useFields = ({
  filesList,
  setFilesList,
  customers,
  setCustomer,
  selectedSupervisions,
  setSupervision,
  invoiceType,
  setPaymentMethod,
  paymentMethod,
  setUpdatedWordingType,
  setUpdateWordingFields,
  updateWordingFields,
  form
}) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [fieldNumber, setFieldNumber] = useState(0);

  const draggerProps = () => ({
    onRemove: (file) => {
      setFilesList(filesList.filter((f) => f.uid !== file.uid));
    },
    beforeUpload: (file) => {
      setFilesList((prevFileList) => [...prevFileList, file]);

      return false;
    },
    fileList: filesList || []
  });

  const filterOption = (input, option) => {
    const children = option?.props?.customer;
    if (children) {
      const { first_name, last_name, reference } = children;

      return (
        String(first_name).toLowerCase().includes(input.toLowerCase()) ||
        String(last_name).toLowerCase().includes(input.toLowerCase()) ||
        String(reference).toLowerCase().includes(input.toLowerCase())
      );
    }

    return false;
  };

  const invoiceFields = [
    ...(invoiceType === 'customer'
      ? [
          {
            name: ['type'],
            rules: [{ required: true }],
            input: (
              <Select
                loading={isFieldsLoading}
                onChange={(e) => setSelectedType(e)}
              >
                {(enums?.type || [])
                  .filter((el) => el !== 'CREDIT_NOTE')
                  .map((type) => (
                    <Option key={type} value={type}>
                      <Tag>{t(`customerinvoices.tags.${type}`)}</Tag>
                    </Option>
                  ))}
              </Select>
            )
          },
          {
            name: ['customer'],
            rules: [{ required: true }],
            input: (
              <Select
                showSearch
                loading={isFieldsLoading}
                filterOption={filterOption}
                onChange={(e, option) => {
                  setCustomer(option.customer);
                }}
              >
                {(customers || []).map((customer) => (
                  <Option
                    key={customer?.user?._id}
                    value={customer?.user?._id}
                    customer_type={customer.customer_type}
                    customer={customer}
                  >
                    <Row justify="space-around" align="center">
                      <Col>{`${customer.first_name} ${customer.last_name}`}</Col>
                      <Col>
                        <Tag color={CustomerType[customer.customer_type]}>
                          {t(`customerinvoices.tags.${customer.customer_type}`)}
                        </Tag>
                      </Col>
                    </Row>
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['supervision'],
            input: (
              <Select
                showSearch
                filterOption={(input, option) => {
                  const children = option?.props?.supervision;
                  if (children) {
                    const { reference } = children;

                    return String(reference)
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }

                  return false;
                }}
                disabled={selectedType === 'SUBSCRIPTION'}
                loading={isFieldsLoading}
                onChange={(_, option) => setSupervision(option.supervision)}
              >
                {(selectedSupervisions || []).map((supervision) => (
                  <Option
                    key={supervision._id}
                    value={supervision._id}
                    supervision={supervision}
                  >
                    {supervision.reference}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    ...(invoiceType === 'intern'
      ? [
          {
            name: ['emitter']
          },
          {
            name: ['recipient_name']
          }
        ]
      : []),
    {
      name: ['payment_method'],
      input: (
        <Select loading={isFieldsLoading} onChange={(e) => setPaymentMethod(e)}>
          {(enums?.payment_method || []).map((payment_method) => (
            <Option key={payment_method} value={payment_method}>
              {t(`customerinvoices.tags.${payment_method}`)}
            </Option>
          ))}
        </Select>
      )
    },
    ...(paymentMethod === 'CHEQUE' ? [{ name: ['cheque_number'] }] : []),
    {
      name: ['invoicing_address']
    },
    {
      name: ['postal_code']
    },
    {
      name: ['city']
    },
    ...(invoiceType === 'intern'
      ? [
          {
            name: ['wording']
          }
        ]
      : []),
    {
      name: ['emission_date'],
      input: <DatePicker />
    },
    ...(selectedType !== 'SUBSCRIPTION'
      ? [
          {
            name: ['due_date'],
            input: <DatePicker />
          }
        ]
      : []),
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.status || []).map((status) => (
            <Option key={status} value={status}>
              {t(`customerinvoices.tags.${status}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      noLabel: true,
      input: (
        <Form.List name={['wordings']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key }) => (
                <Flex
                  align="center"
                  gap="small"
                  key={key}
                  className="invoice-line"
                  wrap="wrap"
                >
                  {t('interninvoices.form.wordings.title')}
                  <Form.Item name={[name, 'title']} noStyle>
                    <Input />
                  </Form.Item>
                  {invoiceType === 'customer' && (
                    <>
                      {t('interninvoices.form.wordings.total_ht')}
                      <Form.Item name={[name, 'total_ht']} noStyle>
                        <InputNumber
                          onChange={() => {
                            setUpdatedWordingType('HT');
                            setUpdateWordingFields(!updateWordingFields);
                          }}
                        />
                      </Form.Item>
                    </>
                  )}
                  {invoiceType === 'intern' && (
                    <>
                      {t('interninvoices.form.wordings.unit_price')}
                      <Form.Item name={[name, 'unit_price']} noStyle>
                        <InputNumber
                          onChange={() =>
                            setUpdateWordingFields(!updateWordingFields)
                          }
                        />
                      </Form.Item>
                      {t('interninvoices.form.wordings.quantity')}
                      <Form.Item name={[name, 'quantity']} noStyle>
                        <InputNumber
                          onChange={() =>
                            setUpdateWordingFields(!updateWordingFields)
                          }
                        />
                      </Form.Item>
                    </>
                  )}
                  {t('interninvoices.form.wordings.tva')}
                  <Form.Item name={[name, 'tva']} noStyle>
                    <InputNumber
                      onChange={() =>
                        setUpdateWordingFields(!updateWordingFields)
                      }
                    />
                  </Form.Item>
                  {invoiceType === 'customer' && (
                    <>
                      {t('interninvoices.form.wordings.reduction')}
                      <Form.Item name={[name, 'reduction']} noStyle>
                        <InputNumber
                          onChange={() => {
                            setUpdatedWordingType('REDUCTION');
                            setUpdateWordingFields(!updateWordingFields);
                          }}
                        />
                      </Form.Item>
                    </>
                  )}
                  {t('interninvoices.form.wordings.total_ttc')}
                  <Form.Item name={[name, 'total_ttc']} noStyle>
                    <InputNumber
                      onChange={() => {
                        setUpdatedWordingType('TTC');
                        setUpdateWordingFields(!updateWordingFields);
                      }}
                    />
                  </Form.Item>
                  <Button
                    type="link"
                    onClick={() => {
                      remove(name);
                      setFieldNumber((prevFieldNumber) => prevFieldNumber - 1);
                      setUpdateWordingFields(!updateWordingFields);
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </Flex>
              ))}
              <Button
                type="dashed"
                block
                onClick={() => {
                  add();
                  setFieldNumber((prevFieldNumber) => prevFieldNumber + 1);
                  form.setFieldValue(['wordings', fieldNumber, 'tva'], 10);
                }}
                icon={<PlusOutlined />}
                style={{ transform: 'translateX(100%)' }}
              >
                {t('interninvoices.form.wordings_add_button')}
              </Button>
            </>
          )}
        </Form.List>
      )
    },
    {
      name: ['total_ht'],
      input: <InputNumber disabled />,
      rules: [{ required: true }]
    },
    ...(invoiceType === 'customer'
      ? [
          {
            name: ['total_reduction_ttc'],
            input: <InputNumber disabled />
          }
        ]
      : []),
    {
      name: ['total_ttc'],
      input: <InputNumber disabled />,
      rules: [{ required: true }]
    },
    {
      name: ['files'],
      input: (
        <Dragger {...draggerProps()}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customerinvoices/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    invoiceFields,
    isFieldsLoading
  };
};
