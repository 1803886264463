import { Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useState } from 'react';
import PetsittingAddressInfoModal from '../../owners/showOwner/detailsCards/modals/PetsittingAddressModal';
import AnimalInfoModal from '../../owners/showOwner/detailsCards/modals/AnimalInfoModal';
import { convertToHTML } from '../../../utils/convertToHTML';

export const useSupervisionCollapseInfos = (supervision, t, user, anounce) => {
  const [isPetsittingAddressModalOpen, setIsPetsittingAddressModalOpen] =
    useState();
  const [animalModalOpen, setAnimalModalOpen] = useState();

  const {
    address: { petsitting_address } = {},
    reference,
    summary: { price_category: { total_ttc, advance_payment } = {} } = {},
    main_informations,
    summary,
    comment
  } = supervision || {};
  const { number, street, additional, country, city, postal_code, state } =
    petsitting_address?.address || {};
  const {
    animals,
    veterinarian: { name: veterinarianName } = {},
    accept_smoker
  } = main_informations || {};
  const {
    urgency: {
      address: urgencyAddress,
      first_name,
      last_name,
      phone_number: { number: urgencyPhoneNumber } = {}
    } = {}
  } = summary || {};

  const mainInformations = [
    ...(supervision?.status === 'WAITING_BOOKING' &&
    user.role === 'users:PENSIONER'
      ? [
          {
            label: t('supervisions.show.anounce.anounce'),
            content: anounce?.description
              ? convertToHTML({ htmlContent: anounce.description })
              : '-',
            span: 3
          }
        ]
      : [
          {
            label: t('supervisions.show.anounce.anounce'),
            content: anounce?.description
              ? convertToHTML({ htmlContent: anounce.description })
              : '-',
            span: 3
          },
          {
            label: t('supervisions.show.anounce.reference'),
            content: reference || '-',
            span: 3
          },
          ...(user.role === 'users:OWNER'
            ? [
                {
                  label: t('supervisions.show.rates.supervision_rate'),
                  content: total_ttc || '-',
                  span: 3
                },
                {
                  label: t('supervisions.show.rates.advance_payment'),
                  content: advance_payment || '-',
                  span: 3
                }
              ]
            : []),
          {
            label: t('owners.users_shows.petsitting_address'),
            content:
              `${number || ''} ${street || ''} ${additional || ''}` || '-',
            extra: (
              <>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => setIsPetsittingAddressModalOpen(true)}
                >
                  <EyeOutlined />
                  {t('owners.users_shows.petsitting_address_modal')}
                </Button>
                <PetsittingAddressInfoModal
                  address={petsitting_address}
                  isModalOpen={isPetsittingAddressModalOpen}
                  setIsModalOpen={setIsPetsittingAddressModalOpen}
                  t={t}
                />
              </>
            ),
            span: 3
          },
          {
            label: t('owners.users_shows.department'),
            content: state || '-',
            span: 3
          },
          {
            label: t('owners.users_shows.postal_code'),
            content: postal_code || '-',
            span: 3
          },
          {
            label: t('owners.users_shows.city'),
            content: city || '-',
            span: 3
          },
          {
            label: t('owners.users_shows.country'),
            content: country || '-',
            span: 3
          },
          ...(animals || [])
            .map((animal, index) => [
              {
                label: t('supervisions.show.animals.animal_type'),
                content: t(`animals.tags.${animal?.type}`),
                span: 3,
                extra: (
                  <>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => setAnimalModalOpen(true)}
                    >
                      <EyeOutlined />
                      {t('owners.buttons.show_more')}
                    </Button>
                    <AnimalInfoModal
                      owner={main_informations}
                      isModalOpen={animalModalOpen}
                      setIsModalOpen={setAnimalModalOpen}
                      t={t}
                      index={index}
                    />
                  </>
                )
              },
              {
                label: t('supervisions.show.animals.animal_name'),
                content: animal?.name || '-',
                span: 3
              },
              {
                label: t('supervisions.show.animals.animal_age'),
                content: animal?.age || '-',
                span: 3
              },
              {
                label: t('supervisions.show.animals.animal_breed'),
                content: animal?.breed || '-',
                span: 3
              }
            ])
            .flat(2),
          {
            label: t('supervisions.show.veterinarian.title'),
            content: veterinarianName || '-',
            span: 3
          },
          {
            label: t('supervisions.form.main_informations.accept_smoker'),
            content: accept_smoker
              ? t('animals.users_show.yes')
              : t('animals.users_show.no'),
            span: 3
          },
          {
            label: t('supervisions.show.urgency.contact_person'),
            content: `${first_name || ''} ${last_name || ''}` || '-',
            span: 3
          },
          {
            label: t('supervisions.show.urgency.address'),
            content: urgencyAddress || '-',
            span: 3
          },
          {
            label: t('supervisions.show.urgency.phone_number'),
            content: urgencyPhoneNumber || '-',
            span: 3
          },
          {
            label: t('supervisions.show.comment'),
            content: comment || '-',
            span: 3
          }
        ])
  ];

  return mainInformations;
};
