import { Avatar, Divider, Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { userRoles } from '../../utils/constants/tagColors';
import { useListResourceArchiving } from '../../utils/listResourceArchiving';

export const useColumns = (setForceRefresh) => {
  const { t } = useTranslation();
  const { deleteResource, restoreResource } = useListResourceArchiving(
    setForceRefresh,
    'users'
  );

  return [
    {
      title: t('users.form.photo'),
      key: 'photo',
      dataIndex: 'photo',
      render: (photo) => (photo ? <Avatar src={photo} /> : <Avatar />)
    },
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: ['role'],
      render: (role) =>
        role ? (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`, {
              defaultValue: role.split(':')[1]
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      key: 'actions',
      align: 'rigth',
      fixed: 'rigth',
      width: 100,
      render: (record) => (
        <>
          <Link to={{ pathname: `/users/show/${record._id}` }}>
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Link to={{ pathname: `/users/edit/${record._id}` }}>
            <EditOutlined style={{ fontSize: 18 }} />
          </Link>
          <>
            <Divider type="vertical" />
            {!record.is_archived ? (
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() => deleteResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ color: '#b51010', fontSize: 18 }}
                  type="delete"
                />
              </Popconfirm>
            ) : (
              <Popconfirm
                title={t('datatable.column.action.unarchived.title')}
                okText={t('datatable.column.action.unarchived.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.unarchived.cancel')}
                onConfirm={() => restoreResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <RollbackOutlined style={{ color: '#b51010', fontSize: 18 }} />
              </Popconfirm>
            )}
          </>
        </>
      )
    }
  ];
};
