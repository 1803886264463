import React from 'react';
import PropTypes from 'prop-types';
import { Col, Carousel, Image, Modal, Spin } from 'antd';
import { detailPetsittingAddressInfo } from '../../../../../components/PetsittingAddress/listContent/petsittingAddressInfo';
import { DescriptionList } from '../../../../../components';
import { useAuthContext } from '../../../../../contexts/AuthContext';

/**
 * `PetsittingAddressInfoModal` is a component that presents a modal containing information
 * about a pet sitting address. It showcases a series of photos in a carousel and various details
 * categorized by sections such as general information, room and laundry, and so on.
 *
 * @component
 * @param {Object} props - The properties passed down from the parent component.
 * @param {Object} props.address - An object containing details about the pet sitting address including dwelling photos.
 * @param {boolean} [props.isModalOpen=false] - A boolean flag indicating whether the modal is open.
 * @param {Function} [props.setIsModalOpen=null] - A function to set the `isModalOpen` state.
 * @param {Function} props.t - A function to handle the translation of strings.
 *
 * @returns {React.Element} JSX representation of the component.
 */
const PetsittingAddressInfoModal = ({
  address,
  isModalOpen,
  setIsModalOpen,
  t
}) => {
  const { token } = useAuthContext();

  const descriptionTitle = [
    'details',
    'general_informations',
    'room_and_laundry',
    'bathroom',
    'kitchen',
    'outside',
    'parking_and_installation',
    'equipment'
  ];
  return (
    <Modal
      title={t('owners.show.petsitting_address_info')}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      width={800}
    >
      <Col xs={24} xxl={24} style={{ marginBottom: 16 }}>
        <Carousel autoplay dots>
          {address?.dwelling_photos.map((image) => (
            <Image
              width={300}
              key={image._id}
              src={`${process.env.REACT_APP_API_URL}/anounces/get-url/${image?._id}/${token}`}
              placeholder={<Spin loading />}
            />
          ))}
        </Carousel>
      </Col>
      {descriptionTitle.map((title) => (
        <Col key={title} xs={24} xxl={24}>
          <DescriptionList
            title={
              title === 'details'
                ? null
                : t(`petsittingaddress.card_title.${title}`)
            }
            data={detailPetsittingAddressInfo(address || {}, title, t)}
            translate
            skipEmpty
          />
        </Col>
      ))}
    </Modal>
  );
};

export default PetsittingAddressInfoModal;

PetsittingAddressInfoModal.propTypes = {
  address: PropTypes.shape({
    dwelling_photos: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string
      })
    )
  }),
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  t: PropTypes.func.isRequired
};

PetsittingAddressInfoModal.defaultProps = {
  isModalOpen: false,
  setIsModalOpen: null,
  address: {}
};
