import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A React component that defines and returns an array of field configurations
 * for capturing kitchen information in the form of checkboxes.
 * It makes use of the `useTranslation` hook from `react-i18next` to provide
 * translations for the label of each checkbox.
 *
 * @returns {Object} An object containing the following properties:
 * - `kitchenInformationsFields`: An array of objects, each representing a field
 *   configuration for a checkbox input capturing various kitchen appliance
 *   information. The configurations include properties such as `noLabel`,
 *   `name`, `input`, and `valuePropName`.
 *
 * @function
 * @example
 *
 * import KitchenInformations from './KitchenInformations';
 *
 * const { kitchenInformationsFields } = KitchenInformations();
 *
 * // Now, kitchenInformationsFields can be used to generate a form or part of a form
 */
const KitchenInformations = () => {
  const { t } = useTranslation();

  const kitchenInformationsFields = [
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'kitchen',
        'hotplate'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.hotplate'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'kitchen',
        'oven'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.oven'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'kitchen',
        'microwave'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.microwave'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'kitchen',
        'electric_coffee_maker'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.electric_coffee_maker'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'kitchen',
        'toaster'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.toaster'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'kitchen',
        'dishwasher'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.dishwasher'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      noLabel: true,
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'kitchen',
        'freezer'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.kitchen.freezer'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    }
  ];

  return {
    kitchenInformationsFields
  };
};

export default KitchenInformations;
