import React, { useEffect, useState } from 'react';
import { Button, Tabs, Space, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { TemplateDocsDatatable } from './TemplateDocsDatatable';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { routes } from '../../utils/constants/developerRoutes';

const { TabPane } = Tabs;

/**
 * `ListTemplateDocs` is a component that displays a list of template documents
 * categorized by use cases. It retrieves use cases from the server and displays
 * them in tabs, with each tab containing a datatable of documents for a particular
 * use case.
 *
 * It makes use of various hooks like `useTranslation` for internationalization,
 * `useNavigate` to navigate to other routes, and `useState` and `useEffect`
 * to manage the component's state and lifecycle respectively.
 *
 * @component
 * @returns {React.Element} A JSX element containing a page header with a creation button and a set of tabs displaying documents categorized by use cases.
 *
 * @example
 *
 * return (
 *   <ListTemplateDocs />
 * );
 */
const ListTemplateDocs = () => {
  const [useCases, setUsesCases] = useState([]);
  const [archiveFilter, setArchiveFilter] = useState(false);

  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();

  const getUseCases = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/template-documents/enums'
      });
      setUsesCases(
        data.configuration.map((config) => ({
          label: config.use_case,
          key: config.use_case,
          children: (
            <TemplateDocsDatatable
              archiveFilter={archiveFilter}
              navigate={navigate}
              useCaseParams={config.use_case}
            />
          )
        }))
      );
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getUseCases();
    })();
  }, [archiveFilter]);

  return (
    <>
      <PageHeaderCustom
        title={t('template-documents.title')}
        extra={
          <>
            <Space>
              <Switch
                onChange={() => setArchiveFilter(!archiveFilter)}
                checkedChildren={t('buttons.archived')}
                unCheckedChildren={t('buttons.not_archived')}
              />
            </Space>
            <Button onClick={() => navigate(`${routes.TEMPLATE_DOCS}/create`)}>
              <PlusOutlined />
              {t('buttons.create')}
            </Button>
          </>
        }
        backIcon={false}
      />
      <ContentCustom>
        <Tabs tabPosition="left">
          {useCases.map(({ label, key, children }) => (
            <TabPane key={key} tab={t(`template-documents.form.${label}`)}>
              {children}
            </TabPane>
          ))}
        </Tabs>
      </ContentCustom>
    </>
  );
};

export default ListTemplateDocs;
