import { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Select,
  Tag,
  Button,
  Checkbox,
  Flex,
  Card,
  Upload,
  Image,
  Skeleton
} from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useCountryArray } from '../../../../utils/countryArray';
import { compressFile } from '../../../../utils/filesManagement/compressFile';
import { useCountryCodeInfos } from '../utils/useCountryCodeInfos';

const { Option } = Select;

/**
 * Hook to manage the general fields in a form. It maintains the state of various fields and fetches necessary enums from the API.
 * It returns an object containing the basicFields (a structured representation of form fields) and a loading state for select options.
 *
 * @param {Object} form - Instance of form.
 *
 * @returns {Object} - An object containing basicFields array and isFieldsLoading boolean
 * @returns {Object[]} basicFields - An array of objects representing the structure and state of each form field
 * @returns {boolean} isFieldsLoading - A boolean indicating if select options are currently loading
 */
export const useGeneralFields = ({
  form,
  internetAccess,
  setInternetAccess,
  setPhotoFileList,
  photoFileList,
  photoID,
  loading,
  country,
  setCountry
}) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const countries = useCountryArray();
  const { dispatchAPI, token } = useAuthContext();
  const { handleCountryCodeInfos } = useCountryCodeInfos(country);

  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});

  const filterOption = (input, option) =>
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const draggerProps = {
    onRemove: (file) => {
      const index = photoFileList.indexOf(file);
      const newFileList = photoFileList.slice();
      newFileList.splice(index, 1);
      setPhotoFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const newFile = await compressFile(file);
      setPhotoFileList([newFile]);
      return false;
    },
    fileList: photoFileList || []
  };

  const basicFields = [
    {
      label: 'photo',
      input: (
        <Upload {...draggerProps} listType="picture-circle">
          <Skeleton loading={loading} avatar active>
            {photoID ? (
              <Image
                src={`${process.env.REACT_APP_API_URL}/owners/get-url/${photoID}/${token}`}
                alt="avatar"
              />
            ) : (
              '+ Upload'
            )}
          </Skeleton>
        </Upload>
      )
    },
    {
      name: ['civility'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.civility || []).map((civility) => (
            <Option key={civility} value={civility}>
              <Tag>{t(`owners.tags.${civility}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['cell_phone_number'],
      label: 'cell_phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['cell_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['cell_phone_number', 'number']}>
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      noLabel: true,
      input: (
        <Form.List name={['other_phone_number']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key }) => (
                <Card className="other-phone-number-card">
                  <Flex justify="space-between" align="center" key={key}>
                    {`${t('owners.form.other_phone_number.title')} ${name + 1}`}
                    <Button type="link" onClick={() => remove(name)}>
                      <CloseOutlined />
                    </Button>
                  </Flex>

                  <Input.Group compact>
                    <Form.Item
                      noStyle
                      name={[name, 'country_code']}
                      initialValue="+33"
                    >
                      <Select style={{ width: 70 }}>
                        <Option value="+33">+33</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle name={[name, 'number']}>
                      <Input
                        type="number"
                        style={{ width: 'calc(100% - 70px)' }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Card>
              ))}
              <Button
                type="dashed"
                block
                onClick={() => {
                  add();
                }}
                icon={<PlusOutlined />}
                style={{ transform: 'translateX(100%)' }}
              >
                {t('owners.form.other_phone_number.add_button')}
              </Button>
            </>
          )}
        </Form.List>
      )
    },
    {
      name: ['internet_access'],
      initialValue: internetAccess,
      input: (
        <Checkbox
          defaultChecked={internetAccess}
          onChange={() => setInternetAccess(!internetAccess)}
        />
      ),
      valuePropName: 'checked'
    },
    ...(internetAccess
      ? [
          {
            name: ['email'],
            rules: [{ required: true }, { type: 'email' }]
          }
        ]
      : []),
    {
      name: ['first_profession']
    },
    {
      name: ['second_profession']
    },
    {
      label: 'address.number',
      name: ['address', 'number'],
      rules: [{ required: true }]
    },
    {
      label: 'address.street',
      name: ['address', 'street'],
      rules: [{ required: true }]
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.country',
      name: ['address', 'country'],
      input: (
        <Select
          showSearch
          loading={isFieldsLoading}
          filterOption={filterOption}
        >
          {(countries || []).map((value) => (
            <Option key={value} value={value}>
              <Tag>{value}</Tag>
            </Option>
          ))}
        </Select>
      ),
      rules: [
        { required: true },
        ({ getFieldValue }) => {
          setCountry(getFieldValue(['address', 'country']));
        }
      ]
    },
    {
      label: 'address.postal_code',
      name: ['address', 'postal_code'],
      input: (
        <Input
          onChange={(e) => {
            handleCountryCodeInfos(e.target.value).then((values) => {
              form.setFieldValue(['address', 'city'], values?.city);
              form.setFieldValue(['address', 'state'], values?.state);
            });
          }}
        />
      ),
      dependencies: ['address', 'country'],
      rules: [{ required: true }]
    },
    {
      label: 'address.department',
      name: ['address', 'state'],
      rules: [{ required: true }]
    },
    {
      label: 'address.city',
      name: ['address', 'city'],
      rules: [{ required: true }]
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/owners/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    basicFields,
    isFieldsLoading
  };
};
