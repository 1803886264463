import { message as antdMessage } from 'antd';

export const sendMail = async ({
  value,
  dispatchAPI,
  message,
  templateType,
  setIsSubmitting,
  editorState,
  setIsModalOpen,
  documentType,
  recordID,
  filterID,
  filesList,
  t
}) => {
  try {
    if (setIsModalOpen) {
      setIsSubmitting(true);
    }

    const formData = new FormData();
    if (filesList.length) {
      filesList.forEach((file) => {
        formData.append('attachments', file);
      });
    }

    const newBody = {
      ...value,
      template_content: editorState
    };

    formData.append('values', JSON.stringify({ ...newBody }));

    await dispatchAPI('POST', {
      url: `/templates-mail/send/${templateType}/${documentType}/${recordID}/${filterID}`,
      body: formData
    });

    if (setIsModalOpen) {
      setIsSubmitting(false);
      setIsModalOpen(false);
      antdMessage.success(t('messages.mail_sent'));
    }
  } catch (err) {
    message(err);
  }
};
