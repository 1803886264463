import { generateDocumentsOld } from '../../mail-sender/utils/generateDocumentsOld';

export const generateContractDocuments = ({
  dispatchAPI,
  message,
  documentType,
  id,
  option,
  t,
  supervision
}) => {
  const ownerDocumentType = [
    { type: 'OWNER_CONTRACT', record_id: option._id },
    { type: 'OWNER-INVOICE', invoice_type: 'BALANCE' },
    { type: 'OWNER-INVOICE', invoice_type: 'ADVANCE_PAYMENT' }
  ];

  if (documentType === 'OWNER_CONTRACT') {
    ownerDocumentType.map(async (doc) => {
      if (doc.type === 'OWNER-INVOICE') {
        const { data } = await dispatchAPI('GET', {
          url: `customerinvoices?supervision=${supervision._id}&type=${doc.invoice_type}`
        });

        if (data.length) {
          generateDocumentsOld(
            dispatchAPI,
            message,
            doc.type,
            data[0]._id,
            null,
            t
          );
        }
      } else {
        generateDocumentsOld(dispatchAPI, message, doc.type, id, null, t);
      }
    });
  } else {
    generateDocumentsOld(dispatchAPI, message, documentType, id, option._id, t);
  }
};
