import { Button, Col, List, Row, Skeleton } from 'antd';
import { CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generateDocumentsOld } from '../../mail-sender/utils/generateDocumentsOld';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Renders a list of documents for a subscription.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.invoice - The invoice object.
 * @param {boolean} props.isLoading - Indicates if the list is loading.
 * @param {Array} props.pensioner - The list of pensioners.
 * @returns {JSX.Element} The rendered component.
 */
export const ListDocument = ({ invoice, isLoading, pensioner }) => {
  const { invoiceId, reference } = invoice;

  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();

  return (
    <Row>
      <Col span={16}>
        <List
          className="demo-loadmore-list"
          loading={isLoading}
          itemLayout="horizontal"
          dataSource={pensioner}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() =>
                    generateDocumentsOld(
                      dispatchAPI,
                      message,
                      'SUBSCRIPTION-INVOICE',
                      invoiceId,
                      null,
                      t
                    )
                  }
                >
                  <DownloadOutlined />
                </Button>
              ]}
            >
              <Skeleton avatar title={false} loading={false} active>
                <List.Item.Meta
                  avatar={<CheckCircleOutlined />}
                  description={t('subscriptions.subscription_invoice_wording', {
                    name: item.name,
                    reference
                  })}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

ListDocument.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    invoiceId: PropTypes.string,
    reference: PropTypes.string
  }).isRequired,
  pensioner: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
