/**
 * Adds a set of CSS styles to any newly added 'text' component in the editor to prevent width expansion.
 * The styles being added include rules to break words to prevent overflow, maintain normal white space handling,
 * allow overflow content to break onto the next line, and restrict the maximum width and height to 100%.
 *
 * @param {object} editor - The editor instance on which the event listener is being set.
 *
 * @example
 * preventWidthExpand(editorInstance); // Adds event listener to the editor instance to add styles to new 'text' components
 * @returns {void}
 */
export const preventWidthExpand = (editor) => {
  editor.on('component:add', (component) => {
    if (component.get('name') === 'text') {
      component.addStyle({
        'word-wrap': 'break-word',
        'white-space': 'normal',
        'overflow-wrap': 'break-word',
        'max-width': '100%',
        'max-height': '100%'
      });
    }
  });
};
