import { Button, List, Col, Row, Skeleton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography/Typography';
import { useEffect, useState } from 'react';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useListContent } from './listContent';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ImagesLayout } from '../supervisions/ImagesLayout';
import { createFileFromJSON } from '../../../utils/filesManagement/createFileFromJSON';
import { useSocketContext } from '../../../contexts/SocketContext';
import { profileLockMessage } from '../utils/profileLockMessage';
import { editingLocked } from '../../../utils/editingLocked';

const { Text } = Typography;

/**
 * Renders the component for displaying the pet sitting address details.
 * @component
 * @returns {JSX.Element} The JSX element representing the ShowPetSittingAddress component.
 */
export const ShowPetSittingAddress = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { profileLock } = useSocketContext();
  const { id } = useParams();

  const [owner, setOwner] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getOwner = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/owners?email=${user?.email}&populate=petsitting_address.dwelling_photos`
      });
      let dwellingPhotos;
      if (data?.[0]?.petsitting_address?.dwelling_photos?.length) {
        const dwellingPhotosPromises =
          data?.[0]?.petsitting_address?.dwelling_photos?.map((photo) =>
            createFileFromJSON(photo, dispatchAPI, message)
          );
        dwellingPhotos = await Promise.all(dwellingPhotosPromises);
      }
      setOwner({
        ...data?.[0],
        petsitting_address: {
          ...data[0]?.petsitting_address,
          dwelling_photos: dwellingPhotos
        }
      });
      setIsLoading(false);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOwner();
    })();
  }, []);

  const { petsitting_address } = owner || {};

  const titles = [
    // Information générales
    t('petsittingaddress.card_title.general_informations'),
    // Chambre et linge
    t('petsittingaddress.card_title.room_and_laundry'),
    // Salle de bain
    t('petsittingaddress.card_title.bathroom'),
    // Cuisine
    t('petsittingaddress.card_title.kitchen'),
    // Exterieur
    t('petsittingaddress.card_title.outside'),
    // Parking et installation
    t('petsittingaddress.card_title.parking_and_installation'),
    // Equipement
    t('petsittingaddress.card_title.equipment')
  ];

  const { mainInfos, additionalInfos } = useListContent(petsitting_address);
  const notPetsittingAddressExist = Object.keys(
    petsitting_address || []
  ).includes('address');

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrumb
        title={t('owners.users_shows.title.petsitting_address_details')}
        extra={
          !petsitting_address ? (
            <Skeleton.Button active loading={isLoading} block />
          ) : (
            <Button
              type="primary"
              onClick={() => {
                if (editingLocked(profileLock, id)) {
                  profileLockMessage(t, 'profile');
                  return;
                }
                navigate(
                  `/petsitting-address/${
                    !notPetsittingAddressExist ? 'create' : 'edit'
                  }/${petsitting_address?._id || owner?._id}`
                );
              }}
            >
              {!notPetsittingAddressExist
                ? t('petsittingaddress.form.title.create')
                : t('petsittingaddress.form.title.edit')}
            </Button>
          )
        }
      />
      <ContentCustom>
        <>
          <Row>
            <Col xs={24} xl={16}>
              <ImagesLayout
                isLoading={isLoading}
                url={petsitting_address?.dwelling_photos || []}
              />
            </Col>
            <Col xs={24} xl={8}>
              <Skeleton loading={isLoading} active>
                <List
                  header={<Text strong>{t('users.form.principal_infos')}</Text>}
                  itemLayout="vertical"
                  dataSource={mainInfos}
                  renderItem={(item) => (
                    <List.Item>
                      <Text>{item.title} : </Text>
                      <Text strong>{item.content}</Text>
                    </List.Item>
                  )}
                />
              </Skeleton>
            </Col>
          </Row>
          <Skeleton loading={isLoading} active>
            {additionalInfos.map((info, index) => (
              <List
                header={<Text strong>{titles[index]}</Text>}
                itemLayout="vertical"
                dataSource={info}
                renderItem={(item) => (
                  <List.Item>
                    <Text>{item.title} : </Text>
                    <Text strong>{item.content}</Text>
                  </List.Item>
                )}
              />
            ))}
          </Skeleton>
        </>
      </ContentCustom>
    </>
  );
};
