export const getOwner = async ({ dispatchAPI, setOwner, message, OwnerId }) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/owners/${OwnerId}?populate=animals,animals.animal_photo,petsitting_address,petsitting_address.dwelling_photos&is_archived=false`
    });
    setOwner(data);
  } catch (e) {
    message(e);
  }
};
