import { Card, Image, Row, Col, Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../../components';
import { supervisionAnnouncementInfo } from '../listContent/supervisionAnnouncementInfo';
import { useAuthContext } from '../../../../../contexts/AuthContext';

const { PreviewGroup } = Image;

/**
 * Renders a card component for displaying supervision announcements.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.anounce - The announcement object.
 * @param {Function} props.setAnounceModalVisible - A function to set the visibility of the announcement modal.
 * @param {Object} props.supervision - The supervision object.
 * @param {boolean} props.isLoading - A flag indicating if the component is in a loading state.
 * @returns {JSX.Element} The rendered SupervisionAnnouncementCard component.
 */
export const SupervisionAnnouncementCard = ({
  anounce,
  setAnounceModalVisible,
  supervision,
  isLoading
}) => {
  const { t } = useTranslation();
  const { token } = useAuthContext();

  const { summary: { anounce_photos } = {} } = supervision || {};

  return (
    <Spin spinning={isLoading}>
      <Card
        title={t('supervisions.show.anounce.title')}
        extra={
          [
            'REQUEST',
            'BOOKED',
            'PUBLISHED',
            'RESERVE',
            'WAITING_BOOKING'
          ].includes(supervision?.status) ? (
            <Button type="link" onClick={() => setAnounceModalVisible(true)}>
              <EditOutlined />
            </Button>
          ) : null
        }
      >
        <Row gutter={[12, 12]}>
          <Col xs={24} lg={8} className="supervision-img-wrapper">
            <PreviewGroup>
              {anounce_photos?.map((photo) => (
                <Image
                  key={photo.id}
                  src={`${process.env.REACT_APP_API_URL}/anounces/get-url/${photo?._id}/${token}`}
                  width={200}
                  placeholder={<Spin loading size="large" />}
                />
              ))}
            </PreviewGroup>
          </Col>
          <Col xs={24} lg={16}>
            <DescriptionList
              data={supervisionAnnouncementInfo({ anounce })}
              translate
            />
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

SupervisionAnnouncementCard.propTypes = {
  anounce: PropTypes.shape({}).isRequired,
  setAnounceModalVisible: PropTypes.func.isRequired,
  supervision: PropTypes.shape({
    status: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
};
